import {
    AfterViewInit, ApplicationRef,
    ChangeDetectorRef,
    Component,
    ElementRef, HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { StorageService } from '../../../common/service/storage.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { StatusService } from '../../status/status.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfigService } from "../../../common/config/config.service";
import { Globals } from "../../../common/global";
import { BaseComponent } from "../../../base/base.component";
import { Observable, Subscription } from "rxjs";
import { ContentObserver } from "@angular/cdk/observers";
import { MatDialog } from "@angular/material/dialog";
import { EmaildDetailComponent } from "../../email/viewemaildetails/vieweEmailDetails.component";
import { TextUtils } from "../../../../util/TextUtils";
import { DialogUtil } from "../../../../util/DialogUtil";
// import { WaterlineInfiniteScrollService } from './waterline-infinite-scroll.service';
import { LoadListColumns } from '../../load/loadlist/columns';
// import { WaterlineService } from './waterline.service';
import { Maintenance } from '../maintenance';
import * as moment from 'moment-timezone';
import { Load } from '../../load/load';
import { MaintenanceService } from '../maintenance.service';

const timeZone = require('moment-timezone');


@Component({
    selector: 'app-waterline-test',
    templateUrl: './waterline-test.component.html',
    styleUrls: ['./waterline-test.component.scss'],
    animations: fuseAnimations
})
export class WaterlineTestComponent implements OnInit {
    protected searchText: string;
    hasSearchedText = false;
    public dataCount: number;
    searchTxt = '';
    isLoading = false;
    isInitComplete = false;
    isMoreDataAvailable = true;
    limit = 10;
    perPageData = this.globals.perPageData;
    columns = this.getColumn();
    displayedColumns = this.columns.map(c => c.columnDef);
    isEnterPressed = false;
    subscription: Subscription;
    dataN: Array<any> = [];
    static isComeBack = false;


    @ViewChild('filter', { static: true })
    filter: ElementRef;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    isNextPressed = false;
    todayDate: Date = new Date();
    static oldData: Array<any> = [];
    static cursor: any




    @ViewChild('table')
    myTable: MatTable<any>; /*not exatly sure what the type should be set too */
    loadSearchBarcode: Array<any>;
    searchData: string;
    page: number;
    hasFirstDataShown = false;
    errorMessage: any;
    maintenanceWaterlineData: Maintenance[] = [];
    prev: number;
    next: number;
    count: number;
    dataSource = new MatTableDataSource<any>(this.maintenanceWaterlineData);
    paginationDisabled: boolean;

    constructor(
        private userSevice: StorageService, private fb: FormBuilder,
        private route: ActivatedRoute, private router: Router,
        private paginationService: MaintenanceService,
        //   public loadListService: WaterlineService,
        private changeDetectorRefs: ChangeDetectorRef,
        private apprRefs: ApplicationRef,

        private statusService: StatusService,
        public snackBar: MatSnackBar,
        private configService: ConfigService,
        private globals: Globals,
        private readonly contentObserver: ContentObserver,
        private dialog: MatDialog) {
        //   super(configService, globals);
    }

    ngOnInit(): void {
        const lab_id = this.userSevice.getCookie("lab_id");
        if (lab_id) {
            this.isInitComplete = true;
            this.dataSource.data = [];
            this.maintenanceWaterlineData = [];
            // this.getCassetteConfig();
            this.isLoading = true;
            this.prev = 0;
            this.next = 1;
            this.page = 0;
            this.count = 1;
            this.getAllData(1);
        } else {
            this.logout();
        }
    }

    logout(): void {
        localStorage.clear();
        this.userSevice.clearCookie();
        // this.globals.cassetteConfig = null;
        this.router.navigate(['/login']);
    }

    getAllData(page) {
        this.isInitComplete = true;
        this.paginationService.getAllDataWaterLinemaintenace(page).subscribe(
            a => {
                // console.log(" All Waterline Data ==", a,);
                Object.entries(a).find(([key, value]) => {
                    if (key == "message" && value == "No data Found") {
                        this.paginationDisabled = true;
                    }
                    if (key == 'data') {
                        this.isInitComplete = false;
                        this.dataN = value;
                        this.dataN.forEach(element => {
                            // console.log("current date=",element.updated_timestamp)
                            this.maintenanceWaterlineData.push({
                                cellName: (element.cell_name ? element.cell_name : element.cell_id),
                                type: element.type,
                                machineName: element.machine_name,
                                cycleNo: element.cycle_number,
                                barcode: element.load_id,
                                createdBy: (element.created_by ? element.created_by.name : "-"),
                                createdOn: (element.created_by ? element.created_by.timestamp : "-"),
                                startedBy: element.created_by,
                                updatedOn: (element.updated_by ? element.updated_by.timestamp : "-"),
                                updatedBy: (element.updated_by ? element.updated_by.name : "-"),
                                aiResult: element.jarvis_result,
                                status: element.jarvis_result,
                                aiLink: element.load_report_url,
                                id: "",
                                docId: ""
                            })

                        })


                    }

                    else {
                        // // console.log(key,'=',value);
                    }
                })


                this.dataSource.data = this.maintenanceWaterlineData;
                //  // console.log("datasource=",this.dataSource.data)

            },

            err => {
                this.errorMessage = err.error.message;
                // console.log('unauthorised', err.status, this.errorMessage)
                if (err.status == 401) {
                    alert('Token is invalid or has expired Please relogin')
                    // this.logout()
                }

            }
        );

    }

    onNextPress() {
        this.isLoading = true;
        this.isNextPressed = true;
        this.count = this.count + 1;
        this.next = this.prev + 1;
        this.prev = this.next - 1
        this.next++
        this.prev++
        this.getAllData(this.next)
        // console.log("next=", this.next, this.prev);

    }

    getColumn(): any[] {
        return [
            {
                columnDef: "Sr",
                header: "Sr.",
                hide: false,
                cell: (element: Load, index: number) =>
                    `${this.page * 10 + index + 1}`,
            },
            {
                columnDef: "Name",
                header: "Name",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    `${element.cellName}`,

            },
            {
                columnDef: "Type",
                header: "Type",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    `${element.type}`,
                // this.replaceWithEmpty(element.type)

            },

            //   {
            //       columnDef: "CycleNo",
            //       header: "CycleNo",
            //       hide: false,
            //       cell: (element: Maintenance, index: number) => 
            //     //   `${element.cycleNo}`,
            //     this.replaceWithEmpty(element.cycleNo)
            //   },

            {
                columnDef: "MaintenanceNo",
                header: "Created On",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    // `${element.barcode}`,
                    this.getDateStringMomentByTz(element.createdOn)
            },

            {
                columnDef: "CreatedBy",
                header: "Created By",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    `${element.createdBy}`,

            },

            {
                columnDef: "MaintainedOn",
                header: "Maintained On",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    this.getDateStringMomentByTz(element.updatedOn)
            },

            {
                columnDef: "MaintainedBy",
                header: "Maintained By",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    `${element.updatedBy}`,

            },
            {
                columnDef: "Result",
                header: "Result",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    // `${element.aiResult}`,
                    this.replaceWithEmpty(element.aiResult)
            },

        ];
    }


    replaceWithEmptyCellName(value) {
        // // console.log(value);

        if (value.cellName != undefined && value.cellName != null) {
            // // console.log('name=',createdBy.name,createdBy)
            return value.cellName
        }
        else {
            return value.cellId
        }
    }

    replaceWithEmptycreted(value) {
        // // console.log(value);

        if (value.createdBy != undefined && value.createdBy != null) {
            // // console.log('name=',createdBy.name,createdBy)
            if (value.createdBy.time != undefined) {
                return this.getDateStringMomentByTz(value.createdBy.time)
            }
        }
        else {
            return this.getDateStringMomentByTz(value.currentDate)
        }
    }

    viewmaintenance(data) {
        // console.log(data,data.type,data.barcode)

        if (data.type == 'Waterline' && data.type != undefined) {
            return data.barcode;
        }
        else {
            return " "
        }
    }
    replaceWithCreatedBy(createdBy) {

        if (createdBy != undefined) {
            // // console.log('name=',createdBy.name,createdBy)
            if (createdBy.name != undefined) {
                return createdBy.name
            }
            else {
                return ' '
            }
        }
        else {
            return '-'
        }
    }

    replaceWithEmpty(value) {
        const exitingValue = value;

        if (value == undefined) {

            return '-';
        }
        else {
            return exitingValue
        }
    }

    getDateStringMomentByTz(time): string {
        // // console.log("updatedon",time)

        if (time != undefined && time != "-") {
            var timestamp = parseInt(time)
            if (timestamp) {
                if (timestamp < 0)
                    timestamp = timestamp * -1;
                const localTimeZone = moment(timestamp).tz(this.userSevice.getCookie('defaultTz'));
                return localTimeZone.format('DD-MM-YYYY | HH:mm');
            }
        }
        else {
            return time;
        }
    }
    getStringOrDefault(reqkey: any, defaulvalue: any) {
        const rg = defaulvalue;
        try {
            if (reqkey === undefined || reqkey === null) {
                return defaulvalue;
            } else {
                return reqkey;
            }
        } catch (error) {
            rg == defaulvalue;
        }
    }


    updateRow(row): any {
        if (row) {
            row.date = timeZone(Math.abs(row.startTimeNum)).tz(this.userSevice.getCookie('defaultTz')).format('DD-MM-YYYY');
            row.time = timeZone(Math.abs(row.startTimeNum)).tz(this.userSevice.getCookie('defaultTz')).format('HH:mm');
        }
    }

    viewLOad(barcode) {
        this.router.navigate(['/loaddetail'], { queryParams: { barcode: barcode } });
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }

    openDetail(Url) {
        window.open(Url, "_blank");
    }



    tableContentChanged() {
        const table = document.querySelector('mat-table');
        table.scrollBy(0, table.scrollHeight);
    }
    getOrderByColumn(): string {
        return 'doaTimestamp';
    }

    getTableName(): string {
        return 'patientResult';
    }

    ngAfterViewInit(): void {
    }



    // ngOnDestroy(): void {
    //   WaterlineTestComponent.isComeBack = true
    //     // console.log('ngOnDestroy length' + this.dataN.length)
    //     this.subscription.unsubscribe();
    //     WaterlineTestComponent.oldData = this.dataSource.data
    //     // WaterlineTestComponent.cursor = this.paginationService.getCursor()
    // }

    hasMore = () => {
        return !this.dataSource || this.dataSource.data.length < this.limit;
    }

    nextPage() {
        this.isLoading = true;
        this.isNextPressed = true;
        // this.paginationService.more();
        // console.log("progress bar=",this.perPageData,this.dataCount,this.isLoading,this.isMoreDataAvailable)
    }

    isDataAvailable() {
        if (this.dataSource && this.dataSource.data.length > 0) {
            return true;
        }
        return false;
    }

    isMoreDataAvailableFun() {
        if (this.isMoreDataAvailable && this.dataSource.data.length % this.limit === 0) {
            return true;
        }
        return false;
    }

    // public onClickSearch() {

    //     if(this.searchText && this.searchText.length > 0){
    //         this.loadListService.getLoadById(this.searchText).subscribe(resultData => {
    //             // // console.log('Result Search=',resultData);
    //         //    localStorage.setItem('searchResult','');
    //         //    this.searchData=''
    //             this.loadSearchBarcode=resultData;
    //             // console.log(this.loadSearchBarcode)
    //              localStorage.setItem('searchResult', JSON.stringify(this.loadSearchBarcode));
    //             if(resultData && resultData.length >0){
    //                 this.router.navigate(['/loaddetail'], { queryParams: { barcode: this.searchText } });
    //             } else {
    //                 this.showAlert(`Load not found with barcode "` + this.searchText + `"`);
    //             }
    //         });
    //     }
    // }
    showAlert(message): void {
        const dialogUtil = new DialogUtil();
        dialogUtil.openAlertDialog(this.dialog, message, {
            onConfirm: () => {
            },
            onCancel: () => {
            },
            onAlways: () => {

            }
        });
    }

    // added for clear input and refresh data
    clearInputSearch() {
        this.searchText = '';
    }

    refreshSearch(event) {
        // if (event.code === 'Backspace' && this.searchText.length === 1) {
        //     this.searchText = '';
        //     this.onClickSearch();
        // }
    }


    @HostListener('document:keypress', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        this.handleEnterSearch(event);
    }


    handleEnterSearch(event: KeyboardEvent) {
        if (event.key.includes('Enter')
            && event.code.includes('Enter')
            && document.activeElement.id.includes('search')) {
            this.isEnterPressed = true;
            // if (this.searchText && this.searchText.length > 0) {
            //     this.onClickSearch();
            // }
        } else {
            this.isEnterPressed = false;
        }
    }
}