import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AnalyticsService } from '../home/analytics.service';
import { throwError } from 'rxjs';
import { DateAdapter, ErrorStateMatcher, MAT_DATE_FORMATS } from '@angular/material/core';
import { APP_DATE_FORMATS, AppDateAdapter } from './format-datepicker';
import { ApiService } from '../../common/service/api.service';
import { StorageService } from 'app/main/module/common/service/storage.service';
import { ReportService } from "./report.service";
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
// import * as moment from 'moment';
import * as moment from 'moment-timezone';
import 'moment-timezone'; // Import the timezone data
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ActivatedRoute, Router } from '@angular/router';


declare var google: any;

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}


@Component({
    selector: 'alert',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
    providers: [
        DatePipe,
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
    ]
})
export class ReportComponent implements OnInit {
    reportForm: FormGroup;
    reportId: any;
    patientName: any;
    loadStatus: boolean;
    // analyticService: any;
    range: Range;
    labs: string[] = []; // ['DownTown'];
    dateRange: any;
    colorGreen = 'green';

    @Input()
    max: 'D' | null;
    today = new Date();

    @Input()
    min: 'D' | null;
    startAt: any;
    onclicktrue = false;
    filename: string;
    myDate: any;
    centerName: any;
    pdfFileName: string;
    apiName: string;
    allData: any;
    errorMessage: any;
    isLoginFailed: boolean;
    http: any;
    // filteredPatients: any;
    filteredPatients: Array<any> = [];

    getStatus: any;
    getError: any;
    labsDataId: any;
    default_time_zone: any;


    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService, public reportService: ReportService,
        private formBuilder: FormBuilder,
        private apiService: ApiService,
        private userService: StorageService, private datePipe: DatePipe,
        public snackBar: MatSnackBar,
        private router: Router,

    ) {
        this.today.setDate(this.today.getDate());
        this.myDate = new Date();

        this.myDate = (this.datePipe.transform(this.myDate, 'yyyy-MM-dd_hh-mm-ss_a')).trim();

    }

    reportType = [
        { name: 'Loaded Sets Report', abbrev: 'cassetteReport' },
        { name: 'Sterilizer Load Report', abbrev: 'loadReport' },
        { name: 'Master Report', abbrev: 'masterReport' },
        { name: 'Assign Sets Report', abbrev: 'assignCassetteReport' },
        { name: 'Alert Report', abbrev: 'alertReport' },
        { name: 'BI Alert Report', abbrev: 'BIAlertReport' },
        { name: 'Availability Report', abbrev: 'availabilityReport' },
        { name: 'SOD and EOD Report', abbrev: 'sodEodReport' },
        { name: 'Sterilizer Maintenance Load Report', abbrev: 'sterilizerMaintenanceLoadReport' },
        { name: 'Other Maintenance Report', abbrev: 'otherMaintenanceReport' },
        { name: 'Control BI Report', abbrev: 'controlBIReport' },
        { name: 'Sets Returned TO CSSD Report', abbrev: 'setsReturnedCSSD' },

    ];

    ngOnInit() {
        const labName = this.userService.getCookie("lab_id");
        if (labName) {
            this.labs.push(labName);
            this.reportForm = this.formBuilder.group({
                labListName: ['', Validators.required],
                // departmentName: [''],
                from: ['', Validators.required],
                to: ['', Validators.required],
            });
            this.onclickgetDocuments();
            this.getuserdata();
        } else {
            this.logout();
        }
    }

    logout(): void {
        localStorage.clear();
        this.userService.clearCookie();
        this.router.navigate(['/login']);
        // location.reload();
    }

    async getuserdata(): Promise<void> {
        this.apiService.getLabs().subscribe(
            a => {
                Object.entries(a).find(([key, value]) => {
                    if (key == 'data') {
                        this.labsDataId = value;
                        this.labsDataId.forEach(element => {
                            console.log('lab=', element)
                            console.log('default_time_zone=', element.default_time_zone)
                            this.default_time_zone = element.default_time_zone;
                            // fromDateVale();
                        });
                    }
                    else {
                    }
                })
            },
            err => {
                this.errorMessage = err.error.message;
            }
        );
    }

    fromDateVale(event: MatDatepickerInputEvent<Date>): number | void {
        const selectedDate = event.value; // Get the selected date
        const epochTimestamp = selectedDate ? selectedDate.getTime() : null; // Convert to Unix timestamp
        // Adjust the timestamp based on the time zone
        const adjustedTimestamp = epochTimestamp ? moment.tz(epochTimestamp, this.default_time_zone).unix() : null;
        console.log('Epoch timestamp:', adjustedTimestamp);
        return adjustedTimestamp;
    }



    onclickgetDocuments() {
        this.apiService.getDocumentsApi().subscribe({
            next: data => {
                data['data'].forEach(element => {
                    if (element.display_name != undefined) {
                        var displayName = element.display_name;
                        this.filteredPatients.push(displayName)

                    } else {
                        this.openSnackBar("Department Name Not Found.", "OK");
                    }
                });

            },
            error: HttpErrorResponse => {
                this.getStatus = HttpErrorResponse.error.status;
                this.getError = HttpErrorResponse.error.error;
            }
        });
        return this.filteredPatients;
    }

    fromDateVal(value) {
        this.startAt = value;
    }

    reportOnChangeVal(reportId): void {
        this.reportId = reportId;
    }

    departmentOnChangeVal(patientName): void {
        this.patientName = patientName;
    }

    updateRange(range: Range) {
        this.range = range;
    }

    convertToTimestamp(dateString: string, timezone: string): number {
        // const date = moment.tz(dateString, 'DD/MM/YYYY', timezone);
        // console.log("date.format() = ",date.format())

        // return date.format(); // Returns ISO string


        // const dateParts = dateString.split('/');
        // const jsDate = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
        // // Use moment-timezone to convert and format the date
        // const date = moment.tz(jsDate, timezone);
        // console.log("date.format() = ",date.format())
        // return date.format(); // Returns ISO string

        const dateParts = dateString.split('/');
        const day = +dateParts[0];
        const month = +dateParts[1];
        const year = +dateParts[2];

        // Create a JavaScript Date object using the parsed date parts
        const jsDate = new Date(year, month - 1, day);

        // Use moment-timezone to convert the date to the specified timezone
        const dateInTimezone = moment.tz(jsDate, timezone);

        // Get the Unix epoch timestamp (number of seconds since January 1, 1970)
        const timestamp = dateInTimezone.unix();
        console.log("timestamp = ", timestamp)

        return timestamp;

    }

    downPdf1(fromD, toD, patientId, patientName): void {
        console.log("fromD toDate = ", fromD, toD)

        if (this.default_time_zone.includes('America/Toronto')) {
            fromD = fromD ? moment.tz(fromD, 'DD/MM/YYYY', this.default_time_zone).format("DD/MM/YYYY") : moment().format("DD/MM/YYYY");
            toD = toD ? moment.tz(toD, 'DD/MM/YYYY', this.default_time_zone).format("DD/MM/YYYY") : moment().format("DD/MM/YYYY");
        } else {
            // fromD = fromD ? moment.tz(fromD, 'DD/MM/YYYY', this.default_time_zone).add(1, 'day').format("DD/MM/YYYY") : moment().format("DD/MM/YYYY");
            // toD = toD ? moment.tz(toD, 'DD/MM/YYYY', this.default_time_zone).add(1, 'day').format("DD/MM/YYYY") : moment().format("DD/MM/YYYY");
            fromD = fromD ? moment.tz(fromD, 'DD/MM/YYYY', this.default_time_zone).format("DD/MM/YYYY") : moment().format("DD/MM/YYYY");
            toD = toD ? moment.tz(toD, 'DD/MM/YYYY', this.default_time_zone).format("DD/MM/YYYY") : moment().format("DD/MM/YYYY");
        }

        const fromTimestamp = moment.tz(fromD, 'DD/MM/YYYY', this.default_time_zone).valueOf();
        const toTimestamp = moment.tz(toD, 'DD/MM/YYYY', this.default_time_zone).valueOf() + 86309000;

        console.log("From Timestamp:", fromTimestamp);
        console.log("To Timestamp (with tonight at midnight):", toTimestamp);

        this.loadStatus = true;
        if (true) {
            this.centerName = this.getStringOrDefault(this.userService.getCookie("center"), "");
            this.onclicktrue = true;
            this.filename = (this.reportId.trim() + '_' + this.myDate + '.pdf').trim();
            //  // console.log(this.filename);
            if (this.reportId == 'cassetteReport') {
                this.apiName = 'sets/report?'
                this.pdfFileName = 'CR'

            } else if (this.reportId == 'loadReport') {
                this.apiName = 'sterilizerload/report?'
                this.pdfFileName = 'SLR'

            } else if (this.reportId == 'masterReport') {
                this.apiName = 'master/report?'
                this.pdfFileName = 'MR'

            } else if (this.reportId == 'assignCassetteReport') {
                if (this.patientName != undefined) {
                    this.apiName = 'assignsets/report?department=' + this.patientName + '&'
                } else {
                    this.apiName = 'assignsets/report?'
                }
                this.pdfFileName = 'ACR'

            } else if (this.reportId == 'alertReport') {
                this.apiName = 'alert/report?'
                this.pdfFileName = 'Alert'

            } else if (this.reportId == 'BIAlertReport') {
                this.apiName = 'bialert/report?'
                this.pdfFileName = 'BiAlert'

            } else if (this.reportId == 'availabilityReport') {
                this.apiName = 'availability/report?'
                this.pdfFileName = 'AVR'

            } else if (this.reportId == 'sodEodReport') {
                this.apiName = 'dailytask/report?'
                this.pdfFileName = 'SOD_EOD'
            } else if (this.reportId == 'sterilizerMaintenanceLoadReport') {
                this.apiName = 'mnloads/report?'
                this.pdfFileName = 'SMnLR'
            } else if (this.reportId == 'otherMaintenanceReport') {
                this.apiName = 'othermn/report?'
                this.pdfFileName = 'OMnR'
            } else if (this.reportId == 'controlBIReport') {
                this.apiName = 'cbis/report?'
                this.pdfFileName = 'CBI'
            }
            else if (this.reportId == 'setsReturnedCSSD') {//Sets Returned to CSSD
                this.apiName = 'returned/report?'
                this.pdfFileName = 'CSSD'
            }

            this.filename = ('WEB_' + this.pdfFileName + fromD + 'to' + toD + '.pdf').trim();
            // this.filename = ('WEB_' + this.pdfFileName + from + 'to' + to + '.pdf').trim();
            console.log('apiName =' + this.apiName);
            console.log('reportId =' + this.reportId);


        }

        // this.reportService.getPdfUrlReportByReportId(afterfromTimestamp, aftertoTimestamp, this.apiName).subscribe(
        this.reportService.getPdfUrlReportByReportId(fromTimestamp, toTimestamp, this.apiName).subscribe(
            a => {
                // console.log(" All Data ==", a,from,to,this.apiName)
                Object.entries(a).find(([key, value]) => {

                    if (key == 'data' && value != "") {
                        this.allData = value;
                        const msg = 'Data Found';
                        if (msg == "Data Found") {


                            this.downloadPdf(this.allData, this.filename);

                        }
                        //   this.reportForm.reset();
                    } else if (key == 'status' && value == 'failed') {
                        this.loadStatus = false;
                        // // console.log(key, '=', value);
                        const msg = 'No Data Found';
                        this.openSnackBar(msg, 'OK');
                        // this.reportForm.reset();
                    }
                })

            },

            err => {
                this.errorMessage = err.error.message;
                // console.log('message', err.status, this.errorMessage)
                // this.openSnackBar("Report Download Succesfully", 'OK');
                this.isLoginFailed = true;
            }
        );

    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 1000,
        });
    }


    getTimestampData(dateRangeVal: any) {
        const rangeVal = dateRangeVal;
        const rangeDatesArr = rangeVal.split('-');
        const dFrom = rangeDatesArr[0].split('-');
        const dTo = rangeDatesArr[1].split('-');
        return [new Date(dFrom).getTime(), new Date(dTo).getTime()];
    }
    getStringOrDefault(reqkey: any, defaulvalue: any) {
        const rg = defaulvalue
        try {
            if (reqkey === undefined || reqkey === null) {
                return defaulvalue
            } else {
                return reqkey
            }
        } catch (error) {
            rg == defaulvalue
        }
    }

    downloadPdf(pdfUrl, filename): void {
        this.reportService.downloadPdf(pdfUrl).subscribe((response: Blob) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            const file = new File([blob], filename, { type: 'application/pdf' });
            const url = URL.createObjectURL(file);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            a.title = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
            this.openSnackBar("Report Download Successfully", 'OK');
            this.loadStatus = false;
        });
    }

}
