import {
    AfterViewInit, ApplicationRef,
    ChangeDetectorRef,
    Component,
    ElementRef, HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { Subscription } from 'rxjs';
import { StorageService } from '../../../common/service/storage.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfigService } from "../../../common/config/config.service";
import { Globals } from "../../../common/global";
import { MatDialog } from "@angular/material/dialog";
//  import { OthermachineService } from './othermachine.service';
import { Maintenance } from '../maintenance';
import * as moment from 'moment-timezone';
import { Load } from '../../load/load';
import { MaintenanceService } from '../maintenance.service';

// import { MaintenaceService } from '../maintenace.service';


const timeZone = require('moment-timezone');

@Component({
    selector: 'app-othermachine',
    templateUrl: './othermachine.component.html',
    styleUrls: ['./othermachine.component.scss'],
    animations: fuseAnimations
})
export class OthermachineComponent implements OnInit {

    patient: string;
    data = [];
    columns = this.getColumn();
    titleMatIcon = this.getTitleMatIcon();
    title = this.getTitle();
    displayedColumns = this.columns
        .filter((c) => c.hide === false)
        .map((c) => c.columnDef);
    dataSource = new MatTableDataSource<any>(this.data);
    @ViewChild("filter", { static: true })
    filter: ElementRef;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;
    // isLoading = true;
    // alertSign = "";
    // enableInfoMsg = false;
    // protected extraCta = {
    //     visibility: false,
    //     text: "",
    // };

    page: number;
    hasFirstDataShown = false;
    private dataSubscription: Subscription;
    errorMessage: any;
    isInitComplete: boolean;
    maintenanceOtherData: Maintenance[] = [];
    isLoading: boolean;
    prev: number;
    next: number;
    count: number;
    isNextPressed: boolean;
    paginationDisabled: boolean;


    constructor(
        private userSevice1Child: StorageService,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        public snackBar: MatSnackBar,
        // public historyService:MaintenaceService,
        public global: Globals,
        public configService: ConfigService,
        private paginationService: MaintenanceService,
        private router: Router,
    ) {
        //  super(snackBar, configService, global);


    }

    /**
     * On init
     */
    ngOnInit(): void {
        const lab_id = this.userSevice1Child.getCookie("lab_id");
        if (lab_id) {
            this.isInitComplete = true;
            this.dataSource.data = [];
            this.maintenanceOtherData = [];
            // this.getCassetteConfig();
            this.isLoading = true;
            this.prev = 0;
            this.next = 1;
            this.page = 0;
            this.count = 1;
            this.getAllData(1);
        } else {
            this.logout();
        }
    }

    logout(): void {
        localStorage.clear();
        this.userSevice1Child.clearCookie();
        this.router.navigate(['/login']);
    }

    getAllData(page) {
        this.isInitComplete = true;
        this.paginationService.getAllDataOtheremaintenace(page).subscribe(
            a => {
                //   // console.log(" All maintenanceload Data ==", a,);
                Object.entries(a).find(([key, value]) => {

                    if (key == "message" && value == "No data Found") {
                        this.paginationDisabled = true;
                    }
                    if (key == 'data') {
                        this.isInitComplete = false;
                        this.data = value;
                        this.data.forEach(element => {
                            // // console.log("current date=",element.cell_name)
                            this.maintenanceOtherData.push({
                                cellName: (element.cell_name ? element.cell_name : element.cell_id),
                                type: element.type,
                                machineName: element.machine_name,
                                cycleNo: element.cycle_number,
                                barcode: element.load_id,
                                createdBy: element.created_by,
                                createdOn: element,
                                startedBy: element.created_by,
                                updatedOn: element.updated_by.timestamp,
                                updatedBy: element.updated_by,
                                aiResult: element.jarvis_result,
                                status: element.jarvis_result,
                                aiLink: element.load_report_url,
                                id: "",
                                docId: ""
                            })

                        })


                    }

                    else {
                        // // console.log(key,'=',value);
                    }
                })


                this.dataSource.data = this.maintenanceOtherData;
                //  // console.log("datasource=",this.dataSource.data)

            },

            err => {
                this.errorMessage = err.error.message;
                // console.log('unauthorised', err.status, this.errorMessage)
                if (err.status == 401) {
                    alert('Token is invalid or has expired Please relogin')
                    // this.logout()
                }

            }
        );

    }

    onNextPress() {
        this.isLoading = true;
        this.isNextPressed = true;
        this.count = this.count + 1;
        this.next = this.prev + 1;
        this.prev = this.next - 1
        this.next++
        this.prev++
        this.getAllData(this.next)
        // console.log("next=", this.next, this.prev);

    }

    getRowBackgroundColor(element): string {
        // if (element.deleted == true) {
        //     return "lightgrey";
        // } else
        return "#FFFFFF";
    }

    getRowFontColor(element) {
        if (element.deleted == true) {
            return "italic";
        } else return "bold";
    }

    getFontColor(element) {
        if (element.deleted == true) {
            return "#808080";
        } else return "#000000";
    }
    getCrossWord(element) {
        if (element.deleted == true) {
            return "line-through";
        } else return "overline";
    }

    getColumn(): any[] {

        return [
            {
                columnDef: "Sr",
                header: "Sr.",
                hide: false,
                cell: (element: Load, index: number) =>
                    `${this.page * 10 + index + 1}`,
            },
            {
                columnDef: "Name",
                header: "Name",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    `${element.cellName}`,
                //   this.replaceWithEmptyCellName(element)
            },
            {
                columnDef: "Type",
                header: "Type",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    `${this.replaceWithEmpty(element.type)}`,
                // this.replaceWithEmpty(element.type)

            },

            //   {
            //       columnDef: "CycleNo",
            //       header: "CycleNo",
            //       hide: false,
            //       cell: (element: Maintenance, index: number) => 
            //     //   `${element.cycleNo}`,
            //     this.replaceWithEmpty(element.cycleNo)
            //   },

            {
                columnDef: "MaintenanceNo",
                header: "Created On",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    `${this.replaceWithEmptycreted(element)}`,
                // this.replaceWithEmptycreted(element)
            },

            {
                columnDef: "CreatedBy",
                header: "Created By",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    `${this.replaceWithCreatedBy(element.createdBy)}`,
                //   this.replaceWithCreatedBy(element.createdBy)
            },

            {
                columnDef: "MaintainedOn",
                header: "Maintained On",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    `${this.getDateStringMomentByTz(element.updatedOn)}`,

            },

            {
                columnDef: "MaintainedBy",
                header: "Maintained By",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    `${this.replaceWithEmpty(element.updatedBy.name)}`,
                //   this.replaceWithEmpty(element.updatedBy)
            },
            {
                columnDef: "Result",
                header: "Result",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    `${this.replaceWithEmpty(element.aiResult)}`,
                //    this.replaceWithEmpty(element.aiResult)
            },

        ];
    }
    replaceWithCreatedBy(createdBy) {

        if (createdBy != undefined) {
            // // console.log('name=',createdBy.name,createdBy)
            if (createdBy.name != undefined) {
                return createdBy.name
            }
            else {
                return ' '
            }
        }
        else {
            return '-'
        }
    }

    replaceWithEmptyCellName(value) {
        // // console.log(value);

        if (value.cellName != undefined && value.cellName != null) {
            // // console.log('name=',createdBy.name,createdBy)
            return value.cellName
        }
        else {
            return value.cellId
        }
    }

    replaceWithEmptycreted(value) {
        // // console.log(value);
        if (value.createdBy != undefined && value.createdBy != null) {
            // // console.log('If calling')
            if (value.createdBy.timestamp != undefined) {
                return this.getDateStringMomentByTz(value.createdBy.timestamp)
            }

        }
        else {
            // // console.log('else calling')
            return this.getDateStringMomentByTz(value.updated_timestamp)
        }
    }

    replaceWithEmpty(value) {
        const exitingValue = value;

        if (value == undefined) {

            return '-';
        }
        else {
            return exitingValue
        }
    }

    getDateStringMomentByTz(time): string {
        // // console.log("updatedon",time)

        if (time != undefined && time != "-") {
            var timestamp = parseInt(time)
            if (timestamp) {
                if (timestamp < 0)
                    timestamp = timestamp * -1;
                const localTimeZone = moment(timestamp).tz(this.userSevice1Child.getCookie('defaultTz'));
                return localTimeZone.format('DD-MM-YYYY | HH:mm');
            }
        }
        else {
            return time;
        }
    }


    getStringOrDefault(reqkey: any, defaulvalue: any) {
        const rg = defaulvalue;
        try {
            if (reqkey === undefined || reqkey === null) {
                return defaulvalue;
            } else {
                return reqkey;
            }
        } catch (error) {
            rg == defaulvalue;
        }
    }







    updateRow(row): any {
        // setDeriveFields(row);
    }

    // ngOnDestroy() {
    //     this.subscription.unsubscribe();
    //     OthermachineComponent.oldData = this.dataSource.data;
    //     // OthermachineComponent.cursor = this.infiniteScroll.getCursor();
    // }

    getTitleMatIcon(): string {
        return "art_track";
    }

    getTitle(): string {
        return "Other Maintenance History";
    }

    getSearchText(): string {
        return "Search Patient via name";
    }





    addZeroToHourAndMinutes(date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        const strTime = hours + ":" + minutes + " " + ampm;
        return strTime;
        // if (time < 10) {
        //     return '0' + time;
        // }
        // return time;
    }

    getMapValue(stringData) {
        const param = stringData.replace(/[^a-zA-Z0-9]/g, "");
        const obj = {};
        for (let j = 0; j <= param.length - 1; j++) {
            let emptyString = "";
            for (let k = 0; k <= j; k++) {
                emptyString += param[k];
            }

            obj[emptyString] = true;
        }
        return obj;
    }

    toUpperCaseString(str) {
        return str.trim().toUpperCase();
    }


    private handleError(error: Error): void {
        // this.openSnackBar(error.message, "OK");
        // this.notify.update(error.message, 'error');
    }




    changeTimestampToDate(timestamp) {
        if (timestamp) {
            // new Date('03/17/2021');
            // return timeZone(timestamp).tz(this.userSevice1Child.getCookie('defaultTz')).format('DD/MM/YYYY');
            return moment(timestamp).format("DD/MM/YYYY | HH:mm");
        }
        return "";
    }

    @HostListener("document:keypress", ["$event"])
    handleKeyboardEvent(event: KeyboardEvent) {
        // this.handleEnterSearch(event);
    }

    // getSubscriptionType() {
    //     // console.log("navType", this.navigationType);
    //     switch (this.navigationType) {
    //         case "screening":
    //             return true;
    //         default:
    //             return false;
    //     }
    // }
}


