import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-image-dialog-component',
  templateUrl: './image-dialog-component.component.html',
  styleUrls: ['./image-dialog-component.component.scss']
})
export class ImageDialogComponentComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ImageDialogComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

}
