import { AfterViewInit, Component, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { fromEvent, merge, Observable, Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CassetteListService } from '../list/cassette-list.service';
import { AssignService } from '../assign/assign.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StatusService } from '../../status/status.service';
import { Cassette } from "../cassette";
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from 'app/main/module/common/service/storage.service';
import { ApiService } from '../../../common/service/api.service';
import { Globals } from '../../../common/global';
import { DialogUtil } from "../../../../util/DialogUtil";
import { Location } from '@angular/common';

import { I } from '@angular/cdk/keycodes';
// import { Cassette } from '../cassette.module';


const timeZone = require('moment-timezone');
@Component({
    selector: 'app-viewcassettedetails',
    templateUrl: './viewcassettedetails.component.html',
    styleUrls: ['./viewcassettedetails.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class ViewcassettedetailsComponent implements AfterViewInit, OnInit {
    dataSource = new MatTableDataSource<any>();
    displayedColumns: string[] = ['sr', 'name', 'code', 'quantity'];
    urlData = '';
    viewcassetteData: any;
    barcode = '';
    cycleNo = '';
    name = '';
    id = '';
    time = '';
    type = '';
    program = '';
    loadNo: number;
    machineName = '';
    status = '';
    result = ' ';
    barcodeBi: any;
    barcodeEi: any;
    barcodeBd: any;
    isAssignDone: boolean;
    statusBi: string;
    statusEi: string;
    statusBd: string;
    date = new Date();
    assignedBy = '';
    assignedOn: any;
    status1 = '';
    spoiledTime = '';
    spoiledName = '';
    addedName = '';
    addedOn = '';
    processedBy = '';
    processedOn = '';
    canAssignIncubator: boolean;

    cstObservableData: Observable<any>;
    assignedDepartment: any;
    asignedDepartmentname: any;
    receivedBy: any;
    quantity: any;
    instrumentsName: any;
    instruments: any = [];
    departments: any;
    categories: any;
    subcategories: any;
    displayName: any;
    comment: any;
    verified: string;
    verifiedBy: any;
    verifiedOn: any;
    href: string;
    currentPageURL: string;
    getbarcodefromParam: string;
    errorMessage: any;
    cassetteDetail: Array<any>;
    loadedcassetteDatadetail: Cassette[] = [];
    can_assign_if_incubator_pending: any;
    assignedpatientName: any;
    assignedpatientId: string;
    isAssignedPatientDone: boolean;
    assignedByPatient: any;
    assignedbypatientOn: any;
    role: string;
    hasFirstDataShown: boolean = false;
    comment_on_return: any;
    isReturnDone: boolean;
    returnBy: any;
    returnOn: any;

    constructor(public cassetteListService: CassetteListService,
        private paginationService: AssignService,
        private statusService: StatusService,
        private globals: Globals,
        private route: ActivatedRoute, private router: Router,
        private assignService: AssignService, private snackBar: MatSnackBar,
        private cookieService: CookieService,
        private userService: StorageService,
        public dialog: MatDialog, public apiService: ApiService, private location: Location) {
    }


    ngOnInit(): void {
        const lab_id = this.userService.getCookie("lab_id");
        if (lab_id) {
            this.hasFirstDataShown = false;
            this.loadedcassetteDatadetail = [];
            // this.urlData = this.route.snapshot.queryParams.barcode;
            console.log(this.urlData);
            this.href = this.router.url;
            // console.log('urldata',this.router.url);
            this.currentPageURL = (window.location.href).toString();
            this.getCassetteConfig();
            this.getbarcodefromParam = this.currentPageURL.split('=')[1];
            //   console.log("sss",getbarcodefromParam)
            if (this.getbarcodefromParam !== '' && this.getbarcodefromParam !== undefined) {
                console.log('getbarcodefromParam= ' + this.getbarcodefromParam)
                this.getAllData(this.getbarcodefromParam);
            }
            else {

                window.alert('No Data found, try again!');
            }

            this.role = localStorage.getItem('role');
        } else {
            this.logout();
        }
    }

    getAllData(barcode) {
        this.status = '';
        this.cassetteDetail = [];
        // this.isAssignedPatientDone = false
        const cassetteConfig = this.globals.cassetteConfig;
        this.cassetteListService.getSetsDetailByBarcode(barcode).subscribe(
            a => {
                console.log(" All Sets Detail by  barcode ==", a, barcode)
                Object.entries(a).find(([key, value]) => {
                    //     if(key == 'total_pages'){
                    //    this.totalPage = value;
                    //     }
                    if (key == 'data') {
                        this.cassetteDetail = value;
                        // console.log('cassetteDetail= ' + this.cassetteDetail)
                        this.cassetteDetail.forEach(element => {
                            this.hasFirstDataShown = true;
                            this.instruments = element.instruments
                            this.dataSource = this.instruments;
                            // console.log(this.instruments)
                            if (element.verified == false) {
                                this.verified = "No";
                            }
                            else if (element.verified == true) {
                                this.verified = "Yes";
                            }
                            this.viewcassetteData = element;
                            this.barcode = element.barcode;
                            this.program = element.set_program;
                            this.cycleNo = element.cycle_number;
                            this.loadNo = element.load_number;
                            this.machineName = element.machine_name;
                            this.departments = element.departments;
                            this.asignedDepartmentname = element.assigned_to_department
                            this.comment = element.comment ? element.comment : '-';;
                            console.log("comm=", this.comment)
                            this.categories = element.categories;
                            this.subcategories = element.subcategories;
                            this.displayName = element.display_name;
                            this.status1 = element.set_status; // being used as a condition in html page
                            this.barcodeBd = element.bd_barcode ? element.bd_barcode : '-';
                            this.barcodeBi = element.bi_barcode;
                            this.barcodeEi = element.ei_barcode;
                            this.statusBi = element.bi_status;
                            this.statusEi = element.ei_status;
                            this.statusBd = element.bd_status;
                            const cass: Cassette = element;
                            // console.log('>>> statusBi= ' + cass.bi_status)
                            this.isAssignDone = element.is_assigned_to_department;
                            cass.isAssignDone = element.is_assigned_to_department
                            this.isAssignedPatientDone = element.is_assigned_to_patient;
                            // this.result = this.statusService.getCassetteResult(cass);
                            // console.log("Result=",this.result);
                            if (element.department_assigned_by) {
                                this.assignedBy = element.department_assigned_by.name;
                                this.assignedOn = this.updateDate(element.department_assigned_by.timestamp);
                                // cass.isAssignDone = element.department_assigned_by.name
                            }

                            if (element.return_by) {
                                this.isReturnDone = true;
                                this.returnBy = element.return_by.name;
                                this.returnOn = this.updateDate(element.return_by.timestamp);
                                this.comment_on_return = element.comment_on_return;
                                // cass.isAssignDone = element.department_assigned_by.name
                            }


                            let eStatus: EStatus = this.statusService.getStatusNew(element.bd_status, element.bi_status, element.ei_status);
                            // console.log('eStatus= ' + eStatus)
                            this.result = this.statusService.getCassetteResult(eStatus);
                            // console.log('eStatus= ' + this.result)

                            if (element.set_status == "Assigned") {
                                // this.status = "Assigned Department"
                                this.status = this.statusService.getCassetteStatus(element);

                                //   console.log(' status = ' + this.status)

                            }
                            else
                                // console.log("element.set_status ", element.set_status)
                                if (element.set_status == "Incubator_Failed") {
                                    this.status = element.set_status;
                                } else if (element.set_status == "Used") {
                                    if (this.result == "Pending") {
                                        this.status = "Used(P)";
                                    }
                                    else {
                                        this.status = "Used";
                                    }

                                } else if (element.set_status == "Returned") {
                                    if (this.result == "Pending") {
                                        this.status = "Returned(P)";
                                    }
                                    else {
                                        this.status = "Returned";
                                    }
                                }
                                else if (element.set_status != "Used" && element.set_status != "Assigned") {
                                    this.status = this.getLoadStatus(element);
                                    console.log("another status", this.status)
                                }


                            if (element.set_type == 'Implant') {
                                this.type = 'Implant-' + element.print_title;
                            } else {
                                this.type = element.print_title;
                            }
                            if (element.added_by) {
                                this.addedName = element.added_by.name;
                                // console.log(this.addedName);
                                this.addedOn = this.updateDate(element.added_by.timestamp);
                            } else if (element.created_by) {
                                this.addedName = element.created_by.name;
                                this.addedOn = this.updateDate(element.created_by.timestamp);
                            }
                            if (element.updated_by) {
                                this.processedBy = element.updated_by.name;
                                this.processedOn = this.updateDate(element.updated_by.timestamp);
                            }
                            if (element.received_by) {
                                this.receivedBy = element.received_by.name;
                            }
                            if (element.received_by) {
                                // this.assignedBy = element.received_by.name;
                                // console.log(this.assignedBy)
                                // // this.assignedOn =  element.assigned.time;
                                // this.assignedOn = this.updateDate(element.received_by.timestamp);
                                // console.log(this.assignedOn)
                            }

                            if (element.spoiled_by) {
                                this.spoiledName = element.spoiled_by.name;
                                this.spoiledTime = this.updateDate(element.spoiled_by.timestamp);
                            }

                            if (element.is_assigned_to_patient != undefined && element.is_assigned_to_patient == true) {
                                // console.log("used sets for patient")
                                this.assignedpatientName = element.patient.name;
                                this.assignedpatientId = element.patient.id;
                                this.assignedByPatient = element.patient_assigned_by.name;
                                this.assignedbypatientOn = this.updateDate(element.patient_assigned_by.timestamp)

                            }


                        });
                    }
                    else {
                        console.log("else loaddetail calling")
                    }
                })

            });

        err => {
            this.errorMessage = err.error.message;
            console.log('unauthorised', err.status, this.errorMessage)
            if (err.status == 401) {
                console.log("gggg"),
                    alert('Token is invalid or has expired Please relogin.')
                this.logout()
            }

        }


    }

    getLoadStatus(load: Cassette): string {
        return this.statusService.getCassetteStatus(load);
    }



    logout(): void {
        localStorage.clear();
        this.userService.clearCookie();
        // this.globals.cassetteConfig = null;
        this.router.navigate(['/login']);
        // location.reload();

    }

    back(): void {
        this.location.back()
    }

    getCassetteConfig() {
        this.apiService.getLabconfigdata().subscribe(a => {
            console.log('casseeteConfig', a);
            Object.entries(a).find(([key, value]) => {
                if (key == 'data') {
                    value.forEach(element => {
                        Object.entries(element.cassette).find(([key, value]) => {
                            console.log("cassette data  =", key, value);
                            if (key == 'can_assign_if_incubator_pending') {
                                this.can_assign_if_incubator_pending = value;
                                console.log("can_assign_if_incubator_pending", this.can_assign_if_incubator_pending)
                            }

                        })

                    });

                }
            })
        })
    }

    canAssign(cassette): boolean {
        // console.log('calling canAssign >>> ')
        if (this.statusService.canAssign(cassette)) {
            return cassette.barcode;
        }
    }

    ngAfterViewInit(): void {

        const cassetteConfig = this.globals.cassetteConfig;
    }


    updateDate(value): any {

        const dateTime = timeZone(Math.abs(value)).tz(this.userService.getCookie('defaultTz')).format('DD-MM-YYYY | HH:mm');
        return dateTime

    }
    unAssignCassetteFromPatient(barcode) {
        console.log("set for unassign=", barcode);
        const time = new Date().getTime();
        const updateData = {

            barcode: barcode,
            update_data: {
                set_status: 'Assigned',
                //   assigned_to_department: this.department_name,
                is_assigned_to_patient: false,
                // assigned_to_patient:patient_fullName,
                patient: {
                    id: '',
                    name: '',
                    timestamp: ''

                },
                patient_assigned_by: {
                    id: '',
                    name: '',
                    timestamp: ''
                },
                timestamp: time.toString(),
                patient_unassigned_by: {
                    id: this.userService.getCookie('email'),
                    name: this.userService.getCookie('name'),
                    timestamp: time.toString()
                },

            }
        }

        console.log("updatedata=", updateData)
        this.paginationService.assignSets(updateData).subscribe(a => {
            console.log('unassignedassigned data', a)
            if (a) {
                // alert("Set(s) unassigned from patient successfully.");
                // this.barcode='';
                // this.hasFirstDataShown = true;
                this.getAllData(barcode);
            }
        })

    }

    assignToPatient(barcode) {
        this.router.navigate(['/patientcheckout'], { queryParams: { barcode: barcode.trim() } });

    }

    unAssignCassette(barcode) {
        console.log("set for unassign=", barcode);
        const time = new Date().getTime();

        const updateData = {

            barcode: barcode,
            update_data: {
                set_status: 'Ready',
                //   assigned_to_department: this.department_name,

                is_assigned_to_department: false,
                // assigned_to_patient:patient_fullName,
                department_assigned_by: {
                    id: '',
                    name: '',
                    timestamp: ''

                },
                // patient_assigned_by:{},
                timestamp: time.toString(),
                department_unassigned_by: {
                    id: this.userService.getCookie('email'),
                    name: this.userService.getCookie('name'),
                    timestamp: time.toString()
                },

            }
        }

        console.log("updatedata=", updateData)
        this.paginationService.assignSets(updateData).subscribe(a => {
            console.log('unassignedassigned data', a)
            if (a) {
                // alert("Set unassigned from department successfully.");
                // this.barcode='';
                // this.hasFirstDataShown = true;
                this.getAllData(barcode);
            }
        })

    }

    spoilCassette(barcode) {
        // console.log("*************** Not Implemented Yet Need API *************** ") 
        const time = new Date().getTime();
        const updateData = {
            barcode: barcode,
            update_data: {
                set_status: 'Spoiled',
                verified: false,
                attached: false,
                is_spoiled: true,
                timestamp: time.toString(),
                spoiled_by: {
                    id: this.userService.getCookie('email'),
                    name: this.userService.getCookie('name'),
                    timestamp: time.toString()
                }
            }
        }
        console.log("updatedata=", updateData)
        this.paginationService.assignSets(updateData).subscribe(a => {
            console.log('unassignedassigned data', a)
            if (a) {
                window.alert("Set spoiled successfully");
                // this.barcode='';
                // this.getAllData(barcode);
                this.router.navigate(['/cassette']);
            }
        })


    }

    showAlert(message): void {
        const dialogUtil = new DialogUtil();
        dialogUtil.openAlertDialog(this.dialog, message, {
            onConfirm: () => { },
            onCancel: () => { },
            onAlways: () => { },
        });
    }

    assignCassette(barcode) {
        this.router.navigate(['/assign'], { queryParams: { barcode: barcode } });
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 2000,
        });
    }

    getSingleLoadColor(load: string): string {
        if (load) {
            return this.statusService.getColor(this.statusService.getStatus(load));
        } else {
            return '#111111';
        }
    }
    getLoadColor(load: Cassette, result: string): string {
        if (result == 'Failed') {
            return this.statusService.getColor(EStatus.FAIL);
        } else if (load) {
            return this.statusService.getColor(this.statusService.getStatusNew(load.bd_status, load.bi_status, load.ei_status));
        } else {
            return null;
        }
    }
    openDialogWithRef(ref: TemplateRef<any>,): void {
        this.dialog.open(ref, { width: '400px' });
        // this.router.navigate(['/viewtool']);
    }

    openDialogWithRefUnassignDepartment(ref: TemplateRef<any>, barcode): void {
        // this.hasFirstDataShown = false;
        const timeout = 3000;
        const dialogRef = this.dialog.open(ref, { width: '400px' });
        console.log(" this.role = " + this.role);
        // this.router.navigate(['/viewtool']);
        this.unAssignCassette(barcode);

        dialogRef.afterOpened().subscribe(gg => {
            setTimeout(() => {
                dialogRef.close();
                // this.isInitComplete = false;
            }, timeout)
        });

        //need to change role wise
        // if (this.role == 'doctor') {
        //     this.router.navigate(['/cassette/patientcheckoutList']);
        // } else {
        //     // this.router.navigate(['/cassette/cassette']);
        //     console.log("loc = "+this.location.path())
        //     this.location.back();
        // }
        this.location.back();


        dialogRef.afterClosed().subscribe(result => { })
    }

    openDialogWithRefUnassignPatient(ref: TemplateRef<any>, barcode): void {
        // this.hasFirstDataShown = false;
        const timeout = 3000;
        const dialogRef = this.dialog.open(ref, { width: '400px' });

        // this.router.navigate(['/viewtool']);
        this.unAssignCassetteFromPatient(barcode);

        dialogRef.afterOpened().subscribe(gg => {
            setTimeout(() => {
                dialogRef.close();
                // this.isInitComplete = false;
            }, timeout)
        });

        dialogRef.afterClosed().subscribe(result => { })
    }

}
