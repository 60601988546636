import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    HostListener,
    OnInit,
    TemplateRef,
    OnChanges,
    ViewChild,
    ViewEncapsulation, Inject
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { Observable, Subject, Subscription } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { MatTableDataSource } from '@angular/material/table';
import { StorageService } from '../../common/service/storage.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Resolve } from '@angular/router';
import { TeamService } from './team.service';
import { Team } from './team';
import { MatDialog } from '@angular/material/dialog';
import { BaseComponent } from '../../base/base.component';
import { ConfigService } from '../../common/config/config.service';
import { Globals } from '../../common/global';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TextUtils } from '../../../util/TextUtils';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationService } from '../../common/service/authentication.service';
import { ApiService } from '../../common/service/api.service';
import { SuccessfulComponent } from '../patient/successful/successful.component';
import { noop as _noop } from 'lodash-es';
import { takeUntil } from 'rxjs/operators';
import { Role } from '../../common/role';
import { MatTable } from '@angular/material/table';
import { truncate } from 'lodash';
import { IpServiceService } from '../../common/service/ip-service.service';
import { sortAndDeduplicateDiagnostics } from 'typescript';
// import { DOCUMENT} from '@angular/common';
import { LogsService } from '../logs/logs.service';
import { MatPaginator } from '@angular/material/paginator';

@Component({
    selector: 'team-table',
    templateUrl: './team.component.html',
    styleUrls: ['./team.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class TeamComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    // dataSource: MatTableDataSource<any>();
    // dataSource = new MatTableDataSource<any>();
    // displayedColumns: string[] = [ 'avatar', 'name', 'position', 'office', 'email', 'phone'];
    displayedColumns: string[] = ['Sr', 'name', 'role', 'email', 'edit', 'delete'];
    // displayedColumnsNoDelete: string[] = ['Sr', 'name', 'role', 'email', ];
    registerForm: FormGroup;
    registerFormAdmin: FormGroup;
    registerFormEdit: FormGroup;
    staffForm: FormGroup;
    staffDropDown: any;

    @ViewChild('paginator', { static: true }) paginator: MatPaginator;
    // @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('paginatorPageSize', { static: true }) paginatorPageSize: MatPaginator;
    // @ViewChild('empTbSort') empTbSort = new MatSort();
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    role = this.userService.getCookie('role');
    lab = this.userService.getCookie('lab');
    // types: Array<any> = ['user', 'support', 'admin'];
    types: Array<any> = [];
    multiCenters: Array<any> = ['Center1', 'Center2', 'Center3'];
    allRoleData: any;
    currentRoleData: any;
    beforeEdit: any;
    datePick = false;
    tStamp: any;
    message = '';
    msgColor = '';
    color = ['green', 'red', 'blue'];
    isCheckedVal = false;
    validD: any;
    length: any;
    @Input()
    min: 'D' | null;
    today = new Date();
    emailToDel = '';
    perPageData = this.globals.perPageData;
    staffEmail = '';
    staffName = '';
    urlLabName = this.userService.getCookie("lab");
    loggedInEmail = this.userService.getCookie('email');
    // Private
    spinner = false;
    hasFirstDataShown = false;
    private _unsubscribeAll: Subject<any>;
    itemsObservable: Observable<Team[]>;
    limit = 1000;
    full = false;
    isSticky = true;
    roleData: any;
    enableInfoMsg = false;
    dataN: Array<any> = [];
    hasEditDataShown = true;
    // staffScreeningEnable: boolean = true;
    teamName = '';
    mailData: any = {}
    daysArrayList: any = [{
        days: [{ value: 0, valueView: 'Sun', checked: true },
        { value: 1, valueView: 'Mon', checked: true },
        { value: 2, valueView: 'Tue', checked: true },
        { value: 3, valueView: 'Wed', checked: true },
        { value: 4, valueView: 'Thu', checked: true },
        { value: 5, valueView: 'Fri', checked: true },
        { value: 6, valueView: 'Sat', checked: true }],
    }];
    isDisabled = false;
    staticObjArr: any;

    ipAddress = '';
    staffFormSelector: FormGroup;
    loadingSpinner: boolean = true;
    types2: any[];
    errorMessage: any;
    isLoginFailed: boolean;
    allConfigurationByrole: Array<any> = [];
    allTeamMember: any;
    tableData: Array<any> = [];
    dataSource = new MatTableDataSource<any>(this.tableData);
    dataSourceFilters = new MatTableDataSource(this.tableData);
    dataSourceWithPageSize = new MatTableDataSource(this.tableData);
    page: number;
    memberInfo: Array<any> = [];
    memberId: any;
    memberEmail: any;
    memberLabs: Array<any> = [];
    memberRole: any;
    memberRoleAssign: any;
    tablerole: any;
    memberCenterAssign: any;
    idTodel: any;
    can_add_team_member: any;
    can_edit_team_member: any;
    can_select_as_role: any;
    can_select_team: any;
    see_team_member: any;
    default_lab: any;
    memberCenter: any;
    deleteTeamMember: any;
    // centers: string;
    labsDataId: any;
    ismultiCenter: any;
    canselect_as_role: any;
    can_change_centre: any;
    constructor(
        private userService: StorageService, private fb: FormBuilder,
        private route: ActivatedRoute, private router: Router,
        private authService: AuthenticationService,
        public logService: LogsService,
        private paginationService: TeamService,
        // private changeDetectorRefs: ChangeDetectorRef,
        public dialog: MatDialog,
        private _formBuilder: FormBuilder,
        private configService: ConfigService,
        private globals: Globals,
        public snackBar: MatSnackBar,
        private cookieService: CookieService,
        // private paginationServiceV2: PaginationService,
        private apiService: ApiService,
        private roleConfig: Role,
        private ip: IpServiceService,
        // @Inject(DOCUMENT) private document: Document
    ) {
        super(configService, globals);
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // ------------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    @HostListener('window:scroll', ['$event'])
    onScroll(event) {
        // // console.log('You scrolled!');
    }


    pageSizes = [10, 15, 20];

    ngOnInit(): void {
        const lab_id = this.userService.getCookie("lab_id");
        if (lab_id) {
            this.page = 0;
            this.getROleconfiguration()
            this.getallRoleConfig();
            // console.log('>>>>>>>>> '+this.types)
            this.getAllData();
            this.getuserdata();
            // // console.log('>>>>>>>>>multiCenters = '+this.multiCenters)
            this.role = this.userService.getCookie('role');
            // // console.log("role", this.role)
            // this.centers = this.userService.getCookie('center');
            // // console.log("centers", this.centers)
            this.getDisplayedColumns();


            // if (this.role !== 'user') {
            this.registerForm = this._formBuilder.group({
                // name: ['', [Validators.required, Validators.required, Validators.pattern(/^\S+/), Validators.pattern(/^[A-Za-z ]+$/)], ''],
                email: ['', [Validators.required, Validators.email, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
                role: ['', [Validators.required], ''],
                center: ['', ''],
                // hasLimit: ['', Validators.required],
                // validTillDate: ['', ''],

            });

            // } else {

            this.registerFormAdmin = this._formBuilder.group({
                name: ['', [Validators.required, Validators.required, Validators.pattern(/^\S+/), Validators.pattern(/^[A-Za-z ]+$/)], ''],
                email: ['', [Validators.required, Validators.email, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
                // hasLimit: ['', Validators.required],
                // validTillDate: ['', ''],//////

            });
            // }
            this.registerFormEdit = this._formBuilder.group({
                name: ['', [Validators.required, Validators.required, Validators.pattern(/^\S+/), Validators.pattern(/^[A-Za-z ]+$/)], ''],
                email: [{
                    value: '',
                    disabled: true
                }, [Validators.required, Validators.email, [Validators.required, Validators.email, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]]],
                role: ['', Validators.required],
                // center: ['', ''],
                // hasLimit: ['', Validators.required],
                // validTillDate: [{value :''}, ],
            });
            this.staffForm = this._formBuilder.group({
                email: ['', [Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)], ''],
            });
            this.staticObjArr = this.daysArrayList;

        } else {
            this.logout();
        }
    }

    getROleconfiguration() {
        const loginUserRole = this.userService.getCookie('role');
        // // console.log("logdinuser role = ", loginUserRole)
        this.apiService.getAllRoleByName(loginUserRole).subscribe(
            a => {
                Object.entries(a).find(([key, value]) => {
                    if (key == 'data') {
                        this.allConfigurationByrole = value;
                        this.allConfigurationByrole.forEach(element => {
                            this.currentRoleData = element.access_specifier;
                            for (const [key, value] of Object.entries(this.currentRoleData)) {
                                this.can_add_team_member = this.currentRoleData.can_add_team_member;
                                this.deleteTeamMember = this.currentRoleData.can_delete_team_member;
                                this.can_edit_team_member = this.currentRoleData.can_edit_team_member;
                                this.can_select_as_role = this.currentRoleData.can_select_as_role;
                                this.see_team_member = this.currentRoleData.see_team_member;
                                this.can_change_centre = this.currentRoleData.can_change_centre;
                            }
                        });

                        // console.log('All Role config', this.can_add_team_member, this.can_edit_team_member, this.can_select_as_role);
                    }
                    else {

                    }
                })
            },

            err => {
                this.errorMessage = err.error.message;
                // console.log('unauthorised', err.status, this.errorMessage)
                if (err.status == 401) {
                    // // console.log("gggg"),
                    alert('Token is invalid or has expired Please relogin')
                    this.logout()
                }
            }
        );

    }

    getallRoleConfig() {
        this.apiService.getAllRole().subscribe(
            a => {
                Object.entries(a).find(([key, value]) => {
                    if (key == 'data') {
                        this.allConfigurationByrole = value;
                        this.allConfigurationByrole.forEach(element => {
                            this.allRoleData = element.access_specifier;
                            for (const [key, value] of Object.entries(this.allRoleData)) {
                                this.canselect_as_role = this.allRoleData.can_select_as_role;
                            }
                            if (this.canselect_as_role) {
                                this.types.push(element.name);
                            }
                        });
                        // console.log('All Role config', this.can_add_team_member, this.can_edit_team_member, this.can_select_as_role);
                    }
                    else {

                    }
                })
            },
            err => {
                this.errorMessage = err.error.message;
                // console.log('unauthorised', err.status, this.errorMessage)
                if (err.status == 401) {
                    // console.log("gggg"),
                    alert('Token is invalid or has expired Please relogin')
                    this.logout()
                }
            }
        );

    }


    logout(): void {
        localStorage.clear();
        this.userService.clearCookie();
        // this.globals.cassetteConfig = null;
        this.router.navigate(['/login']);
        // location.reload();

    }

    getAllData() {
        // urlLabName = this.userService.getCookie("lab");
        this.tableData = [];
        this.dataSource.data = [];
        const lab = this.userService.getCookie('lab_id')
        this.paginationService.getAllTeammeberByLabId(lab).subscribe(
            a => {
                //   // console.log(" AllTeammember ==", a) 
                Object.entries(a).find(([key, value]) => {
                    // // console.log(key,value)
                    if (key == 'data') {
                        this.allTeamMember = value;
                        // // console.log('all teammember',this.allTeamMember)
                        // this.allTeamMember.forEach(element => {
                        // // console.log(element)
                        this.allTeamMember.forEach(el => {
                            // // console.log(el.name,el.email,el._id,el.labs)
                            this.tablerole = '';
                            el.labs.forEach(rolebylab => {
                                // // console.log('valuecheck',rolebylab.lab_id,rolebylab.role,lab)
                                if (rolebylab.lab_id == lab) {
                                    // // console.log(value);
                                    this.tablerole = ''
                                    this.tablerole = rolebylab.role;

                                }

                            });
                            // Object.entries(el.labs[0]).find(([key, value]) => {
                            this.tableData.push({ name: el.name, email: el.email, role: this.tablerole, edit: el.email, delete: el._id })
                            this.hasEditDataShown = true;
                            this.loadingSpinner = true;
                            // })

                        });

                        // });

                    }

                    else {
                        // // console.log(key,'=',value);
                    }
                })
                // // console.log('table data=',this.tableData)

                this.dataSource.data = this.tableData;
                //  // console.log("datasource=",this.dataSource.data)

            },

            err => {
                this.errorMessage = err.error.message;
                // console.log('unauthorised', err.status, this.errorMessage)
                if (err.status == 401) {
                    // // console.log("gggg"),
                    alert('Token is invalid or has expired Please relogin')
                    this.logout()
                }
            }
        );
    }

    async getuserdata(): Promise<void> {
        // // console.log('>>>>>>>> calling getuserdata ')
        this.apiService.getLabs().subscribe(
            a => {
                // // console.log('>>>>>>>> calling a ' + a)
                Object.entries(a).find(([key, value]) => {
                    if (key == 'data') {
                        this.labsDataId = value;
                        this.labsDataId.forEach(element => {
                            // console.log('center=', element.center)
                            // this.centers = element.center;
                            this.multiCenters = element.centers;
                            this.ismultiCenter = element.multi_center;
                        });

                    }
                    else {
                        // // console.log(key,'=',value);
                    }
                })
            },

            err => {
                this.errorMessage = err.error.message;
                // console.log('unauthorised', err.status, this.errorMessage)
                if (err.status == 401) {
                    // console.log("gggg"),
                    alert('Token is invalid or has expired Please relogin')
                    this.logout()
                }

            }

        );
    }

    getDisplayedColumns(): string[] {
        return this.displayedColumns;
        // // console.log("currentrole=",this.currentRoleData)
        //         if (this.currentRoleData) {

        //             const isAdmin = this.currentRoleData.hideAddTeamMember;
        //             // // console.log('hideteam',isAdmin)
        //             if (!isAdmin) {

        //                 return this.displayedColumns;
        //             } else {

        //                 // return this.displayedColumnsNoDelete;
        //             }

        //         }
    }

    enableInOffice(docId, inOffice) {
        let combo = '';
        if (inOffice === undefined) {
            return combo = docId;
        }

        return combo;
    }







    onCancelFrm() {
        this.registerForm.reset();
        // this.registerFormAdmin.reset();
    }
    onEditCancelFrm() {
        this.registerFormEdit.reset();
        // this.registerFormAdmin.reset();
        this.daysArrayList = this.staticObjArr;
    }

    addTeamMember() {
        this.memberRoleAssign = '';
        this.memberCenterAssign = '';
        this.memberRoleAssign = this.registerForm.value.role;
        this.memberCenterAssign = this.registerForm.value.center;
        // console.log('memberCenterAssign = ' + this.memberCenterAssign)
        this.memberEmail = this.registerForm.value.email.trim().toLowerCase()
        //  // console.log("ema=",this.memberEmail, this.memberRoleAssign)
        if (this.memberCenterAssign == null) {
            this.memberCenterAssign = '';
        }
        const token = this.userService.getCookie('token');
        // const lab = this.userService.getCookie('lab_id')

        this.apiService.getAllLabID(token, this.memberEmail).subscribe(
            // this.paginationService.getAllTeammeberByLabId(lab).subscribe( //try new api

            a => {
                Object.entries(a).find(([key, value]) => {
                    if (key == 'data') {

                        this.memberInfo = value;
                        this.memberInfo.forEach(element => {
                            // console.log(element._id)
                            const teamdata = {
                                email: this.memberEmail,
                                update_data: {
                                    labs: [{ lab_id: this.userService.getCookie('lab_id'), role: this.memberRoleAssign, default_lab: true, center: this.memberCenterAssign }],
                                    updated: ((-1) * new Date().getTime()).toString(),
                                    enabled: true,

                                }
                            }

                            this.paginationService.updateTeammeberData(teamdata).subscribe(data => {
                                // // console.log("update data =",data)
                                Object.entries(data).find(([key, value]) => {
                                    if (key == 'status' && (value == 'success' || value == 'Success')) {
                                        const msg = 'Member Added Successfully';
                                        this.openSnackBar(msg, 'OK');
                                        this.loadingSpinner = false;
                                        this.dataSource.data = [];
                                        this.getAllData();

                                    } else if (key == 'status' && value == 'failed') {

                                        // // console.log('ccfggf',value);
                                        const msg = 'Data Not updated , Somthing went wrong';
                                        this.openSnackBar(msg, 'OK');
                                    }

                                })
                            })
                        });
                    }
                    else if (key == 'status' && value == 'failed') {
                        // // console.log('ccfggf',value);
                        const msg = 'Email is not exist, Please contact your lab admin';
                        this.openSnackBar(msg, 'OK');
                    }
                })
            },

            err => {

                this.errorMessage = err.error.message;
                // console.log('unauthorised', err.status, this.errorMessage)
                if (err.status == 401) {
                    // console.log("gggg"),
                    alert('Token is invalid or has expired Please relogin')
                    this.logout()
                }

            });

        this.registerForm.reset();


    }

    getTrimAndLowerCaseEmail(email) {
        return email.trim().toLowerCase();
    }


    ngAfterViewInit(): void {

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSourceWithPageSize.paginator = this.paginatorPageSize;
        this.getROleconfiguration();
    }

    getOrderByColumn(): string {
        return 'name';
    }

    getTableName(): string {
        return 'team';
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }

    private handleError(error: Error): void {
        this.openSnackBar(error.message, 'OK');
        // this.notify.update(error.message, 'error');
    }

    openDialogWithRef(ref: TemplateRef<any>) {
        this.datePick = false;
        this.dialog.open(ref, { width: '320px', disableClose: true });
    }



    openSuccessfulDialog() {
        const dialogRef = this.dialog.open(SuccessfulComponent,
            {
                disableClose: true,
                width: '350px',
                height: '250px',
                // data: {firstName: this.firstName, lastName: this.lastName, pid: this.urlPatientId}
            });
    }

    openDialogWithRefedit(ref: TemplateRef<any>, email) {
        // console.log("upadteemail=", email)
        this.hasEditDataShown = false;
        const token = this.userService.getCookie('token');
        const lab_id = this.userService.getCookie('lab_id');

        this.apiService.getAllLabID(token, email).subscribe(

            a => {

                Object.entries(a).find(([key, value]) => {
                    if (key == 'data') {
                        this.memberInfo = value;
                        // console.log(this.memberInfo)
                        this.memberInfo.forEach(element => {
                            // console.log(element._id)
                            this.memberId = element._id;
                            this.memberLabs = element.labs
                            this.memberLabs.forEach(lab => {

                                // console.log('labrole=', lab.role);
                                // console.log('default_lab=', lab.default_lab);
                                if (lab_id == lab.lab_id) {
                                    this.memberRole = lab.role;
                                    this.memberCenter = lab.center;
                                    this.default_lab = lab.default_lab
                                }

                            });

                            this.registerFormEdit.setValue({
                                // @ts-ignore
                                email: element.email,
                                // @ts-ignore
                                name: element.name,
                                // @ts-ignore
                                role: this.memberRole,
                                // center:this.memberCenter,
                                // validTillDate: this.validD,
                            });
                            this.hasEditDataShown = true;

                        });
                    }
                    else {
                        // console.log(key, '=', value);
                    }
                })
            },

            err => {

                this.errorMessage = err.error.message;
                // console.log('unauthorised', err.status, this.errorMessage)
                if (err.status == 401) {
                    // console.log("gggg"),
                    alert('Token is invalid or has expired Please relogin')
                    this.logout()
                }

                this.isLoginFailed = true;

            });
        this.dialog.open(ref, { width: '380px', disableClose: true });
    }

    editTeamMember(email) {
        // console.log("role edit=", this.registerFormEdit.value.name, this.registerFormEdit.value.role)
        const token = this.userService.getCookie('token');
        // console.log('token=', token, 'email=', email);
        // var defaultLab = this.userService.getCookie('default_lab');
        const lab_id = this.userService.getCookie('lab_id');
        // // console.log('default_lab = ' + defaultLab)
        // console.log('this.default_lab = ' + this.default_lab)

        if (this.default_lab == null || this.default_lab == undefined || this.default_lab == '') {
            this.default_lab = 'false';
        }
        this.apiService.getAllLabID(token, email).subscribe(

            a => {

                Object.entries(a).find(([key, value]) => {
                    if (key == 'data') {
                        this.memberInfo = value;
                        // console.log(this.memberInfo)
                        this.memberInfo.forEach(element => {
                            // console.log(element._id)
                            this.memberId = element._id;

                            const teamdata = {
                                email: email,
                                update_data: {
                                    name: this.registerFormEdit.value.name.trim(),
                                    email: email,
                                    labs: [{ lab_id: lab_id, role: this.registerFormEdit.value.role.trim(), default_lab: this.default_lab, center: this.memberCenter }],
                                    updated: ((-1) * new Date().getTime()).toString(),
                                    enabled: true,

                                }
                            }



                            this.paginationService.updateTeammeberData(teamdata).subscribe(data => {
                                // console.log("update single data =", data)
                                Object.entries(data).find(([key, value]) => {
                                    if (key == 'status' && (value == 'success' || value == 'Success')) {
                                        const msg = 'Member Updated Successfully';
                                        this.openSnackBar(msg, 'OK');
                                        this.hasEditDataShown = false;
                                        this.loadingSpinner = false;
                                        this.registerFormEdit.reset();
                                        // window.location.reload();
                                        this.getAllData();

                                    }
                                    else {

                                    }
                                })

                            })
                        });
                    }
                    else {
                        //    // console.log(key,'=',value);
                    }
                })
            },

            err => {

                this.errorMessage = err.error.message;
                // console.log('unauthorised', err.status, this.errorMessage)
                if (err.status == 401) {
                    // console.log("gggg"),
                    alert('Token is invalid or has expired Please relogin')
                    this.logout()
                }

                //   this.isLoginFailed = true;

            });

    }

    openDialogWithRefDel(ref: TemplateRef<any>, id, email): void {
        // // console.log("delete member Id=", id)
        if (email === this.userService.getCookie('email')) {
            window.alert('You can not delete logged in user.');
            return;
        } else {
            this.emailToDel = email;
            // this.idTodel = id;
            // console.log("delId=", this.emailToDel)
            this.dialog.open(ref, { width: '400px', disableClose: true });
        }

    }

    deleteTeamMemberId(emailId) {
        // console.log("Email to del", emailId)
        this.paginationService.deleteTeammeberFromLab(emailId, this.userService.getCookie('lab_id')).subscribe(data => {
            // console.log("delete single data =", data)
            Object.entries(data).find(([key, value]) => {
                if (key == 'status' && (value == 'success' || value == 'Success')) {
                    const msg = 'Member Deleted Successfully';
                    this.openSnackBar(msg, 'OK');
                    this.hasEditDataShown = false;
                    this.loadingSpinner = false;
                    this.registerForm.reset();
                    // window.location.reload();
                    this.getAllData();

                }
                else {

                }
            })

        })



    }



    /**
     * On destroy
     */
    ngOnDestroy(): void {

    }


}
