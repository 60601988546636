import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  HostListener,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation, Inject
} from '@angular/core';

import { MatSort } from '@angular/material/sort';
import { Observable, Subject, Subscription } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { MatTableDataSource } from '@angular/material/table';
import { StorageService } from '../../common/service/storage.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Resolve } from '@angular/router';
import { LogsService } from './logs.service';
import { Logs } from './logs';
import { MatDialog } from '@angular/material/dialog';
import { BaseComponent } from '../../base/base.component';
import { ConfigService } from '../../common/config/config.service';
import { Globals } from '../../common/global';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TextUtils } from '../../../util/TextUtils';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationService } from '../../common/service/authentication.service';
import { ApiService } from '../../common/service/api.service';
import { SuccessfulComponent } from '../patient/successful/successful.component';
// import { PaginationService } from '../../common/pagination/pagination.service';
import { noop as _noop } from 'lodash-es';
import { Role } from '../../common/role';
import { MatTable } from '@angular/material/table';
const timeZone = require('moment-timezone');

@Component({
  selector: 'team-table',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss'],
  animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
// export class LogsComponent implements OnInit {
  export class LogsComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    dataSource: MatTableDataSource<Element>;
    // displayedColumns: string[] = [ 'avatar', 'name', 'position', 'office', 'email', 'phone'];

     // displayedColumns: string[] = ['eventName', 'eventDescription', 'user', 'ipAddress', 'date'];
    displayedColumns: string[] = ['eventName', 'eventDescription', 'user', 'date'];

    

    // displayedColumnsNoDelete: string[] = ['Sr', 'name', 'role', 'email',];


    registerForm: FormGroup;
    registerFormAdmin: FormGroup;
    registerFormEdit: FormGroup;
    staffForm: FormGroup;

    // @ViewChild(MatPaginator, {static: true})
    // paginator: MatPaginator;

    @ViewChild('filter', { static: true })
    filter: ElementRef;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    @ViewChild('table') table: MatTable<any>; /*not exatly sure what the type should be set too */
    page = 0;
    role = this.userService.getCookie('role');
    lab = this.userService.getCookie('lab');
    types: Array<any> = [];
    currentRoleData: any;
    beforeEdit: any;
    datePick = false;
    tStamp: any;
    message = '';
    msgColor = '';
    color = ['green', 'red', 'blue'];
    isCheckedVal = false;
    validD: any;
    length: any;
    @Input()
    min: 'D' | null;
    today = new Date();
    emailToDel = '';
    perPageData = this.globals.perPageData;
    staffEmail = '';
    staffName = '';
    loggedInEmail = this.userService.getCookie('email');
    // Private
    spinner = false;
    hasFirstDataShown = false;
    private _unsubscribeAll: Subject<any>;
    itemsObservable: Observable<Logs[]>;
    limit = 1000;
    full = false;
    isSticky = true;
    roleData: any;
    enableInfoMsg = false;
    dataN: Array<any> = [];

    constructor(
        private userService: StorageService, private fb: FormBuilder,
        private route: ActivatedRoute, private router: Router,
        private authService: AuthenticationService,
        private paginationService: LogsService,
        private changeDetectorRefs: ChangeDetectorRef,
        public dialog: MatDialog,
        private _formBuilder: FormBuilder,
        private configService: ConfigService,
        private globals: Globals,
        public snackBar: MatSnackBar,
        private cookieService: CookieService,
        private apiService: ApiService,
        private roleConfig: Role,
        // @Inject(DOCUMENT) private document: Document
    ) 
    {
        super(configService, globals);
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    @HostListener('window:scroll', ['$event'])
    onScroll(event) {
        // console.log('You scrolled!');
    }


    ngOnInit(): void 
    {

        // this.roleData = this.route.snapshot.data.logsResolver as Logs;

        // if(this.roleData.data)
        // if (!this.roleConfig.authRole.includes(this.roleData.data.role)) {
        //     this.router.navigate(['/home']);
        // }

        // this.paginationService.getAllRole().then(allRole => {
        //     allRole.map(roleData => {
        //         // providing only 2 roles to add in dropdown
        //         if (roleData.canSelectAsRole) {
        //             // if(roleData.id === 'admin' || roleData.id === 'user'){
        //             const id = roleData.id;
        //             const value = roleData.value;
        //             this.types.push({ id, value });
        //         }

        //     });


        // }).catch(error => { // dp something
        // });
        // this.role = this.userService.getCookie('role');

        // this.getDisplayedColumns();


        // if (this.role === 'admin') {
        //     this.registerForm = this._formBuilder.group({
        //         name: ['', [Validators.required, Validators.required, Validators.pattern(/^\S+/), Validators.pattern(/^[A-Za-z ]+$/)], ''],
        //         email: ['', [Validators.required, Validators.email, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
        //         role: ['', [Validators.required], ''],
        //         // hasLimit: ['', Validators.required],
        //         // validTillDate: ['', ''],

        //     });

        // } else {

        //     this.registerFormAdmin = this._formBuilder.group({
        //         name: ['', [Validators.required, Validators.required, Validators.pattern(/^\S+/), Validators.pattern(/^[A-Za-z ]+$/)], ''],
        //         email: ['', [Validators.required, Validators.email, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
        //         // hasLimit: ['', Validators.required],
        //         // validTillDate: ['', ''],

        //     });
        // }
        // this.registerFormEdit = this._formBuilder.group({
        //     name: ['', [Validators.required, Validators.required, Validators.pattern(/^\S+/), Validators.pattern(/^[A-Za-z ]+$/)], ''],
        //     email: [{
        //         value: '',
        //         disabled: true
        //     }, [Validators.required, Validators.email, [Validators.required, Validators.email, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]]],
        //     role: ['', Validators.required],
        //     // hasLimit: ['', Validators.required],
        //     // validTillDate: [{value :''}, ],
        // });


        // this.staffForm = this._formBuilder.group({
        //     email: ['', [Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)], ''],
        // });

    }
    ngAfterViewChecked() {
        // your code to update the model
        this.changeDetectorRefs.detectChanges();
    }

    // conditional Column to be shown
    getDisplayedColumns(): string[] {
        if (this.currentRoleData) {
            const isAdmin = this.currentRoleData.canAddTeamMember;
            if (isAdmin) {
                // this.hasFirstDataShown = true;
                return this.displayedColumns;
            } else {
                // this.hasFirstDataShown = true;

                // return this.displayedColumnsNoDelete;
                return this.displayedColumns;
            }

        }
    }


    onCancelFrm() {
        this.registerForm.reset();
        // this.registerFormAdmin.reset();
    }

    // addTeamMember() {

    //     this.paginationService.checkTeammember(this.paginationService.getCollectionPath(), this.getTrimAndLowerCaseEmail(this.registerForm.value.email)).subscribe(isAvailable => {
    //         if (isAvailable.exists) {
    //             this.registerForm.reset();
    //             const msg = 'Email Already Exist!';
    //             this.openSnackBar(msg, 'OK');
    //         } else {

    //             const teamData =
    //             {
    //                 name: this.registerForm.value.name.trim(),
    //                 lab: this.lab.trim(),
    //                 email: this.getTrimAndLowerCaseEmail(this.registerForm.value.email),
    //                 role: this.registerForm.value.role,
    //                 sortBy: TextUtils.toUpperCase(this.registerForm.value.name),
    //             };
    //             // if (this.role === 'admin') {
    //             //     teamData.role = 'user';
    //             // }
    //             // const expiryData = {hasLimit:this.datePick,validTillDate:this.tStamp};
    //             // console.log('teamData', teamData);
    //             const expiryData = {};
    //             const returnData = this.paginationService.addTeamMember(teamData, expiryData);
    //             returnData.then((data: any) => {
    //                 const msg = 'Member Added Successfully';
    //                 this.openSnackBar(msg, 'OK');
    //                 this.initData();
    //             }).catch((error) => {
    //                 this.handleError(error);
    //             });
    //             this.registerForm.reset();
    //         }
    //     });

    // }

    getTrimAndLowerCaseEmail(email) {
        return email.trim().toLowerCase();
    }

    subscription: Subscription;
    initData() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        // this.paginationServiceV2.reset();
        // this.paginationServiceV2.init(this.paginationService.getCollectionPath(),'date', { reverse: true, prepend: false },true)

        // this.subscription = this.paginationServiceV2.data.subscribe(data => {
        //     this.dataN = data;
        //     // console.log("data:",this.dataN);
        //     this.dataN.forEach(value => value.date =  timeZone(Math.abs(value.date)).tz(this.userService.getCookie('defaultTz')).format('DD-MM-YYYY HH:mm')
        // )
        //     this.dataSource = new MatTableDataSource(this.dataN);
        //     this.dataSource.data = this.dataN;
        //     if(!this.dataN || data.length === 0){
        //         this.enableInfoMsg = false;
        //         this.hasFirstDataShown = false;
        //         setTimeout(()=> {
        //             this.enableInfoMsg = true;
        //             this.hasFirstDataShown = true;
        //         },3000)
        //     }
        // });
        // this.page = 0;
    }

    ngAfterViewInit(): void {
        // this.initData();
        // this.authService.getRoleById(this.role).then(roleData => {
        //     this.currentRoleData = roleData;
        // });
    }


    getOrderByColumn(): string {
        return 'name';
    }

    getTableName(): string {
        return 'team';
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }

    private handleError(error: Error): void {
        this.openSnackBar(error.message, 'OK');
        // this.notify.update(error.message, 'error');
    }

    openDialogWithRef(ref: TemplateRef<any>) {
        this.datePick = false;
        this.dialog.open(ref, { width: '320px', disableClose: true });
    }

    // shareAssessmentLink(staffEmail: string): void {
    //     this.paginationService.shareAssessmentLink(staffEmail).subscribe(dataResult => {
    //         this.openSuccessfulDialog();
    //     });
    // }

    openSuccessfulDialog() {
        const dialogRef = this.dialog.open(SuccessfulComponent,
            {
                disableClose: true,
                width: '350px',
                height: '250px',
                // data: {firstName: this.firstName, lastName: this.lastName, pid: this.urlPatientId}
            });
    }

    // openDialogWithRefedit(ref: TemplateRef<any>, email) {
    //     this.paginationService.getMemberById(email).then((data: any) => {
    //         this.beforeEdit = data;

    //         this.registerFormEdit.setValue({
    //             // @ts-ignore
    //             email: data.email,
    //             // @ts-ignore
    //             name: data.name,
    //             // @ts-ignore
    //             role: data.role,
    //             // validTillDate: this.validD,

    //         });

    //     }).catch(error => {
    //         this.handleError(error);

    //     });

    //     this.dialog.open(ref, { width: '320px', disableClose: true });
    // }

    editTeamMember(email) {

        const teamData =
        {
            name: this.registerFormEdit.value.name.trim(),
            lab: this.lab.trim(),
            email: email,
            role: this.registerFormEdit.value.role,
            sortBy: TextUtils.toUpperCase(this.registerFormEdit.value.name),
            labsObject: [] // recreating labsobject after edit
        };


        // recreating labs object Data
        const labsData: any = {
            labId: this.beforeEdit.labsObject[0].labId,
            labName: this.beforeEdit.labsObject[0].labName,
            role: this.registerFormEdit.value.role,

        };
        teamData.labsObject.push(labsData);
        // const returnData = this.paginationService.updateTeamMember(teamData);
        // returnData.then(data => {
        //     this.paginationService.updateUserWhenTeamUpdated(teamData).then(data => {

        //         const msg = 'Member Updated Successfully';
        //         this.openSnackBar(msg, 'OK');
        //         window.location.reload();
        //     });


        // }).catch((error) => {
        //     this.handleError(error);
        // });
        // this.registerForm.reset();

    }

    openDialogWithRefDel(ref: TemplateRef<any>, email): void {
        if (email === this.userService.getCookie('email')) {
            window.alert('You can not delete logged in user.');
            return;
        } else {
            this.emailToDel = email;
            this.dialog.open(ref, { width: '400px' });
        }


        // this.router.navigate(['/viewtool']);
    }

    deleteTeamMember(docId) {

        // this.paginationService.deleteDocumentById(docId).then(resultMsg => {
        //     this.openSnackBar(resultMsg, 'OK');
        //     this.initData();

        // });
    }


    // openDialogWithRefStaff(ref: TemplateRef<any>, obj) {

    //     this.paginationService.getCovidConfig().then(data => {
    //         if (data.enableStaffEmail === 'Yes' && data.staffMailDaysArray[0].days[new Date().getDay()].checked === true) {
    //             this.staffEmail = obj.email;
    //             this.dialog.open(ref, { width: '360px', disableClose: true });
    //         } else {
    //             alert('Sending mail option is disabled, check staff email configuration');
    //         }

    //     });



    // }

    // sendStaffMail() {

    //     // get covid staff config

    //     const sentData = { email: this.staffEmail, labId: this.lab };
    //     this.apiService.sendMailStaffFromTeamModule(sentData).subscribe(result => {
    //         // console.log(result);
    //         //     need to test and add toast msg
    //         this.openSuccessfulDialog();

    //     });

    // }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.page = 0;
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    handleScroll = (scrolled:boolean) => {
        // console.timeEnd('lastScrolled');
        scrolled ? this.getData() : _noop();
        // console.time('lastScrolled');
    }
    hasMore = () => {
      return  !this.dataSource || this.dataSource.data.length < this.limit;

    }
    getData() {
       // this.paginationServiceV2.more();

        // this.dataSource.sort = this.sort;
    }


}
