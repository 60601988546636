import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject, throwError } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthenticationService } from '../../common/service/authentication.service';
import { StorageService } from '../../common/service/storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
    selector: 'register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class RegisterComponent implements OnInit, OnDestroy {
    isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = '';
    registerForm: FormGroup;
    message = '';
    msgColor = '';
    color = ['green', 'red', 'blue'];
    spinner = false;
    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        public authService: AuthenticationService,
        public snackBar: MatSnackBar,
        public userService:StorageService,
        public router: Router,
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // ngOnDestroy(): void {
    //     throw new Error('Method not implemented.');
    // }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.registerForm = this._formBuilder.group({
            name: ['', [Validators.required, Validators.pattern(/^\S+/)], ''],
            email: ['', [Validators.required, Validators.email, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
            password: ['', [Validators.required, Validators.pattern(/^\S*$/)]],
            passwordConfirm: ['', [Validators.required, Validators.pattern(/^\S*$/), confirmPasswordValidator]],
            // phone           : ['', Validators.required],

        });

        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
        this.registerForm.get('password').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.registerForm.get('passwordConfirm').updateValueAndValidity();
            });
    }

    register() {
        // console.log(this.registerForm.value);
        this.spinner = true;
        this.authService.register(this.registerForm.value).subscribe(
            data => {
              // console.log('message =',data,data[0]);
              const msg = 'User has been registerd successfully '
              this.openSnackBar(msg,'OK');
              this.spinner=false;
              this.isSuccessful = true;
              this.isSignUpFailed = false;
            },
            err => {
                this.errorMessage = err.error.message;
                // console.log('unauthorised',err.status,this.errorMessage,this.errorMessage)
                if(err.status == 401){
                    this.spinner=false;
                    this.isSuccessful = true;
                    this.isSignUpFailed = false;
                  // console.log("gggg"),
                  alert('Token is invalid or has expired Please relogin')
                  this.logout()
                }
                if(err.status == 400){
                    this.spinner=false;
                    this.isSuccessful = true;
                    this.isSignUpFailed = false;
                    // console.log("gggg"),
                    alert('User was already registerd')
                    this.logout()
                  }
              this.isSignUpFailed = true;
            }
          );
        
        // const data = Object.assign({}, this.registerForm.value);
        // const returnData = this.authService.signUp(this.registerForm.value.email, this.registerForm.value.password);
        // returnData.then(registerDta => {
        //     this.spinner = false;
        // }).catch((error) => {
        //     this.handleError(error);
        //     this.spinner = false;
        // });
    }

      logout(): void {
        localStorage.clear();
        this.userService.clearCookie();
        // this.globals.cassetteConfig = null;
        this.router.navigate(['/login']);
        // location.reload();

    }
    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }

    private handleError(error: Error): void {
        this.openSnackBar(error.message, 'OK');
        // this.notify.update(error.message, 'error');
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if (!control.parent || !control) {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if (!password || !passwordConfirm) {
        return null;
    }

    if (passwordConfirm.value === '') {
        return null;
    }

    if (password.value === passwordConfirm.value) {
        return null;
    }

    return { passwordsNotMatching: true };
};
