import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { fuseAnimations } from '../../../../../../@fuse/animations';
import { StorageService } from '../../../common/service/storage.service';
import { CassetteListService } from '../list/cassette-list.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../../../common/global';
import { LabService } from '../../lab/lab.service';
import { ApiService } from 'app/main/module/common/service/api.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import * as moment from "moment-timezone";
import { Settemplate } from '../settemplate';
import { Load } from '../../load/load';
import { MatSort } from '@angular/material/sort';
import { E } from '@angular/cdk/keycodes';
const timeZone = require('moment-timezone');
@Component({
  selector: 'app-viewpreparesets',
  templateUrl: './viewpreparesets.component.html',
  styleUrls: ['./viewpreparesets.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class ViewpreparesetsComponent implements OnInit {


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  getStatus: any;
  getError: any;
  perPageData = this.globals.perPageData;
  isInitComplete = true;
  columns = this.getColumn();
  titleMatIcon = this.getTitleMatIcon();
  title = this.getTitle();
  displayedColumns = this.columns.map(c => c.columnDef);
  dataSource = new MatTableDataSource<any>();

  // displayedColumns: string[] = ['sr','barcode','instruments', 'departments', 'categories', 'subcategories','created_by','created']

  //   dataSource:MatTableDataSource<any>= new MatTableDataSource<any>();
  tableList: any = [];
  page = 0;
  isLoading: boolean;
  prev: number;
  next: number;
  count: number;
  totalPage: any;
  allData: Array<any>;
  viewsetseData: Settemplate[] = []
  errorMessage: any;
  isNextPressed: boolean;


  constructor(public labService: LabService, public apiservice: ApiService,
    private formBuilder: FormBuilder, public userService: StorageService, private castService: CassetteListService,
    private router: Router, public globals: Globals) {

  }

  ngOnInit(): void {
    const lab_id = this.userService.getCookie("lab_id");
    if (lab_id) {
      this.viewsetseData = [];
      this.dataSource.data = [];
      this.isLoading = true;
      this.prev = 0;
      this.next = 1;
      this.page = 0;
      this.count = 1;
      // this.getAllData(1);
      this.getSetsDocuments(1);
    } else {
      this.logout();
    }

  }

  getDateStringMomentByTz(timestamp): string {
    if (timestamp != undefined) {
      if (timestamp) {
        if (timestamp < 0)
          timestamp = timestamp * -1;
        const localTimeZone = moment(timestamp).tz(this.userService.getCookie('defaultTz'));
        return localTimeZone.format('DD-MM-YYYY | HH:mm');
      }
    }
    else {
      return '';
    }
  }
  getSetsDocuments(page) {
    this.isInitComplete = true;
    this.castService.getAllCassetteData(page, false).subscribe(
      a => {

        this.isInitComplete = false
        console.log(" All Cassette Data ==", a)
        Object.entries(a).find(([key, value]) => {
          if (key == 'total_pages') {
            this.totalPage = value;
          }
          if (key == 'data') {
            this.allData = value;
            //  console.log(this.allData)
            this.allData.forEach(element => {
              //  console.log(element.load_id)  
              this.isLoading = false;
              if (element.attached == false) {
                console.log("element in table ", element.barcode, element.instruments.length, element.departments, element.subcategories, element.categories, element.created_by.timestamp)
                //  this.changeDetectorRefs.markForCheck()
                //  this.changeDetectorRefs.detectChanges()
                if (element.created_by == undefined || element.timestamp == undefined) {
                  console.log(element.credit_by)
                  element.created_by.timestamp = '-'
                }
                this.viewsetseData.push({
                  quantity: element.quantity,
                  instruments: element.instruments,
                  code: '',
                  departments: element.departments,
                  categories: element.categories,
                  subcategories: element.subcategories,
                  barcode: element.barcode,
                  created_by: element.created_by.name,
                  created: element.created_by.timestamp,
                  updated: '',
                  checkbox: ''
                });
              }
            });

          }

          else {
            // console.log(key,'=',value);
          }

        })
        //  console.log('table data=',this.loadData)

        this.dataSource.data = this.viewsetseData;
        //  console.log("datasource=",this.dataSource.data)

      },

      err => {
        this.errorMessage = err.error.message;
        console.log('unauthorised', err.status, this.errorMessage)
        if (err.status == 401) {
          console.log("gggg"),
            alert('Token is invalid or has expired Please relogin')
          this.logout()
        }

      }
    );
  }

  logout(): void {
    localStorage.clear();
    this.userService.clearCookie();
    // this.globals.cassetteConfig = null;
    this.router.navigate(['/login']);
    // location.reload();

  }

  onNextPress() {
    this.isLoading = true;
    this.isNextPressed = true;
    this.count = this.count + 1;
    this.next = this.prev + 1;
    this.prev = this.next - 1
    this.next++
    this.prev++
    this.getSetsDocuments(this.next)
    console.log("next=", this.next, this.prev);
  }

  getTitleMatIcon(): string {
    return 'pageview';
  }

  getTitle(): string {
    return 'View Prepared Sets';
  }

  getConvertDateTime(timeStamp) {
    var time = moment(timeStamp).format("DD-MM-YYYY h:mm:ss");
    return time
  }

  getColumn(): any[] {
    return [
      {
        columnDef: 'sr',
        header: 'Sr.',
        cell: (element: Settemplate, index: number) => `${index + 1}`,

      },
      {
        columnDef: 'Setbarcode',
        header: 'Barcode',
        cell: (element: Settemplate, index: number) => `${element.barcode}`
      },

      {
        columnDef: 'instruments',
        header: 'No. of Instruments',
        cell: (element: Settemplate, index: number) => `${(element.instruments).length}`
      },
      {
        columnDef: 'Department',
        header: 'Department',
        cell: (element: Settemplate, index: number) => `${element.departments ? element.departments : '-'}`,
      },
      {
        columnDef: 'ViewSetCategory',
        header: 'Category',
        cell: (element: Settemplate, index: number) => `${element.categories ? element.categories : '-'}`,
      },
      {
        columnDef: 'subcategories',
        header: 'Sub-Category',
        cell: (element: Settemplate, index: number) => `${element.subcategories ? element.subcategories : '-'}`,
      },



      {
        columnDef: 'Prepared By',
        header: 'Prepared By',
        cell: (element: Settemplate, index: number) => `${element.created_by ? element.created_by : '-'}`,

      },
      {
        columnDef: 'PreparedOn',
        header: 'Prepared On',
        cell: (element: Settemplate, index: number) => `${this.updateDate(element.created) ? this.updateDate(element.created) : '-'}`,

      },

    ];
  }


  updateDate(value): any {
    // value = parseInt(value)
    // console.log(value) 
    const dateTime = timeZone(Math.abs(value)).tz(this.userService.getCookie('defaultTz')).format('DD-MM-YYYY | HH:mm');
    return dateTime

  }
  viewSets(barcode) {
    console.log(barcode);
    sessionStorage.setItem('viewSetsBarcode', barcode);
    this.router.navigate(['/setsdetail'], { queryParams: { barcode: barcode } });

  }

}
