import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { MatSort } from "@angular/material/sort";
import "rxjs/add/operator/toPromise";
import { fuseAnimations } from "@fuse/animations";
import { StorageService } from "../../../common/service/storage.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Patient } from "../patient";
import { MatDialog } from "@angular/material/dialog";
import { AddPatientDialogComponent } from "../addpatient/add-patient-dialog.component";
import { EditPatientDialogComponent } from "../editpatient/edit-patient-dialog.component";
import { Load } from "../../load/load";
import { ShareAssessmentDialogComponent } from "../shareAssessmentLink/share-assessment-dialog.component";
import { Globals } from "../../../common/global";
import { TeamService } from "../../team/team.service";
import { SuccessfulComponent } from "../successful/successful.component";
import * as moment from "moment";
import { BaseListComponent } from "../../../base/base-list.component";
import { ConfigService } from "../../../common/config/config.service";
import { PatientListService } from "./patient-list.service";
import { ApiService } from "../../../common/service/api.service";
const timeZone = require("moment-timezone");
import { Subscription } from "rxjs";
import { PatientInfiniteScroll } from "./patient-infinite-scroll.service";
import { MatTableDataSource } from "@angular/material/table";
import { Style } from "util";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { PatientService } from "../patient.service";
import { MatPaginator } from "@angular/material/paginator";

@Component({
    selector: "patient-table",
    templateUrl: "patient.component.html",
    styleUrls: ["patient.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class PatientComponent implements OnInit, OnDestroy, AfterViewInit {
    patient: string;
    navigationType = this.route.snapshot.data.navType;
    // checkHide = this.getSubscriptionType();
    columns = this.getColumn();
    // columnsConfig = this.getColumnConfig();
    titleMatIcon = this.getTitleMatIcon();
    title = this.getTitle();
    displayedColumns = this.columns
        .filter((c) => c.hide === false)
        .map((c) => c.columnDef);
    // displayedColumnsConfig = this.columnsConfig
    //     .filter((c) => c.hide === false)
    //     .map((c) => c.columnDef);

    @ViewChild("filter", { static: true })
    filter: ElementRef;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;
    isLoading = true;
    alertSign = "";
    enableInfoMsg = false;
    protected extraCta = {
        visibility: false,
        text: "",
    };

    points = [];
    signatureImage;
    staffDropDown: any;
    staffForm: FormGroup;
    staffName = "";

    perPageData = this.global.perPageData;

    token: number;
    queryParam = {};

    isInitComplete = false;
    subscription: Subscription;
    dataN: Array<any> = [];

    tableData: Patient[] = []
    dataSource = new MatTableDataSource<any>(this.tableData);
    dataSourceFilters = new MatTableDataSource(this.tableData);
    dataSourceWithPageSize = new MatTableDataSource(this.tableData);
    isNextPressed = false;
    todayDate: Date = new Date();
    static oldData: Array<any> = [];
    static cursor: any;
    isMoreDataAvailable = true;
    pmsIntegration: boolean;
    loadingSpinner: boolean = true;
    labConfig: any;
    dateTime: any;
    getCountrycode: any = 0;
    page: number;
    searchTxt: any;
    searchText: any;
    patinetData: Array<any>;
    hasEditDataShown: boolean;
    errorMessage: any;
    isLoginFailed: boolean;
    idTodel: any;
    // countryCode='+91';

    prev: number = 1;
    next: number = 1;
    count: number;
    totalPage: boolean;
    @ViewChild('table') table: ElementRef;
    currentpage: any;
    searchTerm: any;
    isEnterPressed: boolean;
    refDialogNodataFound: boolean;


    constructor(
        private userSevice1Child: StorageService,
        private fb1: FormBuilder,
        private route1: ActivatedRoute,
        private router1: Router,
        private changeDetectorRefs1: ChangeDetectorRef,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router,
        private paginationService: PatientListService,
        private infiniteScroll: PatientInfiniteScroll,
        private changeDetectorRefs: ChangeDetectorRef,
        public snackBar: MatSnackBar,
        public global: Globals,
        public configService: ConfigService,
        public team: TeamService,
        private httpClient: HttpClient,
        private apiService: ApiService,
        private patientservice: PatientService
    ) { }

    @ViewChild('paginator', { static: true }) paginator: MatPaginator;
    // @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('paginatorPageSize', { static: true }) paginatorPageSize: MatPaginator;
    // @ViewChild('empTbSort') empTbSort = new MatSort();
    // @ViewChild(MatSort, {static: true}) sort: MatSort;


    pageSizes = [10];

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSourceWithPageSize.paginator = this.paginatorPageSize;

    }
    /**
     * On init
     */
    ngOnInit(): void {
        const lab_id = this.userSevice1Child.getCookie("lab_id");
        if (lab_id) {
            this.refDialogNodataFound = false;
            this.tableData = [];
            this.dataSource.data = [];
            this.hasEditDataShown = false;
            this.loadingSpinner = false;
            this.isLoading = true;
            this.prev = 0;
            this.next = 1;
            this.page = 0;
            this.count = 1;

            this.getallpatient(1);
        } else {
            this.logout();
        }

    }

    logout(): void {
        localStorage.clear();
        this.userSevice1Child.clearCookie();
        this.router.navigate(['/login']);
    }

    getallpatient(page) {
        this.patientservice.getAllpatient(page).subscribe(
            a => {


                //    // console.log(" AllTeammember ==", a) 
                Object.entries(a).find(([key, value]) => {
                    if (key == "message" && value == "No data Found") {
                        // console.log("calling")
                        this.totalPage = true;
                        this.hasEditDataShown = true;
                        this.loadingSpinner = true;


                    }

                    if (key == 'data') {
                        this.patinetData = value;
                        //  // console.log('all patientData',this.patinetData)
                        this.refDialogNodataFound = false;
                        this.patinetData.forEach(el => {
                            //  // console.log(el.patient_id,el.first_name,el.last_name,el.mobile_number,el.date_of_birth)
                            this.tableData.push({
                                patient_id: el.patient_id, first_name: el.first_name, last_name: el.last_name, mobile_number: el.mobile_number, date_of_birth: el.date_of_birth,
                                comboId: "",
                                _id: el._id,
                                email_id: el.email_id,
                                doa: this.currentpage,
                                countryCode: '',
                                dobString: "",
                                deleted: el.deleted,
                            })
                            this.hasEditDataShown = true;
                            this.loadingSpinner = true;


                        });

                    }

                    else {
                        // // console.log(key,'=',value);
                    }
                })
                // // console.log('table data=',this.tableData)

                this.dataSource.data = this.tableData;
                //  // console.log("datasource=",this.dataSource.data)

            },

            err => {
                this.errorMessage = err.error.message;
                this.isLoginFailed = true;
            }
        );


    }


    onKeyDown(event: KeyboardEvent): void {
        console.log("search patient", this.searchText)
        if (event.key === 'Backspace') {
            // Check if the input value is empty
            // console.log(this.selectedPatient)
            //   if (!this.selectedPatient || this.selectedPatient.trim() === '') {
            //     this.filteredPatients = [];
            //   }
        }
    }

    onClickSearch(searchText) {
        this.hasEditDataShown = false;
        this.loadingSpinner = false;

        this.tableData = [];
        this.dataSource.data = [];
        console.log("search calling = ", this.searchText)
        this.patientservice.searchPatient(searchText).subscribe(
            a => {

                // console.log(" AllTeammember ==", a)
                Object.entries(a).find(([key, value]) => {
                    if (key == "message" && value == "No data Found") {
                        this.totalPage = true;
                        this.hasEditDataShown = true;
                        this.loadingSpinner = true;
                        // this.getallpatient(1);
                        // this.refDialogNodataFound = true;
                        // this.openDialogWithRefNodatafound(refDialogNodataFound)

                    }
                    if (key == 'current_pages') {
                        this.currentpage = value;
                    }
                    if (key == 'data') {
                        this.patinetData = value;
                        // console.log('all patientData', this.patinetData)

                        this.patinetData.forEach(el => {
                            //  // console.log(el.patient_id,el.first_name,el.last_name,el.mobile_number,el.date_of_birth)
                            this.tableData.push({
                                patient_id: el.patient_id, first_name: el.first_name, last_name: el.last_name, mobile_number: el.mobile_number, date_of_birth: el.date_of_birth,
                                comboId: "",
                                _id: el._id,
                                email_id: el.email_id,
                                doa: this.currentpage,
                                countryCode: '',
                                dobString: "",
                                deleted: el.deleted,
                            })
                            this.hasEditDataShown = true;
                            this.loadingSpinner = true;


                        });

                    }

                    else {
                        // console.log(key, '=', value);
                        if (key == 'status' && value == 'failed') {
                            const msg = 'No data Found';
                            this.openSnackBar(msg, 'OK');
                            // this.clearInputSearch();
                            // window.location.reload();
                        }

                        // this.hasEditDataShown = true;
                        // this.loadingSpinner = true;
                    }
                })
                // // console.log('table data=',this.tableData)

                this.dataSource.data = this.tableData;
                //  // console.log("datasource=",this.dataSource.data)

            },

            err => {
                this.errorMessage = err.error.message;
                this.isLoginFailed = true;
            }
        );
    }



    clearInputSearch() {
        this.searchText = '';
        this.ngOnInit();

    }


    getRowBackgroundColor(element): string {
        return "#FFFFFF";
    }

    getRowFontColor(element) {
        if (element.deleted == true) {
            return "italic";
        } else return "bold";
    }

    getFontColor(element) {
        if (element.deleted == true) {
            return "#808080";
        } else return "#000000";
    }
    getCrossWord(element) {
        if (element.deleted == true) {
            return "line-through";
        } else return "overline";
    }

    getColumn(): any[] {
        return [
            {
                columnDef: "Sr",
                header: "Sr.",
                hide: false,
                cell: (element: Patient, index: number) =>
                    `${this.page * 10 + index + 1}`,
            },
            {
                columnDef: "First Name",
                header: "First Name",
                hide: false,
                cell: (element: Patient, index: number) =>
                    `${element.first_name}`,
            },
            {
                columnDef: "Last Name",
                header: "Last Name",
                hide: false,
                cell: (element: Patient, index: number) =>
                    `${element.last_name}`,
            },
            {
                columnDef: "ID",
                header: "ID",
                hide: false,
                cell: (element: Patient, index: number) => `${element.patient_id}`,
            },

            {
                columnDef: "Email",
                header: "Email",
                hide: false,
                cell: (element: Patient, index: number) =>
                    this.replaceWithX(element.email_id ? element.email_id : ""),
            },
            {
                columnDef: "mobileNumber",
                header: "Mobile Number",
                hide: false,
                cell: (element: Patient, index: number) =>
                    this.replaceWithMob(element.mobile_number),
            },
            {
                columnDef: "dob",
                header: "DOB (DD/MM/YYYY)",
                hide: false,
                cell: (element: Patient, index: number) =>
                    `${element.date_of_birth
                        ? element.date_of_birth
                        : this.changeTimestampToDate(element.date_of_birth)
                    }`,
            },
            {
                columnDef: "Action",
                header: "Edit",
                hide: false,
                cell: (element: Patient, index: number) => `${element.patient_id}`,
            },
            {
                columnDef: "Delete",
                header: "Delete",
                hide: false,
                cell: (element: Patient, index: number) => `${element.patient_id}`,
            },

            // {
            //     columnDef: "View",
            //     header: "View",
            //     hide: false,
            //     cell: (element: Patient, index: number) =>
            //         this.preparePatientId(
            //             element.patient_id,
            //             element.first_name,
            //             element.last_name
            //         ),
            // },
        ];
    }

    getStringOrDefault(reqkey: any, defaulvalue: any) {
        const rg = defaulvalue;
        try {
            if (reqkey === undefined || reqkey === null) {
                return defaulvalue;
            } else {
                return reqkey;
            }
        } catch (error) {
            rg == defaulvalue;
        }
    }

    replaceWithMob(mob) {
        mob = this.getStringOrDefault(mob, "");
        const mobileNo = mob.toString();
        // // console.log(mobileNo);
        if (mobileNo !== undefined) {
            const mob = mobileNo.replace(/[^0-9]/g, "");
            // // console.log(mob);
            const patt = /^[0-9]\d*$/;
            if (mob && patt.test(mob)) {
                const txt = mob.toString();
                let numb = txt.match(/\d/g);
                numb = numb.join("");
                if (numb.length < 3) {
                    return numb;
                }
                return (
                    numb[0] +
                    new Array(numb.length - 2).join("*") +
                    numb[numb.length - 2] +
                    numb[numb.length - 1]
                );
            } else {
                return "";
            }
        } else {
            return "-";
        }
    }

    replaceWithX(email) {
        let beforeText = "";
        const beforeAtTheRate = email.split("@")[0];
        const afterAtTheRate = email.split("@")[1];
        if (beforeAtTheRate) {
            if (beforeAtTheRate.length >= 3) {
                beforeText =
                    beforeAtTheRate[0] +
                    new Array(beforeAtTheRate.length - 1).join("*") +
                    beforeAtTheRate[beforeAtTheRate.length - 1];
            } else if (beforeAtTheRate.length === 2) {
                beforeText = "**";
            } else {
                beforeText = "*";
            }
            return beforeText.concat("@").concat(afterAtTheRate);
        }
    }

    prepareComboId(id, fname, lname, mobile) {
        let combo = "";
        if (mobile) {
            combo =
                "patient#" +
                id +
                "##firstName#" +
                fname +
                "##lastName#" +
                lname +
                "##mobile#" +
                mobile;
        } else {
            combo =
                "patient#" +
                id +
                "##firstName#" +
                fname +
                "##lastName#" +
                lname;
        }

        return combo;
        // return combo = combo.replace(/\s/g, '').toLowerCase();
    }

    preparePatientId(id, fname, lname) {
        const patientId =
            "patient#" + id + "##firstName#" + fname + "##lastName#" + lname;
        return patientId;
        // return combo = combo.replace(/\s/g, '').toLowerCase();
    }



    updateRow(row): any {
        // setDeriveFields(row);
    }

    ngOnDestroy() {

    }

    getTitleMatIcon(): string {
        return "accessibility";
    }

    getTitle(): string {
        return "Patients";
    }

    getSearchText(): string {
        return "Search Patient via name";
    }

    extraButtonClick(): void {
        this.searchTxt = '';
        const dialogRef = this.dialog.open(AddPatientDialogComponent, {
            disableClose: true,
            width: "350px",
            data: { id: "1", firstName: "", lastName: "" },
        });

        dialogRef.beforeClosed().subscribe((bfrC) => { });
        dialogRef.afterClosed().subscribe((result) => { });
    }



    addZeroToHourAndMinutes(date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        const strTime = hours + ":" + minutes + " " + ampm;
        return strTime;

    }

    getMapValue(stringData) {
        const param = stringData.replace(/[^a-zA-Z0-9]/g, "");
        const obj = {};
        for (let j = 0; j <= param.length - 1; j++) {
            let emptyString = "";
            for (let k = 0; k <= j; k++) {
                emptyString += param[k];
            }

            obj[emptyString] = true;
        }
        return obj;
    }

    toUpperCaseString(str) {
        return str.trim().toUpperCase();
    }

    editButtonClick(comboId): void {
        // console.log('comboId=', comboId)
        const dialogRef = this.dialog
            .open(EditPatientDialogComponent, {
                width: "350px",
                data: { id: "", firstName: "", lastName: "", comboId: comboId },
            })
            .afterClosed()
            .subscribe((data) => {
                // console.log("after close edit dialogue", data)
                this.page = 0;


            });
    }


    openDialogWithRefDel(ref: TemplateRef<any>, id): void {
        // console.log("delete member Id=", id);
        this.idTodel = id;
        this.dialog.open(ref, { width: '400px' });


    }

    openDialogWithRefNodatafound(ref: TemplateRef<any>): void {
        // console.log("delete member Id=", id);
        // this.idTodel = id;
        this.dialog.open(ref, { width: '400px' });


    }

    deleteTeamMember(docId) {
        // console.log("id to del", docId)
        this.hasEditDataShown = false;
        this.loadingSpinner = false;
        this.patientservice.deletePatientFromLab(docId, this.userSevice1Child.getCookie('lab_id')).subscribe(data => {
            // console.log("delete single data =", data)
            Object.entries(data).find(([key, value]) => {
                if (key == 'status' && (value == 'success' || value == 'Success')) {
                    const msg = 'Patient Deleted Successfully';
                    this.openSnackBar(msg, 'OK');

                    this.tableData = [];
                    this.dataSource.data = [];
                    this.hasEditDataShown = false;
                    this.loadingSpinner = false;
                    this.isLoading = true;
                    this.prev = 0;
                    this.next = 1;
                    this.page = 0;
                    this.count = 1;

                    this.getallpatient(1);


                }
                else {

                }


            })

        })



    }

    // onNextPress() {
    //     this.hasEditDataShown = false;
    //     this.loadingSpinner = false;
    //     this.isLoading = true;
    //     this.isNextPressed = true;
    //     this.count = this.count + 1;
    //     this.next = this.prev + 1;
    //     this.prev = this.next - 1
    //     this.page++
    //     this.getallpatient(this.next)
    //     // console.log("next=", this.next, this.prev)
    //     this.next++
    //     this.prev++
    //     this.scrollToBottom();
    // }

    onNextPress() {
        this.hasEditDataShown = false;
        this.loadingSpinner = false;
        this.isLoading = true;
        this.isNextPressed = true;
        this.count = this.count + 1;
        this.next = this.prev + 1;
        this.prev = this.next - 1
        this.next++
        this.prev++
        this.getallpatient(this.next)
        // console.log("next=", this.next, this.prev);

    }

    onPrevPress() {
        this.hasEditDataShown = false;
        this.loadingSpinner = false;
        this.count = this.count - 1;
        this.prev = this.next - 1;
        this.prev--
        this.next--
        this.page--
        this.getallpatient(this.prev)
        // console.log('prev=', this.prev, this.next)
    }

    scrollToBottom(): void {
        if (this.isNextPressed) {
            this.isNextPressed = false;
            const table = document.querySelector('mat-table');
            table.scrollBy({ behavior: 'smooth', left: 0, top: table.scrollHeight });
        }

    }


    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }


    private handleError(error: Error): void {
        // this.openSnackBar(error.message, "OK");
        // this.notify.update(error.message, 'error');
    }

    viewHistory(patientId) {
        let addSearchText = "";
        if (this.searchText) {
            addSearchText = patientId + "##searchTxt#" + this.searchText;
        } else {
            addSearchText = patientId;
        }

        this.router.navigate(["/patientdetail"], {
            queryParams: { token: this.global.encodeToa(addSearchText) },
        });
    }


    openDialogWithRef(ref: TemplateRef<any>, comboId, emailId) {
        this.dialog.open(ref, {
            disableClose: true,
            height: "250px",
            width: "350px",
        });
    }



    openSuccessfulDialog() {
        const dialogRef = this.dialog.open(SuccessfulComponent, {
            disableClose: true,
            width: "350px",
            height: "250px",
        });
    }

    changeTimestampToDate(timestamp) {
        if (timestamp) {
            return moment(timestamp).format("DD/MM/YYYY");
        }
        return "";
    }



    @HostListener('document:keypress', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        this.handleEnterSearch(event);
    }


    handleEnterSearch(event: KeyboardEvent) {
        if (event.key.includes('Enter')
            && event.code.includes('Enter')
            && document.activeElement.id.includes('search')) {
            this.isEnterPressed = true;
            if (this.searchText && this.searchText.length > 0) {
                this.onClickSearch(this.searchText);
            }
        } else {
            this.isEnterPressed = false;
        }
    }

    // getSubscriptionType() {
    //     // console.log("navType", this.navigationType);
    //     switch (this.navigationType) {
    //         case "screening":
    //             return true;
    //         default:
    //             return false;
    //     }
    // }
}
