import {Component, OnDestroy, OnInit, TemplateRef, ViewEncapsulation} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { AuthenticationService } from 'app/main/module/common/service/authentication.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { navigation } from 'app/navigation/navigation';
import { Globals } from '../../../main/module/common/global';
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from 'app/main/module/common/service/storage.service';
import {MatDialog} from '@angular/material/dialog';

export interface Lab {
    value: string;
    viewValue: string;
}

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None,

})

export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    email: string;
    userName: string;
    profileImage: any;
    labsData: any = [];
    labsObjData: any = [];
    defaultLab: any;
    staticData: any;
    qrcodePath = '';
    labId = '';
    isLoading= true;

    // Private
    private _unsubscribeAll: Subject<any>;


    labs: Lab[] =
        [
            { value: 'Hinduja Mahim', viewValue: 'Hinduja Mahim' },
            { value: 'Hinduja Khar', viewValue: 'Hinduja Khar' },
            { value: 'All Mumbai', viewValue: 'All Mumbai' },
            { value: 'Hinduja Delhi', viewValue: 'Hinduja Delhi' },
            { value: 'Hinduja Banglore', viewValue: 'Hinduja Banglore' },
            { value: 'Hinduja All India', viewValue: 'Hinduja All India' },
            { value: 'Hinduja Europe', viewValue: 'Hinduja Europe' }
        ];
    selected = 'Hinduja Mahim';
    userName2: string;
    // selected = this.labsData[0];

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private route: ActivatedRoute,
        private router: Router,
        private globals: Globals,
        private cookieService: CookieService,
        private userService: StorageService,
        private authService: AuthenticationService,
        private dialog: MatDialog,
    ) {
        this.isLoading = true;
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us'
            },
            {
                id: 'fr',
                title: 'French',
                flag: 'fr'
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }



    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.email = this.userService.getCookie("email");
        this.userName = this.userService.getCookie("name");
        this.userName2 = localStorage.getItem('name')
        // console.log("username = ", this.userName)
        // this.profileImage = this.userService.getCookie("userImage")
        // // console.log('ProfileImage=',this.profileImage);
        this.labsData = JSON.parse(localStorage.getItem('labs'));
        this.labsObjData = JSON.parse(localStorage.getItem('labsObject'));
        this.staticData = localStorage.getItem('staticData');
        if (this.labsData) {
            this.defaultLab = this.labsData[0];
        }
        // this.getUserData();
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });

    }
    // getUserData() {
    //     if(this.email)
    //     this.authService.getUser(this.email).then(async (userDto) => {
    //         this.profileImage = userDto && userDto['userImage'] ? userDto['userImage'] : 'assets/images/avatars/user.png';
    //     });
    // }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        // do something
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    logout(): void {
        localStorage.clear();
        this.userService.clearCookie();
        this.globals.cassetteConfig = null;
        this.router.navigate(['/login']).then(x=> location.reload());
        // location.reload();
    }
    profileRedirection(): void {
        this.router.navigate(['/profile']);
    }

    openDialogWithRef(ref: TemplateRef<any>) {
        this.isLoading = true;
        this.labId = this.userService.getCookie('lab');
        const collectionPath = `/lab/${this.labId}/config/`;
        // const logoPath = this.afs.collection(collectionPath).doc('lab');
        // logoPath.ref.get().then(data => {
        //     const logoPathName = data.data();
        //     if (logoPathName) {
        //         this.qrcodePath = logoPathName.qrcodeUrl;
        //         this.isLoading = false;
        //     }


        // });

            // // console.log(this.rowData);
            this.dialog.open(ref, {width: '320px', disableClose: true});

    }

    hideLoader() {
        this.isLoading = false;
    }

}
