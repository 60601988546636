import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map } from 'rxjs/operators';
import { BaseListService } from "../../../base/base-list.service";
import { StorageService } from 'app/main/module/common/service/storage.service';
import { isAwaitExpression } from 'typescript';





// Options to reproduce firestore queries consistently
interface QueryConfig {
    path: string, // path to collection
    field: string, // field to orderBy
    limit?: number, // limit per query
    reverse?: boolean, // reverse order?
    prepend?: boolean // prepend to source?
}
@Injectable()
export class EmailListService {


    onEmailChanged: BehaviorSubject<any>;

    private itemsSubject: BehaviorSubject<any[]>;
    public itemsObservable: Observable<any[]>;
    public pageSubject: BehaviorSubject<number>;
    public pageObservable: Observable<number>;

    public notFoundObservable: Observable<boolean>;
    public notFoundSubject: BehaviorSubject<boolean>;

    latestEntry: any;
    startingEntry: any;
    currentPage = 0;
    protected table = 'mail';
    protected orderBy = '';
    protected isOrderByNegative = true;
    public searchedText: string = null;


    constructor(
        public storageServise: StorageService,private userService: StorageService) {
        this.table = 'mail';
        this.orderBy = 'time';
        this.onEmailChanged = new BehaviorSubject({});
    }

    // getEmailDetailsById(id) {
    //     return new Promise((resolve, reject) => {
    //         this.afs.collection(this.table).doc(id).valueChanges().subscribe(data => {
    //             resolve(data);
    //         });
    //     });

    // }

    setPositiveOrderBy(): void {
        this.isOrderByNegative = false;
    }
    getCollectionPath(): string {
        return  this.table;
    }


    // Source data
    private _done = new BehaviorSubject(false);
    private _loading = new BehaviorSubject(false);
    private _data = new BehaviorSubject([]);

    private query: QueryConfig;

    // Observable data
    data: Observable<any>;
    done: Observable<boolean> = this._done.asObservable();
    loading: Observable<boolean> = this._loading.asObservable();


    // Initial query sets options and defines the Observable
    init(path, limit, field, opts?,all_records?) {
        this.query = {
            path,
            field,
            limit: limit,
            reverse: false,
            prepend: false,
            ...opts
        }
        this._done = new BehaviorSubject(false);
        this._loading = new BehaviorSubject(false);
        this._data = new BehaviorSubject([]);
        this.done = this._done.asObservable();
        this.loading = this._loading.asObservable();
        // const first = this.afs.collection(this.query.path, ref => {
        //     if(all_records==true)
        //     {
        //         return ref
        //         .where('labId','==', this.storageServise.getCookie("lab")).orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
        //     }
        //     else
        //     {
        //         // console.log(this.storageServise.getCookie("lab"));
        //         return ref
        //         .where('labId','==', this.storageServise.getCookie("lab")).orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
        //             .limit(this.query.limit)
        //     }

        // })

        // this.mapAndUpdate(first)

        this._data.next([]);
        // Create the observable array for consumption in components
        // this.data = this._data.asObservable()
        //     .scan((acc, val) => {
        //         return this.query.prepend ? val.concat(acc) : acc.concat(val)
        //     })
    }


    // Retrieves additional data from firestore
    // more() {
    //     const cursor = this.getCursor()
    //     const more = this.afs.collection(this.query.path, ref => {
    //         return ref
    //             .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
    //             .limit(this.query.limit)
    //             .startAfter(cursor)
    //     })
    //     this.mapAndUpdate(more)
    // }


    // Determines the doc snapshot to paginate query
    private getCursor() {
        const current = this._data.value

        if (current.length) {
            return this.query.prepend ? current[0].doc : current[current.length - 1].doc
        }
        return null
    }


    // Maps the snapshot to usable format the updates source
    // private mapAndUpdate(col: AngularFirestoreCollection<any>) {

    //     if (this._done.value || this._loading.value) {
    //         return
    //     }
    //     ;

        // loading
        // this._loading.next(true)

        // Map snapshot with doc ref (needed for cursor)
        // return col.snapshotChanges()
        //     .do(arr => {
        //         let values = arr.map(snap => {
        //             const data = snap.payload.doc.data()
        //             const doc = snap.payload.doc
        //             return {...data, doc}
        //         })

        //         // If prepending, reverse array
        //         values = this.query.prepend ? values.reverse() : values

        //         // update source with new values, done loading
        //         this._data.next(values)
        //         this._loading.next(false)

        //         // no more values, mark done
        //         if (!values.length) {
        //             this._done.next(true)
        //         }
        //     })
        //     .take(2)
        //     .subscribe()

    // }


    // // Reset the page
    // reset() {
    //     this._data.next([])
    //     this._done.next(false)
    // }

}
