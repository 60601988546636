import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'app/main/module/common/service/storage.service';
import { CassetteListService } from '../list/cassette-list.service';
import { CassetteInfiniteScroll } from '../list/cassette-infinite-scroll.service';
import { FormBuilder } from '@angular/forms';
import { StatusService } from '../../status/status.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigService } from 'app/main/module/common/config/config.service';
import { ApiService } from 'app/main/module/common/service/api.service';
import { Globals } from 'app/main/module/common/global';
import { Cassette } from '../cassette';
import { CassetteConfig } from 'app/main/module/common/config/cassetteConfig';
import { MatTableDataSource } from '@angular/material/table';
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: 'app-patient-assign',
    templateUrl: './patient-assign.component.html',
    styleUrls: ['./patient-assign.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class PatientAssignComponent implements OnInit {
    columns = [
        { columnDef: 'SrCassette', header: 'Sr.', cell: (element: Cassette, index: number) => `${this.page * 10 + index + 1}` },
        {
            columnDef: 'Barcodes', header: 'Barcode', cell: (element: Cassette, index: number) => `${element.barcode}`,
            textColor: (element: Cassette, index: number) => `${this.getBarcodeColor(element)}`
        },
        { columnDef: 'type2', header: 'Type', cell: (element: Cassette, index: number) => `${element.type ? element.type : '-'}` },
        // { columnDef: 'Load No', header: 'Load No', cell: (element: Cassette, index: number) => `${element.loadNo}` },
        { columnDef: 'Cycle No', header: 'Cycle No', cell: (element: Cassette, index: number) => `${element.cycleNo}` },
        {
            columnDef: 'ResultCassette', header: 'Result',
            cell: (element: Cassette, index: number) => `${element.platform}`,
            textColor: (element: Cassette, index: number) => `${this.getLoadColor(element)}`
        },
        { columnDef: 'Date', header: 'Date|Time', cell: (element: Cassette, index: number) => `${this.changeDate(element.dateTimeNum)}` },
        // { columnDef: 'timeCassette', header: 'Time', cell: (element: Cassette, index: number) => `${element.time}` },
        {
            columnDef: 'adepart', header: 'Assigned Department',
            cell: (element: Cassette, index: number) => `${element.assigned_to_department}`,
            // textColor: (element: Cassette, index: number) => `${this.getSingleLoadColor(element.bd_status)}`
        },
        //   {
        //     columnDef: 'BD', header: 'BD',
        //     cell: (element: Cassette, index: number) => `${element.barcodeBd ? element.barcodeBd : '-'}`,
        //     textColor: (element: Cassette, index: number) => `${this.getSingleLoadColor(element.bd_status)}`
        // },
        //   {
        //       columnDef: 'BI', header: 'BI(Spore Test)',
        //       cell: (element: Cassette, index: number) => `${element.barcodeBi ? element.barcodeBi : '-'}`,
        //       textColor: (element: Cassette, index: number) => `${this.getSingleLoadColor(element.bi_status)}`
        //   },
        //   {
        //       columnDef: 'EI', header: 'PCD',
        //       cell: (element: Cassette, index: number) => `${element.barcodeEi ? element.barcodeEi : ''}`,
        //       textColor: (element: Cassette, index: number) => `${this.getSingleLoadColor(element.ei_status)}`
        //   },
        {
            columnDef: 'StatusCassette', header: 'Status',
            cell: (element: Cassette, index: number) => `${element.status}`
        },
        {
            columnDef: 'details', header: 'More Details',
            cell: (element: Cassette, index: number) => this.loadStatusUsed(this.getLoadStatus(element), element.barcode)
        },
        {
            columnDef: 'actionCassette', header: 'Checkout',
            cell: (element: Cassette, index: number) => `${this.canAssign(element), element.barcode}`
        },
    ];
    displayedColumns = this.columns.map((c) => c.columnDef);

    // columns = this.getColumn();
    // titleMatIcon = this.getTitleMatIcon();
    // title = this.getTitle();
    // displayedColumns = this.columns.map(c => c.columnDef);
    // @ViewChild('filter', { static: true })
    // filter: ElementRef;

    // @ViewChild(MatSort, { static: true })
    // sort: MatSort;
    cassette: string;
    perPageData = this.globals.perPageData;
    protected extraCta = {
        visibility: false,
        text: '',
    };

    disableUnassign = true;
    // @ViewChild(MatPaginator, {static: true})
    // paginator: MatPaginator;
    isInitComplete = true;
    isLoading = false;
    isNextPressed = false;
    todayDate: Date = new Date();
    isMoreDataAvailable = true;
    prev: number;
    next: number;
    count: number;
    totalPage: any;
    allData: Array<any>;
    errorMessage: any;
    loadedcassetteData: Cassette[] = []
    cassetteConfigData: CassetteConfig[] = []
    dataSource = new MatTableDataSource<any>(this.loadedcassetteData);
    dataSourceFilters = new MatTableDataSource(this.loadedcassetteData);
    dataSourceWithPageSize = new MatTableDataSource(this.loadedcassetteData);
    page: number;
    canShowAlertIfIncubatorPending: unknown;
    assignedBy: any;
    assignedOn: any;
    status: string;
    isassigndonefinal: any;
    can_assign_if_incubator_pending: unknown;
    isLoginFailed: boolean;
    searchText: any;
    result: string;


    constructor(
        private userSevice: StorageService, private fb: FormBuilder,
        private route: ActivatedRoute, private router: Router,
        private paginationService: CassetteListService,
        private infiniteScroll: CassetteInfiniteScroll,
        private changeDetectorRefs: ChangeDetectorRef,
        private statusService: StatusService,
        public snackBar: MatSnackBar,
        private configService: ConfigService,
        private apiService: ApiService,
        private globals: Globals) {
        // super(snackBar, configService, globals);
    }

    ngOnInit(): void {

        const lab_id = this.userSevice.getCookie("lab_id");
        if (lab_id) {

            this.isInitComplete = true;
            this.dataSource.data = [];
            this.loadedcassetteData = [];
            // this.getCassetteConfig();
            this.isLoading = true;
            this.prev = 0;
            this.next = 1;
            this.page = 0;
            this.count = 1;
            this.getAllData(1);
        } else {
            this.logout();
        }
    }

    logout(): void {
        localStorage.clear();
        this.userSevice.clearCookie();
        this.router.navigate(['/login']);
    }

    getSingleLoadColor(load: string): string {
        if (load) {
            return this.statusService.getColor(
                this.statusService.getStatus(load)
            );
        } else {
            return "#111111";
        }
    }

    viewCassette(barcode) {
        this.router.navigate(['/viewcassettedetails'], { queryParams: { barcode: barcode } });

    }

    onNextPress() {
        this.isLoading = true;
        this.isNextPressed = true;
        this.count = this.count + 1;
        this.next = this.prev + 1;
        this.prev = this.next - 1
        this.next++
        this.prev++
        this.getAllData(this.next)
        console.log("next=", this.next, this.prev);
    }

    getRowBackgroundColor(element): string {
        return '#FFFFFF';
    }

    loadStatusUsed(functValue, id) {
        // if(functValue === 'USED'){
        return id;
        // }
    }

    getCassetteResult(load: Cassette): string {
        console.log("Result=", this.statusService.getMessage(
            this.statusService.getStatusNew(
                load.bd_status,
                load.bi_status,
                load.ei_status
            )
        ))
        return this.statusService.getMessage(
            this.statusService.getStatusNew(
                load.bd_status,
                load.bi_status,
                load.ei_status
            )
        );
    }

    getLoadStatus(load: Cassette): string {
        return this.statusService.getCassetteStatus(load);
    }
    canAssign(cassette): boolean {
        console.log("cas=", cassette.set_status)
        // if (cassette.set_status == "Assigned") {
        return cassette.barcode;
        // }
    }
    getLoadColor(load: Cassette): string {
        return this.statusService.getColor(
            this.statusService.getStatusNew(
                load.bd_status,
                load.bi_status,
                load.ei_status
            )
        );
    }
    assignCassette(barcode) {
        this.router.navigate(['/patientcheckout'], { queryParams: { barcode: barcode.trim() } });
    }

    changeDate(date): any {
        // console.log("date=",date)
        var timestamp = parseInt(date);
        return date = this.apiService.getDateStringMomentByTz(timestamp);

    }

    getBarcodeColor(element) {
        if (element.verified == true) {
            return this.statusService.getBarcodeColor(element.barcode);
            //     console.log(element.verified)
            //     if(element.verified == true){
            //     return '##0000FF'; 
        }
        // else{
        //     return '#000000'
        // }
    }

    getAllData(page) {
        this.isInitComplete = true;
        this.paginationService.getAllPatientCheckoutData(page).subscribe(
            a => {
                console.log(" All Patient checkout Data ==", a)
                Object.entries(a).find(([key, value]) => {
                    if (key == 'total_pages') {
                        this.totalPage = value;
                    }
                    if (key == 'data') {
                        this.isInitComplete = false;
                        this.allData = value;
                        //  console.log(this.allData)
                        this.isassigndonefinal = '';
                        this.allData.forEach(element => {
                            //  console.log(element.load_id)  
                            this.isLoading = false;
                            const cass: Cassette = element;
                            if (element.department_assigned_by) {
                                this.assignedBy = element.department_assigned_by.name;
                                cass.isAssignDone = element.department_assigned_by.name
                                this.isassigndonefinal = cass.isAssignDone;

                            }

                            this.result = this.getCassetteResult(element);
                            console.log("Result=", this.result);

                            if (element.set_status == "Assigned") {
                                // this.status = "Assigned Department"
                                this.status = this.getLoadStatus(element);
                                console.log(' status = ' + this.status)

                            }
                            else
                                if (element.set_status == "Used") {
                                    if (this.result == "Pending") {
                                        this.status = "Used(P)";
                                    }
                                    else {
                                        this.status = "Used";
                                    }

                                }
                            //  else 

                            this.loadedcassetteData.push({
                                assigned_to_department: element.assigned_to_department,
                                barcode: element.barcode,
                                added: undefined,
                                startedBy: undefined,
                                assigned: undefined,
                                updated: undefined,
                                spoiled: undefined,
                                comment: '',
                                receiverName: '',
                                departmentName: '',
                                department: undefined,
                                print_title: element.print_title,
                                barcodeBd: element.bd_barcode,
                                barcodeBi: element.bi_barcode,
                                barcodeEi: element.ei_barcode,
                                machineName: '',
                                status: this.status,
                                bd_status: element.bd_status,
                                bi_status: element.bi_status,
                                ei_status: element.ei_status,
                                isAssignDone: element.department_assigned_by,
                                unassigned_by: element.unassigned_by,
                                isSpoiled: element.spoiled_by,
                                type: element.set_type,
                                version: 0,
                                cycleNo: element.cycle_number,
                                loadNo: element.load_number,
                                program: '',
                                dateTimeNum: element.timestamp,
                                loadIdStr: '',
                                machineIdStr: '',
                                plateform: '',
                                platform: this.result,
                                verified: '',
                                createdUser: '',
                                patientId: '',
                                patientName: '',
                                cassetteID: '',
                                updatedBy: '',
                                time: '',
                                date: element.timestamp,
                                id: '',
                                docId: ''
                            })
                        });

                    }

                    else {
                        // console.log(key,'=',value);
                    }
                })
                //  console.log('table data=',this.loadData)

                this.dataSource.data = this.loadedcassetteData;
                //  console.log("datasource=",this.dataSource.data);
            },

            err => {
                this.errorMessage = err.error.message;
                console.log('unauthorised', err.status, this.errorMessage)
                if (err.status == 401) {
                    alert('Token is invalid or has expired Please relogin')
                    // this.logout()
                }

            }
        );

    }

    // need to uncomment and implement

    onClickSearch(searchText) {
        this.isInitComplete = true;
        this.searchText = searchText;
        this.isLoading = true;
        console.log('searchText', searchText)
        if (searchText == '') {
            window.location.reload();
        } else {
            this.loadedcassetteData = [];
            this.dataSource.data = [];
            this.allData = [];
            this.paginationService.searchCasset(searchText).subscribe(
                a => {
                    console.log(" search casset Data =", a)
                    Object.entries(a).find(([key, value]) => {
                        if (key == 'data') {
                            this.isInitComplete = false;
                            this.allData = value;
                            console.log(this.allData)
                            this.isassigndonefinal = '';
                            this.allData.forEach(element => {
                                //  console.log(element.barcode)  
                                this.isLoading = false;
                                const cass: Cassette = element;
                                if (element.department_assigned_by) {
                                    this.assignedBy = element.department_assigned_by.name;
                                    // this.assignedOn = this.updateDate(element.department_assigned_by.timestamp);
                                    cass.isAssignDone = element.department_assigned_by.name
                                    this.isassigndonefinal = cass.isAssignDone;
                                }
                                this.status = this.getLoadStatus(element);
                                console.log(' status = ' + this.status)
                                if (this.status == "In Department(P)" || this.status == "In Department" || this.status == "Used(P)" || this.status == "Used") {
                                    this.loadedcassetteData.push({
                                        assigned_to_department: element.assigned_to_department,
                                        barcode: element.barcode,
                                        added: undefined,
                                        startedBy: undefined,
                                        assigned: undefined,
                                        updated: undefined,
                                        spoiled: undefined,
                                        comment: '',
                                        receiverName: '',
                                        departmentName: '',
                                        department: undefined,
                                        print_title: element.print_title,
                                        barcodeBd: element.bd_barcode,
                                        barcodeBi: element.bi_barcode,
                                        barcodeEi: element.ei_barcode,
                                        machineName: '',
                                        status: this.status,
                                        bd_status: element.bd_status,
                                        bi_status: element.bi_status,
                                        ei_status: element.ei_status,
                                        isAssignDone: element.department_assigned_by,

                                        unassigned_by: element.unassigned_by,
                                        isSpoiled: element.spoiled_by,
                                        type: element.set_type,
                                        version: 0,
                                        cycleNo: element.cycle_number,
                                        loadNo: 0,
                                        program: '',
                                        dateTimeNum: element.timestamp,
                                        loadIdStr: '',
                                        machineIdStr: '',
                                        plateform: '',
                                        platform: '',
                                        verified: '',
                                        createdUser: '',
                                        patientId: '',
                                        patientName: '',
                                        cassetteID: '',
                                        updatedBy: '',
                                        time: '',
                                        date: element.timestamp,
                                        id: '',
                                        docId: ''
                                    })
                                } else {
                                    return alert(searchText + " Barcode Status is " + this.status)
                                }
                            });

                        } else if (key == 'status' && value == 'failed') {
                            // else {
                            console.log(key, '=', value);
                            this.isLoading = false;
                            const msg = 'No Data Found';
                            this.openSnackBar(msg, 'OK');
                            // window.location.reload();
                        }
                    })
                    //  console.log('table data=',this.loadData)
                    this.dataSource.data = this.loadedcassetteData;
                    //  console.log("datasource=",this.dataSource.data)

                },
                err => {
                    this.errorMessage = err.error.message;
                    console.log('unauthorised', err.status, this.errorMessage)
                    this.isLoginFailed = true;
                }
            );
        }
    }

    openSnackBar(message: string, action: string): void {
        // this.isLoading = true;
        // this.hasFirstDataShown = true;
        this.snackBar.open(message, action, {
            duration: 1000,
        });
    }

    clearInputSearch() {
        this.searchText = '';
        this.ngOnInit();

    }


}
