import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { StorageService } from 'app/main/module/common/service/storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
const AUTH_API = 'https://backend-proplus.apps.sterilwize.com/api/v2/';

const httpOptions = {

    headers: new HttpHeaders({ 'Content-Type': 'application/json' })

  };

export class UserDto {

    center: string;

    lab: string;

    name: string;

    userImage:string;

    role: string;

    staticData: string;

    labs: any = [];

    labsObject: any = [];



}



@Injectable({

    providedIn: 'root'

})



export class AuthenticationService {
    credentialData: any;
    constructor( public router: Router, public userService:StorageService,private http: HttpClient,private cookieService: CookieService) {
    }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.cookieService.get('token');
        if (token) {
          const authReq = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${token}`)
          });
          return next.handle(authReq);
        } else {
          return next.handle(req);
        }
      }
    

    // Send email verification when new user sign up



    // reSendVerificationMail() {



    //     return this.angularFireAuth.auth.currentUser.sendEmailVerification()

    //         .then(() => {

    //             localStorage.setItem('resendBtn', '');

    //             //  localStorage.setItem('EmailVerification', 'An email sent to your email address. Please validate your email address then you can login.');

    //             this.router.navigate(['/login']);

    //         });

    // }



    // SendVerificationMail() {



    //     return this.angularFireAuth.auth.currentUser.sendEmailVerification()

    //         .then(() => {

    //             localStorage.setItem('resendBtn', 'N');

    //             //localStorage.setItem('EmailVerification', 'Validate Your Email ');

    //             this.router.navigate(['/validate']);

    //         });

    // }

    // getValidationMessage(){
    //     const getCollectionPath ='common/config/messages';
    //     return new Promise<any>((resolve, reject)=>{
    //     this.afs.collection(getCollectionPath).doc('registration').valueChanges().subscribe(data =>{
    //     // console.log(data);
    //     resolve(data);
    //     })

    //     })

    // }



    /* Sign up */

    // signUp(email: string, password: string) {

    //     return this.angularFireAuth

    //         .auth

    //         .createUserWithEmailAndPassword(email, password)

    //         .then(res => {

    //             // this.SendVerificationMail();

    //         })

    // }









    /* Sign in */

    



    // getUser(email): Promise<UserDto> {

    //     return new Promise((resolve, reject) => {

    //         this.afs.collection('user').doc(email).get().subscribe((doc: any) => {

    //             if (doc) {

    //                 const userInfo = doc.data();

    //                 resolve(userInfo);

    //             } else {

    //                 reject(new Error('sterilwize.user.not.available'));

    //             }

    //         });

    //     });

    // }





    /* Sign out */

    // signOut(): void {

    //     this.angularFireAuth

    //         .auth

    //         .signOut();

    // }



    setCredentialData(credentialData: any) {

        this.credentialData = credentialData;

    }



    getCredentialData(): any {

        return this.credentialData;

    }



    isLoggedIn() {

        // return this.angularFireAuth.authState.pipe(first()).toPromise();

    }

    register(user): Observable<any> {
        // console.log("user",user)
        return this.http.post(AUTH_API + 'signup', {
           
          name: user.name,
          email: user.email,
          password: user.password,
          enabled:false,
          created:Date.now(),
          updated:Date.now(),
          last_signed_in:Date.now(),
          labs:[],
          clusters:["123xyz"],
          sites:["delhi"]

        }, httpOptions);
      }

   

    getRoleById(roleId) {



        // return new Promise<any>((resolve, reject) => {

        //     const collectionName = 'common/config/webRole';

        //     this.afs.collection(collectionName).doc(roleId).get().subscribe((doc) => {

        //         if (doc) {

        //             resolve(doc.data());

        //         } else {

        //             reject(' Error in reading the role details');

        //         }

        //     });

        // });



    }

    login(credentials:any): Observable<any> {

     console.log("call service ",credentials.email)
   
        return this.http.post(AUTH_API + 'signin',  {

            'email': credentials['email'],

            'password': credentials['password']

          }, httpOptions);

      }

}