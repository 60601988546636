import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map } from 'rxjs/operators';
import { Cassette } from '../cassette';
import { Patient } from '../../patient/patient';
import { Globals } from "../../../common/global";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { StorageService } from 'app/main/module/common/service/storage.service';

@Injectable()
export class UnassignService {
    private itemsSubject: BehaviorSubject<Cassette[]>;
    public itemsObservable: Observable<Cassette[]>;
    public pageSubject: BehaviorSubject<number>;
    public pageObservable: Observable<number>;
    apiUrl = "https://backend-proplus.apps.sterilwize.com/api/v2/"
    latestEntry: any;
    startingEntry: any;
    ROWS = 10;
    table = 'cassette';
    tablePatient = 'patient';
    orderBy = 'dateTimeNum';
    currentPage = 0;

    constructor(public globals: Globals,
        private userService: StorageService,
        public httpClient: HttpClient,) {
    }

    // getCollection(ref, queryFn?): Observable<any[]> {
    //     return this.afs.collection(ref, queryFn).snapshotChanges().pipe(map(actions => {
    //         return actions.map(a => {
    //             const data = a.payload.doc.data();
    //             const id = a.payload.doc.id;
    //             const doc = a.payload.doc;
    //             return { id, ...data as {}, doc };
    //         });
    //     }));
    // }

    getCassetteEntryObserable(barcode: string){
        const token = this.userService.getCookie('token');
        const lab_id = this.userService.getCookie('lab_id');
        //  console.log("barcode= ",barcode)
        //   const headers = new HttpHeaders().set('Authorization', `${token}`);
         const httpOptions = {
              headers: new HttpHeaders({
                         'Authorization': token,
                         'Content-Type': 'application/json',
                         'Access-Control-Allow-Origin': '*',
                         'Access-Control-Allow-Credentials': 'true'
            })
          };
        return this.httpClient.get(this.apiUrl +'sets?lab_id='+ lab_id +'&barcode='+ barcode, httpOptions ) ;
    }

    // first(): void {
    //     this.itemsSubject = new BehaviorSubject([]);
    //     this.itemsObservable = this.itemsSubject.asObservable();
    //     this.pageSubject = new BehaviorSubject<number>(0);
    //     this.pageObservable = this.pageSubject.asObservable();
    //     const ref = this.getCollection(this.getCollectionPath(), ref => ref
    //         .orderBy(this.orderBy)
    //         .where(this.orderBy, '<', 0)
    //         .limit(this.ROWS))
    //         .subscribe(data => {
    //             this.pageSubject.next(0);
    //             this.latestEntry = data[data.length - 1].doc;
    //             this.startingEntry = data[0].doc;
    //             this.itemsSubject.next(data);
    //             // scoresRef.unsubscribe();
    //         });
    // }

    // next(): void {
    //     const ref = this.getCollection(this.getCollectionPath(), ref => ref
    //         .orderBy(this.orderBy)
    //         .startAfter(this.latestEntry)
    //         .where(this.orderBy, '<', 0)
    //         .limit(this.ROWS))
    //         .subscribe(data => {
    //             if (data.length) {
    //                 this.currentPage++;
    //                 this.pageSubject.next(this.currentPage);
    //                 // And save it again for more queries
    //                 this.latestEntry = data[data.length - 1].doc;
    //                 this.startingEntry = data[0].doc;
    //                 this.itemsSubject.next(data);
    //             }
    //             // scoresRef.unsubscribe();
    //         });
    // }


    // prev(): void {
    //     const ref = this.getCollection(this.getCollectionPath(), ref => ref
    //         .orderBy(this.orderBy, 'desc')
    //         .where(this.orderBy, '<', 0)
    //         // Now you can use the latestEntry to query with startAfter
    //         .startAfter(this.startingEntry)
    //         .limit(this.ROWS))
    //         .subscribe(data => {
    //             if (data.length) {
    //                 data.reverse();
    //                 // And save it again for more queries
    //                 this.latestEntry = data[data.length - 1].doc;
    //                 this.startingEntry = data[0].doc;
    //                 this.currentPage--;
    //                 this.pageSubject.next(this.currentPage);
    //                 this.itemsSubject.next(data);
    //             }
    //             // scoresRef.unsubscribe();
    //         });
    // }


    // getCollectionPath(): string {
    //     return 'lab/' + this.userService.getCookie("lab") + '/' + this.table;
    // }


    // getCassette(id: string) {
    //     return this.afs.doc<Cassette>(`${this.getCollectionPath()}/${id}`);
    // }

    // getPatientCollectionPath(): string {
    //     return 'lab/' + this.userService.getCookie("lab") + '/' + this.tablePatient;
    // }


    // getPatient(id: string) {
    //     return this.afs.doc<Patient>(`${this.getPatientCollectionPath()}/${id}`);
    // }


    // getPatientObserable(id: string): Observable<Patient[]> {
    //     return this.afs.collection(this.getPatientCollectionPath(), (ref) => ref
    //         .where('id', '==', id)
    //         .limit(1)).snapshotChanges().pipe(map((actions) => {
    //             return actions.map((a) => {
    //                 const data = a.payload.doc.data() as Patient;
    //                 data.docId = a.payload.doc.id;
    //                 return data;
    //             });
    //         }));
    // }


    // updateCassette(id: string, data: Partial<Cassette>) {
    //     return this.getCassette(id).update(data);
    // }


    unassignSets(updateData){
        const token = this.userService.getCookie('token');
        //  console.log("call service ",updateData)
        const headers = new HttpHeaders().set('Authorization', `${token}`);
        const labId = this.userService.getCookie("lab_id")
        // console.log("labId=",labId)
        return this.httpClient.put(this.apiUrl +'sets?lab_id='+labId,updateData, {headers}) ;
    }



}
