import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusService } from '../../status/status.service';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../../../common/service/api.service';
import { LoadService } from '../../load/load.service';
import { StorageService } from 'app/main/module/common/service/storage.service';
import * as es6printJS from "print-js";
import * as  printJS from "print-js";
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels, NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { clear } from 'console';
import { CassetteListService } from '../list/cassette-list.service';

const timeZone = require('moment-timezone');
@Component({
  selector: 'app-setsdetail',
  templateUrl: './setsdetail.component.html',
  styleUrls: ['./setsdetail.component.scss']
})
export class SetsdetailComponent implements OnInit {
  name = 'Angular ';
  elementType = NgxQrcodeElementTypes.IMG;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.LOW;
  value = '';
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = ['sr', 'name', 'code', 'quantity'];
  urlData: any;
  tableList: any;
  getStatus: any;
  getError: any;
  attached: any;
  barcode: any;
  categories: any;
  created: any;
  created_by: any;
  particulars: any = [];
  department: any;
  subcategories: any;
  quantity: any;
  updated: any;
  updated_by: any;
  verified: any;
  insName: any;
  code: any;
  finalBarcode: any;
  qrwidth: any;
  titlemargintop: any;
  lablemargintop: any;
  labelmarginright: any;
  printer_dpi: string;
  titlefontsize: any;
  barcodefontsize: any;
  pagemargintop: any;
  pagemarginbottom: any;
  pagemarginleft: any;
  pagemarginright: any;
  lastId: number;
  connect_type: any;
  barcode_type: any;
  dpi: any;
  rightContainer: {
    'font-family': string; 'margin-right': string; //50
    'margin-top': string;
  };
  headingBarcode: {
    // 'font-size': this.barcodefontsize + 'px',
    'margin-top': string; //20
    'font-size': string;
  };
  headingTitle: {
    // 'font-family': 'Verdana, Geneva, Tahoma, sans-serif',
    'margin-top': string;
    // 'font-size': this.titlefontsize + 'px',
    'font-size': string;
  };
  setsPrintTitle: any;
  href: string;
  currentPageURL: string;
  getbarcodefromParam: string;
  set_scope: any;
  instrumentSize: any;





  constructor(
    private route: ActivatedRoute, private router: Router,
    public statusService: StatusService,
    public userService: StorageService,
    public cassetteListService: CassetteListService,
    private location: Location, public dialog: MatDialog, public apiService: ApiService,
  ) {

    this.elementType = NgxQrcodeElementTypes.IMG;
    this.correctionLevel = NgxQrcodeErrorCorrectionLevels.LOW;
    this.value = this.finalBarcode;
  }
  ngOnDestroy(): void {
    // this.loadObservableData
  }

  ngOnInit(): void {
    const lab_id = this.userService.getCookie("lab_id");
    if (lab_id) {
      // this.urlData = this.route.snapshot.queryParams.barcode;
      // console.log(this.urlData);
      this.href = this.router.url;
      // console.log('urldata',this.router.url);
      this.currentPageURL = (window.location.href).toString();

      this.getbarcodefromParam = this.currentPageURL.split('=')[1];
      //   console.log("sss",getbarcodefromParam)
      if (this.getbarcodefromParam !== '' && this.getbarcodefromParam !== undefined) {
        this.getSetsDocuments(this.getbarcodefromParam);
      }
      //   else {

      //      window.alert('No Data found, try again!');
      //  }
      this.getprinterconfig();
    } else {
      this.logout();
    }
  }

  logout(): void {
    localStorage.clear();
    this.userService.clearCookie();
    this.router.navigate(["/login"]);
  }


  getSetsDocuments(barcode) {
    this.finalBarcode = '';

    this.cassetteListService.getSetsDetailByBarcode(barcode).subscribe({
      next: data => {
        console.log(data)
        // this.data2.push(data['data']);
        this.tableList = data['data'];
        console.log(this.tableList)

        this.tableList.forEach(element => {
          console.log(element);

          this.attached = element.attached;
          this.barcode = element.barcode;
          this.finalBarcode = element.barcode;
          this.elementType = NgxQrcodeElementTypes.IMG;
          this.correctionLevel = NgxQrcodeErrorCorrectionLevels.LOW;
          this.value = this.finalBarcode;

          this.setsPrintTitle = element.print_title;
          this.categories = element.categories;
          if (element.created_by && element.created_by != undefined) {
            this.created_by = element.created_by.name;
            this.created = this.updateDate(element.created_by.timestamp)
          }
          // this.created=this.updateDate(element.created);
          // this.created_by=element.created_by;
          this.department = element.departments;
          this.subcategories = element.subcategories;
          this.set_scope = element.set_scope;
          this.updated = this.updateDate(element.updated);
          this.updated_by = element.updated_by;
          this.verified = element.verified;
          element.instruments.forEach(element => {
            this.particulars.push(element);
            this.insName = element.name;
            this.quantity = element.quantity;
            this.code = element.code;
          });
          this.instrumentSize = element.instruments.length;
          this.dataSource.data = this.particulars;

        });



      },
      error: HttpErrorResponse => {
        this.getStatus = HttpErrorResponse.error.status;
        console.log("status=", this.getStatus)
        this.getError = HttpErrorResponse.error.error;
        console.log("error=", this.getError);
      }
    });
  }

  updateDate(value): any {
    const value2 = parseInt(value)

    const dateTime = timeZone(Math.abs(value2)).tz(this.userService.getCookie('defaultTz')).format('DD-MM-YYYY | HH:mm');
    return dateTime

  }
  ngAfterViewInit(): void {
  }
  onCancelFrm() {

  }


  getprinterconfig() {
    this.apiService.getLabelprintersApi().subscribe({
      next: data => {
        this.lastId = 0;
        console.log(data['data'])
        data['data'].forEach(element => {
          this.connect_type = element.connect_type;
          this.barcode_type = element.barcode_type;
          this.printer_dpi = element.printer_dpi;
          this.dpi = element.dpi;
          // let map=dpi.get(printer_dpi)
          let jsonMap = new Map(Object.entries(this.dpi));
          console.log(jsonMap)
          for (var entry of jsonMap.entries()) {
            let key = entry[0], value = entry[1];
            let innerJson = JSON.stringify(value);
            let innerJsonObj = JSON.parse(innerJson);
            // console.log(key)
            console.log(innerJsonObj)
            if (key == this.printer_dpi) {
              this.qrwidth = innerJson['qrwidth'];
              this.titlemargintop = innerJsonObj['titlemargintop']; // new field add as
              this.lablemargintop = innerJsonObj['lablemargintop'];
              this.labelmarginright = innerJsonObj['labelmarginright'];
              this.titlefontsize = innerJsonObj['titlefontsize'];
              this.barcodefontsize = innerJsonObj['barcodefontsize'];
              this.pagemargintop = innerJsonObj['pagemargintop'];
              this.pagemarginbottom = innerJsonObj['pagemarginbottom'];
              this.pagemarginleft = innerJsonObj['pagemarginleft'];
              this.pagemarginright = innerJsonObj['pagemarginright'];

              console.log(this.lablemargintop + '_' + this.labelmarginright + '_' + this.titlemargintop + '_' + this.barcodefontsize + '_' + this.titlefontsize + '_' + this.pagemargintop + '_'
                + this.pagemarginbottom + '_' + this.pagemarginleft + '_' + this.pagemarginright)
              //style="margin-top: 20px; float: right; margin-right: 50px;"
              this.rightContainer = {

                'font-family': 'Verdana, Geneva, Tahoma, sans-serif',

                'margin-right': this.labelmarginright + 'px',//50

                'margin-top': '60px',//20

              }

              this.headingBarcode = {
                'margin-top': '60px',//20
                'font-size': '45px',
              };
              this.headingTitle = {
                'margin-top': this.titlemargintop + 'px',
                'font-size': '45px',

              };
            }
          }
        })
      }
    })
  }

  printTest1() {

    es6printJS({
      printable: 'form1',
      type: 'html',
      targetStyles: ['*'],
      style: `@page { margin-top: -0.6cm;
        margin-bottom: 8cm;
        margin-left: 1.9cm;
        margin-right: 0cm;   } @media print {
          body * {
            margin: 15mm 15mm 15mm 15mm;

          }

          #section-to-print, #section-to-print * {

           visibility: visible;

          }

          #section-to-print {
            position: absolute;
            left: 0;
            top: 0;
          }

        }`,

    })


  }

  openDialogWithRef(ref: TemplateRef<any>) {
    // this.datePick = false;
    console.log("abc,cd")
    // this.printTest1()
    this.dialog.open(ref, { width: '420px', height: '300px', disableClose: true });

  }

  goBack() {
    this.router.navigate(["/viewpreparesets"])
      .then(() => {
        this.ngOnInit()
      });
  }
  hideLoader() {

  }


}
