import { Component, HostListener, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { AcademyCoursesService } from '../centers.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../../common/global';
import { StorageService } from 'app/main/module/common/service/storage.service';
import { AuthenticationService } from "../../../common/service/authentication.service";
import { ApiService } from '../../../common/service/api.service';

@Component({
    selector: 'academy-courses',
    templateUrl: './centers.component.html',
    styleUrls: ['./centers.component.scss'],
    animations: fuseAnimations
})
export class AcademyCoursesComponent implements OnInit, OnDestroy {
    categories: any[];
    dataByCatg: any[];
    courses: any[];
    coursesFilteredByCategory: any[];
    filteredCourses: any[];
    currentCategory: string;
    searchTerm: string;
    addCoursesForm: FormGroup;
    editCoursesForm: FormGroup;
    title: '';
    // length = 0;
    fileUrl = '';
    file: any;
    msg = '';
    date = new Date();
    selectedCategory: '';
    selectedCourseIdToDelete = '';
    selectedCourseObjToEdit: any;
    beforeEdit: any;
    hasFirstDataShown = true;
    titleCurrentValue = '';
    keyValuePair: any;
    holdDefaultCategeory = '';
    role = this.userService.getCookie("role")
    currentRoleData: any;
    showAction = false;

    // Private

    // memberInfo: Array<any> = [];
    memberInfo2: any;
    rcenter: any;
    display_name: any;





    /**
     * Constructor
     *
     * @param {AcademyCoursesService} _academyCoursesService
     */

    constructor(
        private _academyCoursesService: AcademyCoursesService,
        private _formBuilder: FormBuilder,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private httpClient: HttpClient,
        private global: Globals,
        // private afStorage: AngularFireStorage,
        private authService: AuthenticationService,
        private userService: StorageService,
        private apiService: ApiService,
    ) {
        // Set the defaults
        this.currentCategory = 'all';
        this.searchTerm = '';
    }
    // hot Keys

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEven(event: KeyboardEvent) {
        const key = event.key;
        if (key === 'Backspace' || key === 'Delete') {
            this.searchTerm = '';
            this.filterCoursesByTerm();
        }
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        // // console.log('centers calling')
        this.courses = [];

        // Subscribe to categories
        this.holdDefaultCategeory = this.currentCategory;

        this.CategoriesfromLabs();
        this.AllCategoriesResourceCenter();


        this.addCoursesForm = this._formBuilder.group({
            fileUrl: [''],
            title: ['', [Validators.required, , Validators.pattern(/^[A-Za-z0-9 ]/)],],
            // length: ['', [Validators.min(0), Validators.maxLength(3)],],
            categoryList: ['', [Validators.required],],
        });
        this.editCoursesForm = this._formBuilder.group({
            fileUrl: ['',],
            title: ['', [Validators.required, Validators.pattern(/^[A-Za-z0-9 ]/)],],
            // length: ['', [Validators.min(0), Validators.maxLength(3)],],
            categoryList: ['', [Validators.required],],
        });

        // user role and permission
        // this.currentRoleData = roleData;
        // if (this.role === 'admin') {
        if (this.role === 'support') {
            this.showAction = true;
        }

    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    // getAllCategoriesResourceCenter
    AllCategoriesResourceCenter(): void {
        const page = 1;
        this.hasFirstDataShown = false;
        this._academyCoursesService.getAllCategoriesResourceCenter(page).subscribe(categories => {
            // // console.log('getCategoriesfromLabs == ' + categories)
            Object.entries(categories).find(([key, value]) => {
                if (key == 'data') {
                    this.memberInfo2 = value;

                    this.courses = value;
                    // // console.log('this.courses = ' + this.courses);
                    this.hasFirstDataShown = true;

                }
                else {
                    // // console.log(key, '=', value);
                }
            })

            this.keyValuePair = this.makeKeyValuepair(this.courses);

        });
    }
    //getCategoriesfromLabs
    CategoriesfromLabs(): void {
        this._academyCoursesService.getCategoriesfromLabs().subscribe(categories => {
            // // console.log('getCategoriesfromLabs == ' + categories)
            Object.entries(categories).find(([key, value]) => {
                if (key == 'data') {
                    this.memberInfo2 = value;
                    // // console.log(this.memberInfo2)
                    this.memberInfo2.forEach(element => {
                        // // console.log(element.rcenter)
                        this.rcenter = element.rcenter
                        if (this.rcenter != undefined) {
                            this.categories = element.rcenter;
                        }
                        this.rcenter.forEach(rc => {
                            // // console.log('rc=', rc.display_name);
                        });
                    });
                }
                else {
                    // // console.log(key, '=', value);
                }
            })
            // this.categories = categories;
            this.keyValuePair = this.makeKeyValuepair(this.categories);
        });
    }

    //getcourseByIdfromResource
    courseByIdfromResource(): void {
        const page=1;
        this.hasFirstDataShown = false;
        this._academyCoursesService.getcourseByIdfromResource(this.currentCategory,page).subscribe(categories => {
            // // console.log('getCategoriesfromLabs == ' + categories)
            Object.entries(categories).find(([key, value]) => {
                if (key == 'data') {
                    // // console.log(value)
                    this.memberInfo2 = value;
                    // console.log(this.memberInfo2)
                    // // console.log(typeof (this.memberInfo2))
                    // // console.log('this.currentCategory = ' + this.currentCategory)
                    this.memberInfo2.forEach(element => {
                        // localStorage.setItem('labs',element.category_id);
                        // // console.log('element.category_id = ' + element.category_id)
                        if (element.category_id === this.currentCategory) {
                            // // console.log('this.currentCategory' + element.category_id === this.currentCategory)
                            // return element.category_id === this.currentCategory;
                            // return this.courses=element;
                        }
                        // return this.courses=element;
                    });
                    this.hasFirstDataShown = true;
                    return this.courses = this.memberInfo2;
                }
                else {
                    // console.log(key, '=', value);
                }
            })
        });
    }
    /**
     * Filter centers by category
     */
    filterCoursesByCategory(): void {
        // Filter
        // // console.log('calling filterCoursesByCategory currentCategory = ' + this.currentCategory)
        if (this.currentCategory === 'all') {
            // if (false) {
            // // console.log('this.currentCategory' + this.currentCategory)
            this.AllCategoriesResourceCenter();
            this.coursesFilteredByCategory = this.courses;
            this.filteredCourses = this.courses;
        }
        else {
            // // console.log('currentCategory' + this.currentCategory)
            // // console.log('course' + this.courses)
            this.courseByIdfromResource();
            // // console.log('course--------------- > ' + this.courses)
            this.coursesFilteredByCategory = this.courses.filter((course) => {
                // // console.log('>>>>>>>>>>>>>>>> course.category_id >>>>>>>>> ' + course.category_id);
                if (course.category_id === this.currentCategory) {
                    return course.category_id === this.currentCategory;
                }
            });
            // // console.log('coursesFilteredByCategory' + this.coursesFilteredByCategory)
            this.filteredCourses = [...this.coursesFilteredByCategory];
            // this.filteredCourses = [...this.coursesFilteredByCategory];

        }
        // Re-filter by search term
        this.filterCoursesByTerm();
    }

    /**
     * Filter centers by term
     */
    filterCoursesByTerm(): void {
        const searchTerm = this.searchTerm.toLowerCase();
        // // console.log('searchTerm >>>> ' + searchTerm);
        // Search
        if (searchTerm === '') {
            // // console.log('this.coursesFilteredByCategory in if case =  ' + this.coursesFilteredByCategory);
            this.filteredCourses = this.coursesFilteredByCategory;
            this.courses = this.filteredCourses;
        }
        else {
            // // console.log('this.coursesFilteredByCategory =  ' + this.coursesFilteredByCategory);
            if (this.coursesFilteredByCategory != undefined) {
                this.filteredCourses = this.coursesFilteredByCategory.filter((course) => {
                    return course.display_name.toLowerCase().includes(searchTerm);
                });
            } else {
                this.filteredCourses = this.courses.filter((course) => {
                    return course.display_name.toLowerCase().includes(searchTerm);
                });
            }
            this.courses = this.filteredCourses;

        }
    }
    openDialogWithRef(ref: TemplateRef<any>) {
        this.dialog.open(ref, { width: '520px', disableClose: true });
    }
    openDialogDeleteCourse(ref: TemplateRef<any>, courseId) {
        this.selectedCourseIdToDelete = courseId;
        this.dialog.open(ref, { width: '520px', disableClose: true });
    }
    openDialogEditCourse(ref: TemplateRef<any>, selectedCourseObj) {
        // // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  category_id = opendialogEdit ')
        this.selectedCourseObjToEdit = selectedCourseObj;
        this._academyCoursesService.getcourseById(selectedCourseObj.category_id).then((data: any) => {
            this.beforeEdit = data;
            this.editCoursesForm.setValue({
                // @ts-ignore
                categoryList: data.category,
                // @ts-ignore
                title: data.title,
                // @ts-ignore
                // fileUrl: data.fileUrl,
                fileUrl: '',

                // length: data.length ? data.length : 0,
                // validTillDate: this.validD,

            });

        }).catch(error => {
            this.handleError(error);

        });

        this.dialog.open(ref, { width: '520px', disableClose: true });
    }



    openDetail(row) {
        // window.open(row.fileUrl, "_blank");
        window.open(row.resource_link, "_blank");
    }
    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }

    onFileSelectEditForm(event) {
        if (event.target.files.length > 0) {
            const fileUrl = event.target.files[0];
            this.editCoursesForm.get('fileUrl').setValue(fileUrl);
        }
    }

    deleteCourse() {
        // console.log('resouce_id to be delted = ' + this.selectedCourseIdToDelete)
        this.currentCategory = this.holdDefaultCategeory;
        this.hasFirstDataShown = false;
        this._academyCoursesService.deleteCourseById(this.selectedCourseIdToDelete).subscribe(categories => {
            // this._academyCoursesService.deleteCourseById(this.selectedCourseIdToDelete).then(result => {
            this.hasFirstDataShown = true;
            const msg = 'File deleted successfully';
            this.openSnackBar(msg, 'OK');
            this.onCancelFrm();
        })
    }

    addCourses() {
        if (this.addCoursesForm.value.title.trim().length === 0) {
            alert('Please provide title!');
            return;
        }

        this.hasFirstDataShown = false;
        const dataObj: any =
        {
            title: this.addCoursesForm.value.title.trim(),
            category: this.addCoursesForm.value.categoryList,
            fileName: this.addCoursesForm.value.fileUrl.name,
            id: this.addCoursesForm.value.categoryList,
            updatedOn: new Date().getTime(),
            updated: this.getMonthlyTimestamp(),
            label: this.keyValuePair[this.addCoursesForm.value.categoryList],
        };

        // getting file data into fileData
        const fileData = this.addCoursesForm.value.fileUrl;
        var formdata = new FormData();
        formdata.append('file', this.file);

        this._academyCoursesService.addfile(dataObj, formdata).subscribe(categories => {
            Object.entries(categories).find(([key, value]) => {
                if (key == 'data') {
                    this.memberInfo2 = value;
                    // // console.log(this.memberInfo2)
                    // // console.log(typeof (this.memberInfo2))
                    this.memberInfo2.forEach(element => {
                        // // console.log(element.display_name)
                        this.display_name = element.display_name
                        this.hasFirstDataShown = true;
                        const msg = 'File added successfully';
                        this.openSnackBar(msg, 'OK');
                        return this.onCancelFrm();

                    });
                } else {
                    // // console.log(key, '=', value);
                    if (key == 'message' && value != '') {
                        if (value == 'Data Created') {
                            value = 'File added successfully';
                            this.openSnackBar(value, 'OK');
                        } else {
                            this.openSnackBar(value, 'OK');
                        }
                        this.onCancelFrm();
                        // this.addCoursesForm.reset();
                        window.location.reload();
                    }

                }
            })
            this.keyValuePair = this.makeKeyValuepair(this.categories);
        });

    }
    getMonthlyTimestamp() {
        let ms = this.date.getTime();
        var d = new Date(ms),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join(' -');

    }

    onCancelFrm() {
        this.dialog.closeAll();
        this.addCoursesForm.reset();
        this.editCoursesForm.reset();
        this.hasFirstDataShown = true;
    }

    // updateCourse() {
    //     // working on this step

    //     if (this.editCoursesForm.value.title.trim().length === 0) {
    //         alert('Please provide title!');
    //         return;
    //     }
    //     this.hasFirstDataShown = false;
    //     const dataObj: any =
    //     {
    //         title: this.editCoursesForm.value.title,
    //         category: this.editCoursesForm.value.categoryList,
    //         id: this.editCoursesForm.value.categoryList,
    //         updatedOn: new Date().getTime(),
    //         updated: this.getMonthlyTimestamp(),
    //         // length: this.editCoursesForm.value.length,
    //         label: this.keyValuePair[this.editCoursesForm.value.categoryList],

    //     };

    //     // // console.log(dataObj);  return;
    //     const fileData = this.editCoursesForm.value.fileUrl;
    //     if (fileData) {
    //         const fileRef = this.afStorage.ref(`/rcenter/${fileData.name}`);

    //         this.afStorage.upload(`/rcenter/${fileData.name}`, fileData).then(imageData => {
    //             if (imageData) {
    //                 fileRef.getDownloadURL().subscribe(url => {
    //                     if (url) {
    //                         // added url to dataObj
    //                         dataObj.fileUrl = url;
    //                         dataObj.fileName = fileData.name;
    //                         this._academyCoursesService.updateCourse(this.selectedCourseObjToEdit.doc.id, dataObj)
    //                             .then((data: any) => {
    //                                 this.hasFirstDataShown = true;
    //                                 const msg = 'File updated successfully';
    //                                 this.openSnackBar(msg, 'OK');
    //                                 this.onCancelFrm();
    //                             }).catch((error) => {
    //                                 this.handleError(error);
    //                                 this.onCancelFrm();
    //                             });
    //                     }
    //                 })
    //             }
    //         })

    //     }
    //     else {
    //         this._academyCoursesService.updateCourse(this.selectedCourseObjToEdit.doc.id, dataObj)
    //             .then((data: any) => {
    //                 this.hasFirstDataShown = true;
    //                 const msg = 'File updated successfully';
    //                 this.openSnackBar(msg, 'OK');
    //                 this.onCancelFrm();
    //             }).catch((error) => {
    //                 this.handleError(error);
    //                 this.onCancelFrm();
    //             });
    //     }
    // }


    onFileSelect(event: any) {
        if (event.target.files.length > 0) {
            // // console.log('event.target.files.length >> ' + event.target.files.length)
            const fileUrl = event.target.files[0];
            // // console.log('file >> ' + fileUrl)
            this.addCoursesForm.get('fileUrl').setValue(fileUrl);
            this.file = fileUrl;

        }
    }


    reportOnChangeVal(selectedCategory): void {
        this.selectedCategory = selectedCategory;
    }
    private handleError(error: Error): void {
        this.openSnackBar(error.message, 'OK');
        // this.notify.update(error.message, 'error');
    }

    numericOnly(event): boolean {
        const patt = /^([0-9])$/;
        const result = patt.test(event.key);
        return result;
    }

    isNullOrEmpty(event, title) {
        this.titleCurrentValue = title;
        if (event.keyCode === 32 && title.length + 1 === 1) {
            const patt = (/^\S+/);
            const result = patt.test(event.key);
            return result;
        } else {
            return event.key;
        }
    }

    makeKeyValuepair(objArray) {
        const customKeyVal = {};
        for (let single of objArray) {
            customKeyVal[single.value] = single.label;
        }
        return customKeyVal;
    }


}

