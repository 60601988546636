import { AfterViewInit, Component, ElementRef, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

declare var google: any;
import * as shape from 'd3-shape';
import { AnalyticsService } from './analytics.service';
import { urlList } from '../../../../../constant';
import { element } from 'protractor';
// import { collectionPath } from '../../../../../../functions/src/Constants';
import { map } from 'rxjs/operators';
import { Team } from '../team/team';
import { Load } from '../load/load';
import { BaseComponent } from '../../base/base.component';
import { ConfigService } from '../../common/config/config.service';
import { Globals } from '../../common/global';
import * as moment from 'moment';
import { ApiService } from '../../common/service/api.service';
import { StorageService } from 'app/main/module/common/service/storage.service';

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class HomeComponent implements OnInit {

   

    /**
     * Constructor
     *
     * @param {AnalyticsDashboardService} _analyticsDashboardService
     */
    constructor(
        public analyticService: AnalyticsService,
        // public afs: AngularFirestore,
        private configService: ConfigService,
        private globals: Globals,
        private apiService: ApiService,
        private userService: StorageService,
    ) {
        
       
    }

    ngOnInit(): void {
        // console.log("routing Home")


    }

}
