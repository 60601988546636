import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { fuseAnimations } from '@fuse/animations';
import { MatTableDataSource } from '@angular/material/table';
import { StorageService } from '../../../common/service/storage.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Cassette } from '../cassette';
import { StatusService } from '../../status/status.service';
import * as moment from 'moment';
import { CassetteListService } from './cassette-list.service';
import { ConfigService } from '../../../common/config/config.service';
import { Globals } from '../../../common/global';
// import {BaseComponent} from '../../base/base.component';
import { MatSnackBar } from "@angular/material/snack-bar";
import { BaseListComponent } from '../../../base/base-list.component';
const timeZone = require('moment-timezone');
import { Subscription } from 'rxjs';
import { CassetteInfiniteScroll } from './cassette-infinite-scroll.service';
import { ApiService } from 'app/main/module/common/service/api.service';
import { CassetteConfig } from 'app/main/module/common/config/cassetteConfig';


@Component({
    selector: 'cassette-table',
    templateUrl: 'cassette.component.html',
    styleUrls: ['cassette.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class CassetteComponent implements OnInit, OnDestroy, AfterViewInit {


    columns = this.getColumn();
    titleMatIcon = this.getTitleMatIcon();
    title = this.getTitle();
    displayedColumns = this.columns.map(c => c.columnDef);
    @ViewChild('filter', { static: true })
    filter: ElementRef;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;
    cassette: string;
    perPageData = this.globals.perPageData;
    protected extraCta = {
        visibility: false,
        text: '',
    };

    disableUnassign = true;
    // @ViewChild(MatPaginator, {static: true})
    // paginator: MatPaginator;
    isInitComplete = true;
    isLoading = false;
    isNextPressed = false;
    todayDate: Date = new Date();
    isMoreDataAvailable = true;
    prev: number;
    next: number;
    count: number;
    totalPage: any;
    allData: Array<any>;
    errorMessage: any;
    loadedcassetteData: Cassette[] = []
    cassetteConfigData: CassetteConfig[] = []
    dataSource = new MatTableDataSource<any>(this.loadedcassetteData);
    dataSourceFilters = new MatTableDataSource(this.loadedcassetteData);
    dataSourceWithPageSize = new MatTableDataSource(this.loadedcassetteData);
    page: number;
    canShowAlertIfIncubatorPending: unknown;
    assignedBy: any;
    assignedOn: any;
    status: string;
    isassigndonefinal: any;
    can_assign_if_incubator_pending: unknown;
    isLoginFailed: boolean;
    searchText: any;
    result: string;


    constructor(
        private userSevice: StorageService, private fb: FormBuilder,
        private route: ActivatedRoute, private router: Router,
        private paginationService: CassetteListService,
        private infiniteScroll: CassetteInfiniteScroll,
        private changeDetectorRefs: ChangeDetectorRef,
        private statusService: StatusService,
        public snackBar: MatSnackBar,
        private configService: ConfigService,
        private apiService: ApiService,
        private globals: Globals) {
        // super(snackBar, configService, globals);
    }
    ngAfterViewInit(): void {

    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        const lab_id = this.userSevice.getCookie("lab_id");
        if (lab_id) {
            this.isInitComplete = true;
            this.dataSource.data = [];
            this.loadedcassetteData = [];
            this.getCassetteConfig();
            this.isLoading = true;
            this.prev = 0;
            this.next = 1;
            this.page = 0;
            this.count = 1;
            this.getAllData(1);
        } else {
            this.logout();
        }
    }


    getAllData(page) {
        this.isInitComplete = true;
        this.paginationService.getAllCassetteData(page, true).subscribe(
            a => {
                console.log(" All Cassette Data ==", a)
                Object.entries(a).find(([key, value]) => {
                    if (key == 'total_pages') {
                        this.totalPage = value;
                    }
                    if (key == 'data') {
                        this.isInitComplete = false;
                        this.allData = value;
                        //  console.log(this.allData)
                        this.isassigndonefinal = '';
                        this.allData.forEach(element => {
                            //   console.log(element.is_assigned_to_department)  
                            this.isLoading = false;
                            //  this.changeDetectorRefs.markForCheck()
                            //  this.changeDetectorRefs.detectChanges()
                            const cass: Cassette = element;
                            // console.log('>>> statusBi= ' + cass.bi_status)
                            // cass.isAssignDone= element.department_assigned_by
                            if (element.department_assigned_by) {
                                this.assignedBy = element.department_assigned_by.name;
                                // this.assignedOn = this.updateDate(element.department_assigned_by.timestamp);
                                if (element.is_assigned_to_department != undefined) {
                                    cass.isAssignDone = element.is_assigned_to_department
                                } else {
                                    cass.isAssignDone = false;
                                }

                                this.isassigndonefinal = cass.isAssignDone;

                            }
                            this.result = this.getCassetteResult(element);
                            console.log("Result=", this.result);

                            if (element.set_status == "Assigned") {
                                // this.status = "Assigned Department"
                                this.status = this.getLoadStatus(element);
                                console.log(' status = ' + this.status)

                            }
                            else
                                if (element.set_status == "Used") {
                                    if (this.result == "Pending") {
                                        this.status = "Used(P)";
                                    }
                                    else {
                                        this.status = "Used";
                                    }

                                } else if (element.set_status == "Returned") {
                                    if (this.result == "Pending") {
                                        this.status = "Returned(P)";
                                    }
                                    else {
                                        this.status = "Returned";
                                    }
                                }
                                else if (element.set_status != "Used" && element.set_status != "Assigned") {
                                    this.status = this.getLoadStatus(element);
                                }
                            this.loadedcassetteData.push({
                                assigned_to_department: element.assigned_to_department,
                                barcode: element.barcode,
                                added: element.added_by,
                                startedBy: element.created_by,
                                assigned: element.assigned_by,
                                updated: element.updated_by,
                                spoiled: element.spoiled_by,
                                comment: '',
                                receiverName: '',
                                departmentName: '',
                                department: undefined,
                                print_title: element.print_title,
                                barcodeBd: element.bd_barcode,
                                barcodeBi: element.bi_barcode,
                                barcodeEi: element.ei_barcode,
                                machineName: '',
                                status: this.status,
                                bd_status: element.bd_status,
                                bi_status: element.bi_status,
                                ei_status: element.ei_status,
                                isAssignDone: element.is_assigned_to_department,

                                unassigned_by: element.unassigned_by,
                                isSpoiled: element.spoiled_by,
                                type: element.set_type,
                                version: 0,
                                cycleNo: element.cycle_number,
                                loadNo: 0,
                                program: '',
                                dateTimeNum: element.timestamp,
                                loadIdStr: '',
                                machineIdStr: '',
                                plateform: '',
                                platform: this.result,
                                verified: '',
                                createdUser: '',
                                patientId: '',
                                patientName: '',
                                cassetteID: '',
                                updatedBy: '',
                                time: '',
                                date: element.timestamp,
                                id: '',
                                docId: ''
                            })
                        });

                    }

                    else {
                        // console.log(key,'=',value);
                    }
                })
                //  console.log('table data=',this.loadData)

                this.dataSource.data = this.loadedcassetteData;
                //  console.log("datasource=",this.dataSource.data)

            },

            err => {
                this.errorMessage = err.error.message;
                console.log('unauthorised', err.status, this.errorMessage)
                if (err.status == 401) {
                    alert('Token is invalid or has expired Please relogin')
                    this.logout()
                }

            }
        );

    }
    logout(): void {
        localStorage.clear();
        this.userSevice.clearCookie();
        // this.globals.cassetteConfig = null;
        this.router.navigate(['/login']);
        location.reload();

    }

    changeDate(date): any {
        // console.log("date=",date)
        var timestamp = parseInt(date);
        return date = this.apiService.getDateStringMomentByTz(timestamp);

    }

    scrollToBottom(): void {
        if (this.isNextPressed) {
            this.isNextPressed = false;
            const table = document.querySelector('mat-table');
            table.scrollBy({ behavior: 'smooth', left: 0, top: table.scrollHeight });
        }

    }


    ngAfterViewChecked() {
        // your code to update the model
        // this.changeDetectorRefs.detectChanges();
    }
    isOrderByNegative(): boolean {
        return true;
    }

    getRowBackgroundColor(element): string {
        return '#FFFFFF';
    }

    canAssign(cassette): boolean {
        if (this.statusService.canAssign(cassette)) {
            return cassette.barcode;
        }
    }

    onNextPress() {
        this.isLoading = true;
        this.isNextPressed = true;
        this.count = this.count + 1;
        this.next = this.prev + 1;
        this.prev = this.next - 1
        this.next++
        this.prev++
        this.getAllData(this.next)
        console.log("next=", this.next, this.prev);
    }

    getColumn(): any[] {
        // const cass: Cassette = load;
        // // console.log('>>> statusBi= ' + cass.bi_status)
        // // cass.isAssignDone= element.department_assigned_by
        // if (element.department_assigned_by) {
        //     this.assignedBy = element.department_assigned_by.name;
        //     this.assignedOn = this.updateDate(element.department_assigned_by.timestamp);
        //     cass.isAssignDone = element.department_assigned_by.name
        // }
        // this.status = this.getLoadStatus(element);
        return [
            { columnDef: 'SrCassette', header: 'Sr.', cell: (element: Cassette, index: number) => `${this.page * 10 + index + 1}` },
            {
                columnDef: 'BarcodeCassette', header: 'Barcode', cell: (element: Cassette, index: number) => `${element.barcode}`,
                textColor: (element: Cassette, index: number) => `${this.getBarcodeColor(element)}`
            },
            { columnDef: 'type2', header: 'Type', cell: (element: Cassette, index: number) => `${element.type ? element.type : '-'}` },
            // { columnDef: 'Load No', header: 'Load No', cell: (element: Cassette, index: number) => `${element.loadNo}` },
            { columnDef: 'Cycle No', header: 'Cycle No', cell: (element: Cassette, index: number) => `${element.cycleNo}` },
            {
                columnDef: 'ResultCassette', header: 'Result',
                cell: (element: Cassette, index: number) => `${element.platform}`,
                textColor: (element: Cassette, index: number) => `${this.getLoadColor(element)}`
            },
            { columnDef: 'Date', header: 'Date|Time', cell: (element: Cassette, index: number) => `${this.changeDate(element.dateTimeNum)}` },
            // { columnDef: 'timeCassette', header: 'Time', cell: (element: Cassette, index: number) => `${element.time}` },
            {
                columnDef: 'BD', header: 'BD',
                cell: (element: Cassette, index: number) => `${element.barcodeBd ? element.barcodeBd : '-'}`,
                textColor: (element: Cassette, index: number) => `${this.getSingleLoadColor(element.bd_status)}`
            },
            {
                columnDef: 'BI', header: 'BI(Spore Test)',
                cell: (element: Cassette, index: number) => `${element.barcodeBi ? element.barcodeBi : '-'}`,
                textColor: (element: Cassette, index: number) => `${this.getSingleLoadColor(element.bi_status)}`
            },
            {
                columnDef: 'EI', header: 'PCD',
                cell: (element: Cassette, index: number) => `${element.barcodeEi ? element.barcodeEi : ''}`,
                textColor: (element: Cassette, index: number) => `${this.getSingleLoadColor(element.ei_status)}`
            },
            {
                columnDef: 'StatusCassette', header: 'Status',
                cell: (element: Cassette, index: number) => `${element.status}`
            },
            {
                columnDef: 'CassetteMdetails', header: 'More Details',
                cell: (element: Cassette, index: number) => this.loadStatusUsed(this.getLoadStatus(element), element.barcode)
            },
            {
                columnDef: 'actionCassette', header: 'Checkout',
                cell: (element: Cassette, index: number) => `${this.canAssign(element), element.barcode}`
            },
            // {
            //     columnDef: 'unassign', header: 'UnAssign',
            //     cell: (element: Cassette, index: number) => this.loadStatusUnassign(this.getLoadStatus(element), element.id)
            // },

        ];
    }


    getCassetteConfig() {
        this.apiService.getLabconfigdata().subscribe(a => {
            console.log('casseeteConfig', a);
            Object.entries(a).find(([key, value]) => {
                if (key == 'data') {
                    // console.log("casseeteConfig data can_assign_if_incubator_pending",value);
                    value.forEach(element => {
                        Object.entries(element.cassette).find(([key, value]) => {
                            // this.cassetteConfigData.push({ barcode: '',
                            //     implant: '',
                            //     program: '',
                            //     isReusable: true,
                            //     maxLength: 0,
                            //     minLength: 0,
                            //     canAssignIfIncubatorPending: 
                            //     canShowAlertIfIncubatorPending: boolean;
                            //     isGenericBarcode: boolean;
                            //     defaultBarcodeType: string;})

                            // console.log("cassette data  =",key , value);
                            if (key == 'can_assign_if_incubator_pending') {
                                this.canShowAlertIfIncubatorPending = value;
                                this.can_assign_if_incubator_pending = value;
                            }

                        })

                    });

                }
            })
        })
    }
    getCassetteType(element) {
        console.log("print", element, element.type, element.print_title)
        if (element.print_title != undefined && element.type != undefined) {
            if (element.type == 'Implant') {
                return 'I-' + element.print_title
            }
            else {
                return element.print_title
            }
        } else
            if (element.print_title == '' && element.type == '') {
                return '-'
            }
        //     if(element.print_title != undefined && element.type != undefined){
        //     if(element.type == 'Implant'){
        //         return 'I-' + element.print_title
        //     }
        //     else{
        //         return element.print_title
        //     }
        // }else{
        //     return '-'
        // }
    }

    getBarcodeColor(element) {
        if (element.verified == true) {
            return this.statusService.getBarcodeColor(element.barcode);
            //     console.log(element.verified)
            //     if(element.verified == true){
            //     return '##0000FF'; 
        }
        // else{
        //     return '#000000'
        // }
    }

    loadStatusAvailable(functValue, barcode) {
        if (functValue === 'Availbale') {
            return barcode;
        }
    }
    loadStatusUsed(functValue, id) {
        // if(functValue === 'USED'
        // console.log("external function=", id)
        return id;
        // }
    }
    loadStatusUnassign(functValue, id) {
        if (functValue === 'USED' || functValue === 'In Department' || functValue === 'In Department(P)') {
            return id;
        }
    }

    getLoadStatus(load: Cassette): string {
        // console.log("internal=",load)
        return this.statusService.getCassetteStatus(load);
    }

    getCassetteResult(load: Cassette): string {
        return this.statusService.getMessage(this.statusService.getStatusNew(load.bd_status, load.bi_status, load.ei_status));
    }

    getLoadColor(load: Cassette): string {
        return this.statusService.getColor(this.statusService.getStatusNew(load.bd_status, load.bi_status, load.ei_status));
    }

    getSingleLoadColor(load: string): string {
        if (load) {
            return this.statusService.getColor(this.statusService.getStatus(load));
        } else {
            return '#111111';
        }
    }

    // updateRow(row): any {
    //     row.date = moment(Math.abs(row.dateTimeNum)).format('DD-MM-YYYY');
    //     row.time = moment(Math.abs(row.dateTimeNum)).format('HH:mm');
    // }

    updateRow(row): any {
        row.date = timeZone(Math.abs(row.dateTimeNum)).tz(this.userSevice.getCookie('defaultTz')).format('DD-MM-YYYY');
        row.time = timeZone(Math.abs(row.dateTimeNum)).tz(this.userSevice.getCookie('defaultTz')).format('HH:mm');
        // row.time = moment(Math.abs(row.dateTimeNum)).format('HH:mm');
    }

    getTitleMatIcon(): string {
        return 'ballot';
    }

    getTitle(): string {
        return 'Loaded Sets';
    }

    getSearchText(): string {
        return 'Scan/Enter Barcode ';
    }

    assignCassette(barcode) {
        this.router.navigate(['/assign'], { queryParams: { barcode: barcode.trim() } });
    }
    viewCassette(barcode) {
        this.router.navigate(['/viewcassettedetails'], { queryParams: { barcode: barcode } });

    }

    // protected getPaginationService() {
    //     return this.paginationService;
    // }
    // @HostListener('document:keypress', ['$event'])
    // handleKeyboardEvent(event: KeyboardEvent) {
    //     this.handleEnterSearch(event);
    // }
    // changeCheckboxEvent(event, row){

    //     if(event.cheked === true){
    //         this.unassignList.push(row);
    //         console.log(this.unassignList);
    //     }else{
    //         this.unassignList = this.unassignList.filter(element => element !== row);
    //         console.log(this.unassignList);
    //     }
    // }


    nextPage(): void {

        // this.isLoading = true;
        // this.isNextPressed = true;
        // this.infiniteScroll.more();
    }


    ngOnDestroy(): void {
        // console.log('destroyed cassette');

        // this.subscription.unsubscribe();
        // CassetteComponent.oldData = this.dataSource.data
        // CassetteComponent.cursor = this.infiniteScroll.getCursor()
    }

    // need to uncomment and implement

    onClickSearch(searchText) {
        this.isInitComplete = true;
        this.searchText = searchText;
        this.isLoading = true;
        console.log('searchText', searchText)
        if (searchText == '') {
            window.location.reload();
        } else {
            this.loadedcassetteData = [];
            this.dataSource.data = [];
            this.allData = [];
            this.paginationService.searchCasset(searchText).subscribe(
                a => {
                    console.log(" search casset Data =", a)
                    Object.entries(a).find(([key, value]) => {
                        if (key == 'data') {
                            this.isInitComplete = false;
                            this.allData = value;
                            console.log(this.allData)
                            this.isassigndonefinal = '';
                            this.allData.forEach(element => {
                                //  console.log(element.barcode)  
                                this.isLoading = false;
                                const cass: Cassette = element;
                                if (element.department_assigned_by) {
                                    this.assignedBy = element.department_assigned_by.name;
                                    // this.assignedOn = this.updateDate(element.department_assigned_by.timestamp);
                                    cass.isAssignDone = element.department_assigned_by.name
                                    this.isassigndonefinal = cass.isAssignDone;
                                }
                                this.status = this.getLoadStatus(element);
                                console.log(' status = ' + this.status)
                                this.loadedcassetteData.push({
                                    assigned_to_department: element.assigned_to_department,
                                    barcode: element.barcode,
                                    added: undefined,
                                    startedBy: undefined,
                                    assigned: undefined,
                                    updated: undefined,
                                    spoiled: undefined,
                                    comment: '',
                                    receiverName: '',
                                    departmentName: '',
                                    department: undefined,
                                    print_title: element.print_title,
                                    barcodeBd: element.bd_barcode,
                                    barcodeBi: element.bi_barcode,
                                    barcodeEi: element.ei_barcode,
                                    machineName: '',
                                    status: this.status,
                                    bd_status: element.bd_status,
                                    bi_status: element.bi_status,
                                    ei_status: element.ei_status,
                                    isAssignDone: element.department_assigned_by,

                                    unassigned_by: element.unassigned_by,
                                    isSpoiled: element.spoiled_by,
                                    type: element.set_type,
                                    version: 0,
                                    cycleNo: element.cycle_number,
                                    loadNo: 0,
                                    program: '',
                                    dateTimeNum: element.timestamp,
                                    loadIdStr: '',
                                    machineIdStr: '',
                                    plateform: '',
                                    platform: '',
                                    verified: '',
                                    createdUser: '',
                                    patientId: '',
                                    patientName: '',
                                    cassetteID: '',
                                    updatedBy: '',
                                    time: '',
                                    date: element.timestamp,
                                    id: '',
                                    docId: ''
                                })
                            });

                        } else if (key == 'status' && value == 'failed') {
                            // else {
                            console.log(key, '=', value);
                            this.isLoading = false;
                            const msg = 'No Data Found';
                            this.openSnackBar(msg, 'OK');
                            // window.location.reload();
                        }
                    })
                    //  console.log('table data=',this.loadData)
                    this.dataSource.data = this.loadedcassetteData;
                    //  console.log("datasource=",this.dataSource.data)

                },
                err => {
                    this.errorMessage = err.error.message;
                    console.log('unauthorised', err.status, this.errorMessage)
                    this.isLoginFailed = true;
                }
            );
        }
    }

    openSnackBar(message: string, action: string): void {
        // this.isLoading = true;
        // this.hasFirstDataShown = true;
        this.snackBar.open(message, action, {
            duration: 1000,
        });
    }

    clearInputSearch() {
        this.searchText = '';
        this.ngOnInit();

    }
}
