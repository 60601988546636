import { Component, OnInit, ViewEncapsulation, Directive, HostListener, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthenticationService } from '../../common/service/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TeamService } from '../../sterilwize/team/team.service';
// import { NgxSpinnerService } from 'ngx-spinner';
import { Globals } from '../../common/global';
import { ConfigService } from '../../common/config/config.service';
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from 'app/main/module/common/service/storage.service';
import { ApiService } from '../../common/service/api.service';
import { allTrue,resourceCenter,ot } from '../../../../navigation/navigationItemObjects';
import { hasOwnProperty } from 'tslint/lib/utils';
// import {getLabCenterList} from '../../../../../../functions/src/api/center/centerList';
import { TokenStorageService } from '../../common/service/token-storage.service';
import { Session } from 'protractor';

@Component({
    selector: 'login-2',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    isShowProgress = false;
    email = '';
    infoMsg = '';
    msgColor = '';
    color = ['green', 'red', 'blue'];
    spinner = false;
    userData: any;
    credentialData: any;
    landingPage : string ='';
    navigationData: any;
    navigationType: any;
    isLoginFailed: boolean;
    roles: any;
    isLoggedIn: boolean;
    currentUser: any;
    errorMessage: any;
    labsDataId: Array<any> = [];
    userInfo: Array<any> = [];
    labsDetail: any;
    role: any;
    allConfigurationByrole: any;
    currentRoleData: any;
    can_see_twostepcheckout_screen: any;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    //
    @HostListener('contextmenu', ['$event'])
    onRightClick(event) {
        event.preventDefault();
    }



    constructor(
        private _fuseConfigService: FuseConfigService,
        private authService: AuthenticationService,
        public snackBar: MatSnackBar,
        private _formBuilder: FormBuilder,
        private router: Router,
        public teamService: TeamService,
        // private ngxSpinnerService: NgxSpinnerService,
        private globals: Globals,
        private configService: ConfigService,
        private cookieService: CookieService,
        private userService: StorageService,
        private apiService: ApiService,
        public tokenStorage: TokenStorageService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
       
    }

  

    login(): void {
        this.spinner = true;
        this.authService.login(this.loginForm.value).subscribe(
            data => {
                console.log("data = "+data);
                this.spinner = true;
                this.tokenStorage.saveToken(data.data[0].token);
                this.tokenStorage.saveUser(data.data[0]);
                this.isLoginFailed = false;
                this.isLoggedIn = true;
                this.currentUser = this.tokenStorage.getUser();
                this.userService.setCookie('token', this.currentUser.token);
                if (this.currentUser) {
                    this.spinner = false;
                    this.checkFetchedUser(this.currentUser);
                    // // console.log("currentuser calling", this.currentUser, this.currentUser.name);
                    this.userService.setCookie("name", this.currentUser.name);
                    this.userService.setCookie("userImage", 'https://www.w3schools.com/howto/img_avatar.png');
                    localStorage.setItem('name', this.currentUser.name);
                }
                else {
                    // do something else
                    // // console.log("else calling");
                }
            },
            err => {
                this.errorMessage = err.error.message;
                // console.log('unauthorised', err.status, this.errorMessage)
                //   if(err.status == 401){
                //     // // console.log("gggg"),
                //     alert('Token is invalid or has expired Please relogin')
                //     this.logout()
                //   }
                if (err.status != 200) {
                    this.spinner = false;
                    if (this.errorMessage == undefined || this.errorMessage == 'undefined') {
                        alert('Please enter valid credential')
                    }
                    else {
                        alert(this.errorMessage)
                    }
                }
                this.isLoginFailed = true;
            }
        );
    }


    logout(): void {
        localStorage.clear();
        this.userService.clearCookie();
        // this.globals.cassetteConfig = null;
        this.router.navigate(['/login']);
        // location.reload();

    }

    checkFetchedUser(result) {
      
        this.email = result.email; // setting session storage value
        // // console.log("email=", this.email)
        this.userService.setCookie("email", this.email);

        if (result) {
            
            if (this.email)
            
                this.getuserdata();

            // this.router.navigate(['/'+this.landingPage]);
        }

        else {
            // // console.log('checkResult not has value');
            this.msgColor = this.color[1];
            this.infoMsg = 'User not authorized';
            alert('User not authorized');
        }
    }


     getuserdata(){

        this.spinner = true;
      
        const token = this.userService.getCookie('token');
        // // console.log('token2=',token)
        const email = this.userService.getCookie("email");
        this.apiService.getAllLabID(token, email).subscribe(
            a => {
                // // console.log("All user ==", a)
                Object.entries(a).find(([key, value]) => {
                    // // console.log(key,value)
                    if (key == 'data') {
                        this.labsDataId = value;

                        this.labsDataId.forEach(element => {
                            localStorage.setItem('labs', element.labs.length);
                            // // console.log('labs=', element.labs.length)
                            if (element.labs.length != 0) {


                                element.labs.forEach(userlab => {
                                    // // console.log('info=', userlab);
                                    if (userlab.default_lab == true) {
                                        // console.log('center', userlab.center)
                                        localStorage.setItem('lab_id', userlab.lab_id);
                                        this.userService.setCookie("lab_id", userlab.lab_id);
                                        localStorage.setItem('center', userlab.center);
                                        this.userService.setCookie("center", userlab.center);
                                        localStorage.setItem('role', userlab.role);
                                        this.userService.setCookie("role", userlab.role);
                                        this.userService.setCookie("name", element.name);
                                        this.userService.setCookie("userImage", 'https://www.w3schools.com/howto/img_avatar.png');
                                        localStorage.setItem('name', element.name);
                                        // this.navigationType = 'all';
                                        // // console.log("navigation type=", this.navigationType);
                                        


                                        
                                        // // console.log("pageDynamicConfig", JSON.stringify(this.navigationData))
                                        // this.router.navigate(['/' + this.landingPage]);
                                        this.getTimeZone();

                                    }

                                });
                            }

                            else {

                                this.spinner = false;
                                window.alert("No Lab assigned, Please contact your Administrator for lab assign")
                            }

                        });
                    }
                    else {
                        // // console.log(key,'=',value);
                    }
                })
            },

            err => {

                this.errorMessage = err.error.message;
                // console.log('unauthorised', err.status, this.errorMessage)
                if (err.status == 401) {
                   
                        alert('Token is invalid or has expired Please relogin')
                    this.logout()
                }
                this.isLoginFailed = true;

            }

        );
    }

    getROleconfiguration(loginUserRole) {
        this.navigationData = '';
        this.navigationType = '';
        
        this.apiService.getAllRoleByName(loginUserRole).subscribe(
            a => {
                Object.entries(a).find(([key, value]) => {
                    if (key == 'data') {
                        this.allConfigurationByrole = value;
                        this.allConfigurationByrole.forEach(element => {
                            this.currentRoleData = element.access_specifier;
                            for (const [key, value] of Object.entries(this.currentRoleData)) {
                                this.can_see_twostepcheckout_screen = this.currentRoleData.can_see_twostepcheckout_screen;
                               console.log("this.role  "+this.role)
                               console.log("this.can_see_twostepcheckout_screen  "+this.can_see_twostepcheckout_screen)

                                if(this.role == "doctor"){
                                    this.navigationType = "ot";
        
                                } 
                                else if(this.can_see_twostepcheckout_screen == true){
                               console.log("this.can_see_twostepcheckout_screen  "+this.can_see_twostepcheckout_screen)

                                    this.navigationType = "all";
                                }
                                else{
                                    this.navigationType = "usernav";
                                }
                                    switch (this.navigationType) {
                                        case 'rcenter':
                                            this.navigationData = resourceCenter;
                                            this.landingPage = 'rcenter/centers';
                                            break;
                                        case 'all':
                                            this.navigationData = allTrue;
                                            this.landingPage = 'dashboard';
                                            break;
                                        // case 'usernav':
                                        //     this.navigationData = userTrue;
                                        //     this.landingPage = 'dashboard';
                                        //     break;    
                                        case 'ot':
                                            this.navigationData = ot;
                                            this.landingPage = '/cassette/patientcheckoutList';
                                         break;    
                                        default:
                                            this.navigationData = allTrue;
                                            this.landingPage = 'dashboard';                                 
                                    }
                                 
                                    // console.log("landing page=", this.landingPage)
                                    this.userService.setCookie('navigationType', this.navigationType);
                                    this.userService.setCookie('pageDynamicConfig', JSON.stringify(this.navigationData));
                                    
                                    this.router.navigate(['/' + this.landingPage]);
                            }
                        });

                        // // console.log('All Role config', this.can_add_team_member, this.can_edit_team_member, this.can_select_as_role);
                    }
                    else {

                    }
                })
            },

            err => {
                this.errorMessage = err.error.message;
                // console.log('unauthorised', err.status, this.errorMessage)
                if (err.status == 401) {
                    // // console.log("gggg"),
                        alert('Token is invalid or has expired Please relogin')
                    this.logout()
                }
            }
        );

    }


    getTimeZone() {
        // console.log("calling")
        const token = this.userService.getCookie('token');
        const labId = this.userService.getCookie("lab_id")
        this.apiService.getLabIdDetail(token, labId).subscribe(
            a => {
                //  // console.log("All Lab Data ==", a)
                Object.entries(a).find(([key, value]) => {
                    if (key == 'data') {
                        this.labsDetail = value;
                        //  // console.log('',this.labsDetail)
                        this.labsDetail.forEach(element => {
                            // // console.log(" default_time_zone =", element, element.default_time_zone);
                            this.userService.setCookie('defaultTz', element.default_time_zone);
                            this.userService.setCookie('lab_name', element.lab_name);
                            this.userService.setCookie('lab_logo', element.lab_logo);
                            this.userService.setCookie('multi_center', element.multi_center);
                            this.role = localStorage.getItem('role');
                        //    // console.log("th",this.navigationType,this.role)
                        this.getROleconfiguration(this.role);
                           
                        });


                        // });
                    }
                    else {
                        // // console.log(key,'=',value);
                    }
                })
            },

            err => {

                this.errorMessage = err.error.message;
                // console.log('unauthorised', err.status, this.errorMessage)
                if (err.status == 401) {
                    // // console.log("gggg"),
                        alert('Token is invalid or has expired Please relogin')
                    this.logout()
                }
                this.isLoginFailed = true;

            }
        )

    }
    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }

      
}
