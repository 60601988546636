import { Component, OnInit } from '@angular/core';
import { ApiService } from 'app/main/module/common/service/api.service';
import * as Chart from 'chart.js';


@Component({
  selector: 'app-loadchartdashboard',
  templateUrl: './loadchartdashboard.component.html',
  styleUrls: ['./loadchartdashboard.component.scss']
})
export class LoadchartdashboardComponent implements OnInit {
  errorMessage: any;
  chart: Chart;
  teamData: any;
  patientData: any;
  labelData: any;
  loadData: any;
  ei: any;
  bi: any;
  bdar: unknown;
  constructor(private apiService:ApiService) { }

  ngOnInit(): void {
    this.getTotalTeamMember();
    this.getTotalLoadData("year");
    this.getTotalPatientData();
    this.getTotalLableData();

  }



  getTotalTeamMember() {
      
    this.apiService.getTeamMemberOnDashboard().subscribe(
        a => {
            //  // console.log("Total team Data ==", a)
            Object.entries(a).find(([key, value]) => {
                if (key == 'data') {
                  // // console.log("Value =",value[0].team);
                  this.teamData = value[0].team;
                }
                else {
                   
                }
            })
        },

        err => {

            this.errorMessage = err.error.message;
            // console.log('unauthorised', err.status, err.message)
            if (err.status == 401) {
                    alert('Token is invalid or has expired Please relogin')
            }

        }
    )

}


getTotalLoadData(ctx) {
      
  this.apiService.getLoadOnDashboard(ctx).subscribe(
      a => {
          //  // console.log("Total team Data ==", a)
          Object.entries(a).find(([key, value]) => {
              if (key == 'data') {
                this.loadData = value;
                this.loadData.forEach(element => {
                        // // console.log(element.EI)
                        this.ei = element.EI;
                        this.bi= element.BI;
                        
                        Object.entries(element).find(([key,value])=>{
                          if(key == "BD/AR"){
                            this.bdar = value;
                          }

                          //  this.allKey = key ;
                          //  this.allValue =value;
                          // // console.log(this.allKey,this.allValue)
                          
                           
                         
                        })
                       
                      
                         
                      });
              }
              else {
                 
              }
          })
      },

      err => {

          this.errorMessage = err.error.message;
          // console.log('unauthorised', err.status, err.message)
          if (err.status == 401) {
                  alert('Token is invalid or has expired Please relogin')
          }

      }
  )

}


getTotalPatientData() {
      
  this.apiService.getPatientOnDashboard().subscribe(
      a => {
          //  // console.log("Total team Data ==", a)
          Object.entries(a).find(([key, value]) => {
              if (key == 'data') {
                // // console.log("Patient =",value[0]);
                this.patientData = value[0].count;
              }
              else {
                 
              }
          })
      },

      err => {

          this.errorMessage = err.error.message;
          // console.log('unauthorised', err.status, err.message)
          if (err.status == 401) {
                  alert('Token is invalid or has expired Please relogin')
          }

      }
  )

}

getTotalLableData() {
      
  this.apiService.getLabelOnDashboard().subscribe(
      a => {
          console.log("Total team Data ==", a)
          Object.entries(a).find(([key, value]) => {
              if (key == 'data') {
                // // console.log("Label =",value[0]);
                this.labelData = value[0].total_count;
              }
              else {
                 
              }
          })
      },

      err => {

          this.errorMessage = err.error.message;
          // console.log('unauthorised', err.status, err.message)
          if (err.status == 401) {
                  alert('Token is invalid or has expired Please relogin')
          }

      }
  )

}



createChart(ei,bdar,bi){

  this.chart = new Chart("MyChart", {
    type: 'doughnut', //this denotes tha type of chart

    data: {// values on X-Axis
      labels: ['EI','BD/AR','BI', ],
       datasets: [{
  label: 'My First Dataset',
  data: [ei,bdar , bi],
  backgroundColor: [
    'red-600',
    'orange-100',
    'blue-600',			
  ],
  // hoverOffset: 4
}],
    },
    options: {
      aspectRatio:5.5
    }

  });
}

}
