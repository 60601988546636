import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import {
    debounceTime,
    finalize,
    map,
    startWith,
    switchMap,
    tap,
} from "rxjs/operators";

import { MatSort } from "@angular/material/sort";
import { SelectionModel } from "@angular/cdk/collections";
import { Observable, Subject } from "rxjs";

import { fuseAnimations } from "@fuse/animations";
import { MatTableDataSource } from "@angular/material/table";
import { StorageService } from "../../../common/service/storage.service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AssignService } from "./assign.service";
import { HttpClient } from "@angular/common/http";
// import { AngularFirestore } from "@angular/fire/firestore";
import { PatientService } from "../../patient/patient.service";
import { Patient } from "../../patient/patient";
import { Cassette } from "../cassette";
import { StatusService } from "../../status/status.service";
import * as moment from "moment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DialogUtil } from "../../../../util/DialogUtil";
import { MatDialog } from "@angular/material/dialog";
import * as copy from "copy-to-clipboard";
import { BaseComponent } from "../../../base/base.component";
import { ConfigService } from "../../../common/config/config.service";
import { Globals } from "../../../common/global";
import { ApiService } from "../../../common/service/api.service";
import { TextUtils } from "../../../../util/TextUtils";
import { CookieService } from "ngx-cookie-service";
import { CassetteListService } from "../list/cassette-list.service";
import { time } from "@ngtools/webpack/src/benchmark";
const timeZone = require("moment-timezone");

export interface State {
    flag: string;
    name: string;
    population: string;
}

@Component({
    selector: "assign-table",
    templateUrl: "./assign.component.html",
    styleUrls: ["./assign.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class AssignComponent
    extends BaseComponent
    implements OnInit, OnDestroy {
    // regexStr = '^[a-zA-Z \-\']+';
    // @Input() isAlphaNumeric: boolean;



    // @HostListener('keypress', ['$event']) onKeyPress(event) {
    //   return new RegExp(this.regexStr).test(event.key);
    // }

    // @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    //   this.validateFields(event);
    // }

    // validateFields(event) {
    //   setTimeout(() => {

    //     this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^A-Za-z ]/g, '').replace(/\s/g, '');
    //     event.preventDefault();

    //   }, 100)
    // }
    ELEMENT_DATA: Cassette[] = [];
    searchMoviesCtrl = new FormControl();
    filteredMovies: any;
    selectedPatient: string;
    dataSource = new MatTableDataSource<Cassette>();
    selection = new SelectionModel<Cassette>(true, []);
    departmentList: Array<any> = [];
    getStatus: any;
    getError: any;
    assigncassetteData: Cassette[] = []
    stateCtrl = new FormControl();
    filteredStates: Observable<State[]>;
    // filteredPatients: Observable<Patient[]>;

    filteredPatients: Array<any> = [];
    // departmentArray: Array<any> = [];
    urlData = "";
    HYPHEN = "-";
    // HASH = "#";
    enableAssign = false;
    settingFormOne: FormGroup;
    department_name: any;
    recived_name: any;

    states: State[] = [
        {
            name: "Arkansas",
            population: "2.978M",
            // https://commons.wikimedia.org/wiki/File:Flag_of_Arkansas.svg
            flag: "https://upload.wikimedia.org/wikipedia/commons/9/9d/Flag_of_Arkansas.svg",
        },
        {
            name: "California",
            population: "39.14M",
            // https://commons.wikimedia.org/wiki/File:Flag_of_California.svg
            flag: "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_California.svg",
        },
    ];
    columns = [
        {
            columnDef: "Sr",
            header: "Sr.",
            cell: (element: Cassette, index: number) =>
                `${this.page * 10 + index + 1}`,
        },
        {
            columnDef: "Barcode",
            header: "Barcode",
            cell: (element: Cassette, index: number) => `${element.barcode}`,
        },
        {
            columnDef: "type2",
            header: "Type",
            cell: (element: Cassette, index: number) => `${element.type}`,
        },
        {
            columnDef: "Load No",
            header: "Load No",
            cell: (element: Cassette, index: number) => `${element.loadNo}`,
        },
        {
            columnDef: "Cycle No",
            header: "Cycle No",
            cell: (element: Cassette, index: number) => `${element.cycleNo}`,
        },
        {
            columnDef: "Result",
            header: "Result",
            cell: (element: Cassette, index: number) =>
                `${element.platform}`,
            textColor: (element: Cassette, index: number) =>
                `${this.getLoadColor(element)}`,
        },
        {
            columnDef: 'Date',
            header: 'Date|Time',
            cell: (element: Cassette, index: number) => `${this.changeDate(element.dateTimeNum)}`
        },
        // {
        //     columnDef: "Time",
        //     header: "Time",
        //     cell: (element: Cassette, index: number) => `${element.time}`,
        // },

        {
            columnDef: "BD",
            header: "BD",
            cell: (element: Cassette, index: number) =>
                `${element.barcodeBd ? element.barcodeBd : ""}`,
            textColor: (element: Cassette, index: number) =>
                `${this.getSingleLoadColor(element.bd_status)}`,
        },
        {
            columnDef: "BI",
            header: "BI(Spore Test)",
            cell: (element: Cassette, index: number) =>
                `${element.barcodeBi ? element.barcodeBi : ""}`,
            textColor: (element: Cassette, index: number) =>
                `${this.getSingleLoadColor(element.bi_status)}`,
        },
        {
            columnDef: "EI",
            header: "PCD",
            cell: (element: Cassette, index: number) =>
                `${element.barcodeEi ? element.barcodeEi : ""}`,
            textColor: (element: Cassette, index: number) =>
                `${this.getSingleLoadColor(element.ei_status)}`,
        },

        {
            columnDef: "Status",
            header: "Status",
            cell: (element: Cassette, index: number) =>
                `${element.status}`,
        },
        // `${this.page * 10 + index + 1}`
        {
            columnDef: "Remove",
            header: "Remove",
            cell: (element: Cassette, index: number) =>
                `${this.page * 10 + index + 1}`,
        },
    ];
    displayedColumns = this.columns.map((c) => c.columnDef);

    // @ViewChild(MatPaginator, {static: true})
    // paginator: MatPaginator;
    @ViewChild("input", { static: true }) input: any;

    @ViewChild("filter", { static: true })
    filter: ElementRef;
    isLoading = false;
    errorMsg: string;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    page = 0;

    patient: Patient;
    barcode: string;
    searchText: string;
    map = new Map<string, Cassette>();
    isEnterPressed = false;
    isAssignedPressed = false;
    platform = "web";
    settingDto: any;
    selected: any;
    enablePatientEmail = "";
    enableLabCheckoutEmail = "";

    // Private
    private _unsubscribeAll: Subject<any>;
    itemsObservable: Observable<Cassette[]>;
    listLength: number = 0;
    currentDepartment: string;
    receiverName: string;
    result: string;
    status: string;
    hasFirstDataShown: boolean = false;
    can_assign_if_incubator_pending: unknown;
    role: string;

    constructor(
        private formBuilder: FormBuilder,
        public apiservice: ApiService,
        private userService: StorageService,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private paginationService: AssignService,
        private patientService: PatientService,
        private changeDetectorRefs: ChangeDetectorRef,
        private http: HttpClient,
        private snackBar: MatSnackBar,
        private statusService: StatusService,
        // private afs: AngularFirestore,
        public dialog: MatDialog,
        private el: ElementRef,
        private configService: ConfigService,
        private globals: Globals,
        private apiService: ApiService,
        private cookieService: CookieService,
        public cassetteListService: CassetteListService
    ) {
        super(configService, globals);
        // Set the private defaults
        console.log(' call constructer ')

        this._unsubscribeAll = new Subject();
        this.filteredStates = this.stateCtrl.valueChanges.pipe(
            startWith(""),
            map((state) =>
                state ? this._filterStates(state) : this.states.slice()
            )
        );
        console.log(' call constructer 1 ')
        // this.getDocuments();

        // this.filteredPatients = this.searchMoviesCtrl.valueChanges.pipe(
        //     startWith(""),
        //     debounceTime(500),
        //     tap(() => {
        //         this.errorMsg = "";
        //         this.filteredMovies = [];
        //         this.isLoading = true;
        //     }),
        //     switchMap((value) =>
        //         this.patientService
        //             .getPatient(
        //                 TextUtils.removeSpace(value).replace(
        //                     /[^a-zA-Z0-9]/g,
        //                     ""
        //                 )
        //             )
        //             .pipe(
        //                 finalize(() => {
        //                     // console.log('value2',value);
        //                     this.isLoading = false;
        //                 })
        //             )
        //     )
        // );
        // console.log(' call constructer filteredPatients '+this.filteredPatients)

    }

    keyPressAlphanumeric(event) {

        var inp = String.fromCharCode(event.keyCode);

        if (/[a-zA-Z \-\']/.test(inp)) {
            return true;
        } else {
            event.preventDefault();
            return false;
        }
    }

    onclickgetDocuments() {
        const searchKeywrd = this.selectedPatient.valueOf();
        console.log('searchKeywrd = ' + searchKeywrd)
        // this.filteredPatients = this.apiservice.getDocumentsApi().subscribe({
        this.apiservice.getDocumentsApi().subscribe({
            next: data => {
                // console.log(' getDocuments >>> data' + data)
                data['data'].forEach(element => {
                    // this.departmentList.push(element.display_name)
                    if (searchKeywrd.substring(0).includes(element.display_name.substring(0))) {
                        // console.log(' element.display_name >>> ' + element.display_name)
                        var obj = {
                            "id": element._id,
                            "firstName": element.display_name,
                            "lastName": element.name
                        }
                        // this.departmentArray.push(obj)
                        this.filteredPatients.push(obj)
                    } else {
                        this.openSnackBar("Department Name Not Found.", "OK");
                    }
                    // this.filteredPatients = element.display_name
                    console.log(' filteredPatients >>> ' + this.filteredPatients)
                });

            },
            error: HttpErrorResponse => {
                this.getStatus = HttpErrorResponse.error.status;
                this.getError = HttpErrorResponse.error.error;
            }
        });
        return this.filteredPatients;
    }


    private _filterStates(value: string): State[] {
        const filterValue = value.toLowerCase();
        return this.states.filter(
            (state) => state.name.toLowerCase().indexOf(filterValue) === 0
        );
    }

    @HostListener("document:keydown", ["$event"])
    handleKeyboardEven(event: KeyboardEvent) {
        const key = event.key;
        if (key === "Backspace" || key === "Delete") {
            // this.selectedPatient = null;
            // this.filteredPatients=[];

        }
    }

    @HostListener("document:keypress", ["$event"])
    handleKeyboardEvent(event: KeyboardEvent) {
        const key = event.key;
        if (
            event.key.includes("Enter") &&
            event.code.includes("Enter") &&
            document.activeElement.id.includes("Barcode")
        ) {
            if (this.isAssignedPressed) {
                return;
            }
            this.isEnterPressed = true;
            if (this.barcode.length > 0) {
                this.selectBarcode();
            }
        } else {
            this.isEnterPressed = false;
        }
    }

    // added for clear input and refresh data
    clearInputSearch() {
        this.barcode = "";
    }

    changeDate(date): any {
        // console.log("date=",date)
        var timestamp = parseInt(date);
        return date = this.apiService.getDateStringMomentByTz(timestamp);

    }
    // clearInputRecived() {
    //     this.recived_name = "";
    // }
    clearInputSearchPatient() {
        this.selectedPatient = "";
        this.filteredPatients = [];
    }

    ngOnInit(): void {
        const lab_id = this.userService.getCookie("lab_id");
        if (lab_id) {
            this.hasFirstDataShown = true;
            this.assigncassetteData = []
            this.dataSource.data = []
            this.listLength = 0;
            this.role = localStorage.getItem('role');
            this.getCassetteConfig();
            this.getDocuments();
            this.settingFormOne = this.formBuilder.group({
                departmentListName: [''],
                receiverName: ['', Validators.pattern('^[a-zA-Z \-\']+')]
            });


            this.input.nativeElement.focus();
            console.log(this.globals.getLocalTimezoneName());
            this.urlData = this.route.snapshot.queryParams.barcode;
            if (this.urlData !== "" && this.urlData !== undefined) {
                this.barcode = this.urlData;
                this.selectBarcode();
            }

        } else {
            this.logout();
        }
    }

    ngAfterViewInit(): void {
        // this.barcode = this.urlData;
    }

    logout(): void {
        localStorage.clear();
        this.userService.clearCookie();
        this.router.navigate(['/login']);
    }

    onClickAssign(): void {
        this.hasFirstDataShown = false;
        const getTemplateList = this.settingFormOne.value;
        console.log('getTemplate list', getTemplateList);

        this.department_name = this.settingFormOne.get('departmentListName')?.value;
        this.recived_name = this.settingFormOne.get('receiverName')?.value;

        console.log(this.department_name + ' _ ' + this.recived_name);


        this.submit();
        // if (this.dataSource.data.length) {
        //     const dialogUtil = new DialogUtil();

        // const actualPatientId = this.selectedPatient.substr(
        //     0,
        //     this.selectedPatient.indexOf(this.HASH)
        // );
        // let customMsg = "";
        // console.log('actualPatientId = '+actualPatientId)
        //             this.patientService
        //                 .getDepartmentNameById(this.department_name.toUpperCase())
        //                 .then((data: any) => {
        //                     console.log(data);
        //                     if (data.deleted == true) {
        //                         this.showAlert(
        //                             "This patient is deleted you will not assign any cassette to this patient "
        //                         );
        //                     } else {
        //                         this.patientService
        //                             .getPatientLastCovidResultForAssignCassette(
        //                                 this.department_name.toUpperCase()
        //                             )
        //                             .then((data: any) => {
        //                                 if (data && data[0].inOffice !== true) {
        //                                     customMsg = `In Office assessment is not completed for this patient. Are you sure to assign`;
        //                                 } else {
        //                                     customMsg = "Are you sure to assign ";
        //                                 }
        // //////
        //                                 dialogUtil.openConfirmationDialog(
        //                                     this.dialog,
        //                                     customMsg +
        //                                     " " +
        //                                     this.dataSource.data.length +
        //                                     " cassette(s)?",
        //                                     {
        //                                         onConfirm: () => {
        //                                             this.submit();
        //                                             // this.paginationService.shareBarcodeLinkUrl(localStorage.getItem('lab'), [], "this.patient.id"
        //                                             // ).subscribe(dataResult => {
        //                                             //     const message = 'Mail has been sent!';
        //                                             //     this.openSnackBar(message, 'OK');
        //                                             // });
        //                                         },
        //                                         onCancel: () => { },
        //                                         onAlways: () => { },
        //                                     }
        //                                 );
        //                             });
        //                     }
        //                 });
        //    }
        //  else {
        //             alert("There is no cassette for assignment");
        //         }
    }

    submit(): void {
        const list: string[] = [];
        //department name, reciver name, comment

        this.selection.selected.forEach((element) => {
            console.log('elementbarcode = ' + element.barcode)
            if (list.indexOf(element.barcode) < 0) {
                list.push(element.barcode);
                console.log("list =", list)
            }
        });
        this.assign();
        // if (list.length < 1) {
        //     this.openSnackBar("Please Select at least one cassette(s)", "OK");
        //     return;
        // }

        // let patientRealId = this.selectedPatient.substr(
        //     0,
        //     this.selectedPatient.indexOf(this.HASH)//departmant name
        // );
        // patientRealId = patientRealId.trim();

        // const patientIdHyph = this.selectedPatient
        //     .replace(new RegExp(this.HASH, "g"), "")
        //     .toLowerCase();

        // // removes spaces in between name and id, if exist
        // const patientId = patientIdHyph.replace(new RegExp(" ", "g"), "");

        // const subs1 = this.paginationService
        //     .getPatientObserable(patientRealId)
        //     .subscribe(
        //         (patients) => {
        //             if (patients && patients.length > 0) {
        //                 
        //                 this.patient = patients[0];
        //                 this.assign();
        //             } else {
        //                 this.showAlert("No patient found for " + patientRealId);
        //                 // this.openSnackBar('No Patient found for' + patientId, 'OK');
        //             }
        //             subs1.unsubscribe();
        //         },
        //         (error) => {
        //             this.openSnackBar("error come" + error, "OK");
        //         },
        //         () => {
        //             //dp something
        //         }
        //     );
    }

    getCassetteConfig() {
        this.apiService.getLabconfigdata().subscribe(a => {
            console.log('casseeteConfig', a);
            Object.entries(a).find(([key, value]) => {
                if (key == 'data') {
                    value.forEach(element => {
                        Object.entries(element.cassette).find(([key, value]) => {
                            console.log("cassette data  =", key, value);
                            if (key == 'can_assign_if_incubator_pending') {
                                this.can_assign_if_incubator_pending = value;
                                console.log("can_assign_if_incubator_pending", this.can_assign_if_incubator_pending)
                            }

                        })

                    });

                }
            })
        })
    }

    getDocuments() {

        this.apiservice.getDocumentsApi().subscribe({
            next: data => {
                console.log('data ' + data)
                data['data'].forEach(element => {
                    this.departmentList.push(element.display_name)

                });

            },
            error: HttpErrorResponse => {
                this.getStatus = HttpErrorResponse.error.status;
                console.log("status=", this.getStatus)
                this.getError = HttpErrorResponse.error.error;
                console.log("error=", this.getError);
            }
        });
    }


    selectBarcode(): void {
        console.log('selectbarcode==', this.barcode)
        if (this.barcode === "" || this.barcode === undefined) {
            alert("Please enter barcode first");
            return;
        }
        const value = this.barcode.replace(/\s/g, "").toUpperCase();
        console.log('value==', value)

        this.barcode = "";
        let isCassetteAlready = false;
        this.dataSource.data.forEach((cassette) => {
            // console.log('cassette==', cassette)
            // console.log('cassette.barcode==', cassette.barcode)
            if (cassette.barcode.includes(value)) {
                isCassetteAlready = true;
            }
        });
        if (isCassetteAlready) {
            alert(" This sets has been already added " + value);
            return;
        } else {
        }

        this.paginationService
            .getCassetteEntryObserable(value)
            .subscribe(
                (a) => {
                    // console.log('assign cas data=',a)
                    Object.entries(a).find(([key, value]) => {
                        if (key == 'data') {

                            // console.log("key data ==",key,value)
                            if (value && value.length > 0) {
                                // console.log("value==",value)

                                const cass: Cassette = value;
                                // console.log("dataghgfh=",cass)
                                // this.updateRow(cass);
                                this.enableAssign = true;

                                const eStatus: CassetteStatus =
                                    this.statusService.getCassetteStatus(cass);
                                if (this.statusService.canAssign(cass)) {
                                    // console.log('external If',eStatus,CassetteStatus.IN_PROGRESS_BI_PENDING)
                                    if (
                                        eStatus ===
                                        CassetteStatus.IN_PROGRESS_BI_PENDING
                                    ) {
                                        // console.log('internal If')
                                        const dialogUtil = new DialogUtil();
                                        dialogUtil.openConfirmationDialog(
                                            this.dialog,
                                            "Incubator Result for the cassette is pending. Do you still want to assign this cassette?",
                                            {
                                                onConfirm: () => {
                                                    this.addCassetteDirect(cass);
                                                },
                                                onCancel: () => {
                                                    this.enableAssign = false;
                                                },
                                                onAlways: () => { },
                                            }
                                        );
                                    } else {
                                        // console.log('internal else')
                                        this.addCassetteDirect(cass);
                                    }
                                } else if (eStatus === CassetteStatus.USED) {
                                    console.log('external else')
                                    this.enableAssign = false;
                                    alert("Sets is already assigned: " + value);
                                } else {
                                    alert(
                                        "Set current status is " +
                                        eStatus.valueOf()
                                    );
                                }
                            }
                            else {
                                alert("Set(s) not found " + value);
                            }



                        }

                    })

                    // subs1.unsubscribe();
                },


                (error) => {
                    //dp something
                },
                () => {
                    //dp something
                }
            );
        this.barcode = "";
    }

    addCassetteDirect(cass) {
        console.log('add cass =', cass)
        cass.forEach(element => {
            cass.isAssignDone = element.is_assigned_to_department
            this.result = this.getCassetteResult(element);
            console.log("Result=", this.result, element.set_status);
            if (element.set_status == 'Used') {
                if (this.result == "Pending") {
                    this.status = "Used(P)";
                }
                else {
                    this.status = "Used";
                }
            }

            if (element.set_status != 'Assigned' && element.set_status != 'Used') {

                this.status = this.getLoadStatus(element);
                console.log("status else=", this.status);

            }

            if (element.set_status == 'Assigned') {
                if (this.result == "Pending") {
                    this.status = "In Department(P)";
                }
                else {
                    this.status = "In Department";
                }
            }
            if ((this.status == "Available" || this.status == "In Progress(P)") && element.attached == true) {

                if (!this.can_assign_if_incubator_pending && this.status == "In Progress(P)") {
                    alert(element.barcode + " - Set Current Status is " + this.status + " . Please Do Not use this instrument on the patient  ");
                    return;
                } else {
                    this.hasFirstDataShown = true;
                    this.assigncassetteData.push({
                        assigned_to_department: element.assigned_to_department,
                        barcode: element.barcode,
                        added: undefined,
                        startedBy: undefined,
                        assigned: undefined,
                        updated: undefined,
                        spoiled: undefined,
                        comment: '',
                        receiverName: '',
                        departmentName: '',
                        department: undefined,
                        print_title: element.print_title,
                        barcodeBd: element.bd_barcode,
                        barcodeBi: element.bi_barcode,
                        barcodeEi: element.ei_barcode,
                        machineName: '',
                        status: this.status,
                        bd_status: element.bd_status,
                        bi_status: element.bi_status,
                        ei_status: element.ei_status,
                        isAssignDone: element.is_assigned_to_department,
                        unassigned_by: element.unassigned_by,
                        isSpoiled: element.spoiled_by,
                        type: element.set_type,
                        version: 0,
                        cycleNo: element.cycle_number,
                        loadNo: element.load_number,
                        program: '',
                        dateTimeNum: element.timestamp,
                        loadIdStr: '',
                        machineIdStr: '',
                        plateform: '',
                        platform: this.result,
                        verified: '',
                        createdUser: '',
                        patientId: '',
                        patientName: '',
                        cassetteID: '',
                        updatedBy: '',
                        time: '',
                        date: element.timestamp,
                        id: '',
                        docId: ''
                    })
                }
            } else {

                window.alert("Set(s) current status is " + this.status + "  you can not add " + element.barcode + " set, untill set status is Available or In Progress(P)")
            }

        });


        this.dataSource.data = this.assigncassetteData;
        // console.log('ddf=',this.dataSource.data)

        this.listLength = this.dataSource.data.length
        // console.log("dataSource Length=",this.dataSource.data.length,this.dataSource.data)
        this.dataSource.filter = "";
        this.selection.toggle(cass);
        console.log('selection', this.selection.selected)
    }

    assign(): void {
        this.isAssignedPressed = true;
        const time = new Date().getTime();
        const barcodes = [];
        this.assigncassetteData.forEach((element) => {
            // console.log("databarcodes===",element) 
            barcodes.push(element.barcode);
            console.log('barcodes====', barcodes)


        });


        let i = 0
        for (i of barcodes) {
            console.log('ooi', i)

            const updateData = {

                barcode: i,
                update_data: {
                    set_status: 'Assigned',
                    assigned_to_department: this.department_name,
                    timestamp: time.toString(),
                    comment_by_cssd: '',
                    is_assigned_to_department: true,
                    department_assigned_by: {
                        id: this.userService.getCookie('email'),
                        name: this.userService.getCookie('name'),
                        timestamp: time.toString()
                    },
                    received_by: {
                        department: this.department_name,
                        name: this.recived_name,
                        timestamp: time.toString()


                    }
                }
            }
            this.paginationService.assignSets(updateData).subscribe(a => {
                console.log('assigned data', a)
                // if (a) {
                //     this.showAlert("Set(s) In Department successfully");
                this.barcode = '';
                // }
            })

        }
        this.selection.clear();
        this.selectedPatient = "";
        while (this.dataSource.data.length > 0) {
            this.dataSource.data.pop();
            this.assigncassetteData = []
            this.listLength = 0;
            this.currentDepartment = '';
            this.receiverName = ''

        }
        this.dataSource.filter = "";

        this.isAssignedPressed = false;
        this.hasFirstDataShown = true;

        // this.ngOnInit();  
        // }
        // })

        this.openSnackBar("Set(s) assigned to department successfully.", "OK");


        this.settingFormOne.reset();
        this.router.navigate(['/cassette']);
        // check for config

        //     this.apiService
        //         .shareBarcodeLinkUrl(
        //             this.userService.getCookie("lab"),
        //             barcodes,
        //             //  this.departmentList.name,
        //             this.userService.getCookie("center")
        //         )
        //         .then((dataResult) => {
        //             if (
        //                 this.enablePatientEmail === "Yes" &&
        //                 this.enableLabCheckoutEmail === "Yes"
        //             ) {
        //                 const message = "Mail has been sent to lab and patient !";
        //                 this.openSnackBar(message, "OK");
        //             } else if (this.enableLabCheckoutEmail === "Yes") {
        //                 const message = "Mail has been sent to lab!";
        //                 this.openSnackBar(message, "OK");
        //             } else if (this.enablePatientEmail === "Yes") {
        //                 const message = "Mail has been sent to patient!";
        //                 this.openSnackBar(message, "OK");
        //             }
        //         });

        //     this.barcode = "";
    }

    showAlert(message): void {
        const dialogUtil = new DialogUtil();
        dialogUtil.openAlertDialog(this.dialog, message, {
            onConfirm: () => { },
            onCancel: () => { },
            onAlways: () => { },
        });
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 2000,
        });
    }

    // updateRow(row): any {
    //     row.date = moment(Math.abs(row.dateTimeNum)).format('DD-MM-YYYY');
    //     row.time = moment(Math.abs(row.dateTimeNum)).format('HH:mm');
    // }
    updateRow(row): any {
        row.date = timeZone(Math.abs(row.timestamp))
            .tz(this.userService.getCookie("defaultTz"))
            .format("DD-MM-YYYY");
        row.time = timeZone(Math.abs(row.timestamp))
            .tz(this.userService.getCookie("defaultTz"))
            .format("HH:mm");
        //     row.time = moment(Math.abs(row.dateTimeNum)).format('HH:mm');
    }

    // nextPage(): void {
    //     this.paginationService.next();
    // }

    // prevPage(): void {
    //     this.paginationService.prev();
    // }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    displayFn(patient: Patient) {
        if (patient) {
            return patient.first_name;
        }
    }

    getLoadStatus(load: Cassette): string {
        return this.statusService.getCassetteStatus(load);
    }

    getCassetteResult(load: Cassette): string {
        return this.statusService.getMessage(
            this.statusService.getStatusNew(
                load.bd_status,
                load.bi_status,
                load.ei_status
            )
        );
    }

    getLoadColor(load: Cassette): string {
        return this.statusService.getColor(
            this.statusService.getStatusNew(
                load.bd_status,
                load.bi_status,
                load.ei_status
            )
        );
    }

    getSingleLoadColor(load: string): string {
        if (load) {
            return this.statusService.getColor(
                this.statusService.getStatus(load)
            );
        } else {
            return "#111111";
        }
    }

    copyCassette() {
        var copyText = "";

        if (this.dataSource.data.length === 0) {
            alert("No cassette added");
            return;
        }

        for (var i = 0; i < this.dataSource.data.length; i++) {
            const cassette = this.dataSource.data[i];
            copyText = copyText + "Barcode: " + cassette.barcode;
            copyText = copyText + ", Program: " + cassette.program;
            copyText = copyText + ", Type: " + cassette.type;
            copyText = copyText + ", Sterilizer: " + cassette.machineName;
            copyText = copyText + ", Cycle Number: " + cassette.cycleNo;
            copyText = copyText + ", Load Number: " + cassette.loadNo;

            // cassette.added.time = moment(cassette.added.time).format("DD-MM-YYYY | HH:mm");

            if (cassette.startedBy) {
                copyText =
                    copyText +
                    ", Load Start Date | Time: " +
                    timeZone(cassette.startedBy.timestamp)
                        .tz(this.userService.getCookie("defaultTz"))
                        .format("DD-MM-YYYY | HH:mm");
                copyText =
                    copyText + ", Load Started By: " + cassette.startedBy.name;
            } else if (cassette.added) {
                copyText =
                    copyText +
                    ", Load Start Date | Time: " +
                    timeZone(cassette.added.timestamp)
                        .tz(this.userService.getCookie("defaultTz"))
                        .format("DD-MM-YYYY | HH:mm");
                copyText =
                    copyText + ", Load Started By: " + cassette.added.name;
            }

            // cassette.updated.time = moment(cassette.updated.time).format("DD-MM-YYYY | HH:mm");
            copyText =
                copyText +
                ", Processed Date | Time: " +
                timeZone(cassette.updated.timestamp)
                    .tz(this.userService.getCookie("defaultTz"))
                    .format("DD-MM-YYYY | HH:mm");
            copyText = copyText + ", Processed By: " + cassette.updated.name;

            const assignedTime = timeZone(timeZone.now())
                .tz(this.userService.getCookie("defaultTz"))
                .format("DD-MM-YYYY | HH:mm");
            // copyText = copyText + ', Assigned Time: ' + assignedTime;
            copyText =
                copyText +
                ", Assigned By: " +
                this.userService.getCookie("name");

            copyText = copyText + "\n";
        }

        copy(copyText);
        alert("Copied");
    }

    // remove row from the to be assigned table
    remove(id) {
        const index = id - 1; // index starts from 0
        // const index = this.dataSource.data.indexOf(id);  // got index
        this.ELEMENT_DATA = this.dataSource.data.splice(index, 1); // what id to delete and which one to delete
        this.dataSource = new MatTableDataSource(this.dataSource.data); // refresh the data
        // removing cassette from selction array as well
        this.selection.selected.splice(index, 1);
        console.log("size" + this.dataSource.data.length)
        if (this.dataSource.data.length == 0) {
            this.listLength = 0;
        }

    }

    has2Hash(searchPatient: string) {
        return searchPatient.split("#").length === 3;
    }
}
