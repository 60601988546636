import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { StorageService } from 'app/main/module/common/service/storage.service';
import {allTrue} from '../../../app/navigation/navigationItemObjects';

@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit {
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;
    hidden = false;

    // Private
    private _unsubscribeAll: Subject<any>;
    navigationrole:any;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private userService: StorageService,
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();
        this.hideNavigationItemRoleBased()

        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();
                // console.log("current navigation=", this.navigation)

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }



    hideNavigationItemRoleBased(): void {
        // Toggle the visibility
        this.hidden = !this.hidden;
        const currentRole = this.userService.getCookie("role")
       // Update the calendar menu item
       // if (currentRole === 'user') {
           if(currentRole !== undefined && currentRole !== null){

            this._fuseNavigationService.getWebRole(currentRole).subscribe((data) => {
                // // console.log("data",data )
                Object.entries(data).find(([key, value]) => {
                    if (key == 'data') {
                    //   // console.log(value)
                      this.navigationrole=value;
                          this.navigationrole.forEach(element => {
                            // // console.log("element=",element,element.access_specifier)
                            for (const key in element.access_specifier) {
                                if (element.access_specifier.hasOwnProperty(key)) {
                                  const value = element.access_specifier[key];
                                //   // console.log(`Key: ${key}, Value: ${value}`);
                                   if(key == "can_see_twostepcheckout_screen" && value == true ){
                                    this._changeDetectorRef
                                   }
                                }
                              }
                            
                            
                          });

                    }
                   
                })
                
        //         this._fuseNavigationService.updateNavigationItem('setting', data,{
        //             hidden: this.hidden
        //         });
        //    // }
        //    // if (currentRole === 'user' || currentRole === 'admin') {
        //         this._fuseNavigationService.updateNavigationItem('labconfig', data, {
        //             hidden: this.hidden
        //         });
        //  }
          });
    
        }
       
       
        let countDown = setInterval(() => {
            let pageDynamicConfig = '';
            pageDynamicConfig = this.userService.getCookie('pageDynamicConfig');
            let navigationType = ''
            navigationType = this.userService.getCookie('navigationType');


            // // console.log('calling countdown',navigationType,pageDynamicConfig);

            if (pageDynamicConfig && pageDynamicConfig !== null) {

                let keys = Object.keys(JSON.parse(pageDynamicConfig)).filter(e => JSON.parse(pageDynamicConfig)[e] === false)
                // console.log('calling keys', keys);
                for (let i = 0; i < keys.length; i++) {
        
                    this._fuseNavigationService.updateNavigationItem2(keys[i], {
                        hidden: this.hidden
                    });
                }
                clearInterval(countDown);
            }
        }, 1000);

    }

    ngAfterviewInIt(){
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();
        this.hideNavigationItemRoleBased()

        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();
                // console.log("current navigation=", this.navigation)

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }


}
