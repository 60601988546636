import 'rxjs/add/observable/timer';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';
import { AfterViewInit, Component, TemplateRef, ViewEncapsulation } from '@angular/core';
import { CellService } from './cell/cell.service';
import { Observable } from 'rxjs';
import { Cell } from './cell/cell';
import { Asset } from './asset/asset';
import { MatDialog } from "@angular/material/dialog";
import { FormBuilder } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
const util = require('util');
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import * as Chart from 'chart.js';
import { ApiService } from '../../common/service/api.service';
import { fuseAnimations } from '@fuse/animations';
import { StorageService } from '../../common/service/storage.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
    selector: 'anms-dashimage',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class DashboardComponent implements AfterViewInit {
    dateNow = Date.now();
    canvas: any;
    ctx: any;
    chart: Chart;
    errorMessage: any;
    public cellSpacing: number[] = [10, 10];
    public panels: any = [
        { "sizeX": 1, "sizeY": 1, "row": 0, "col": 0, content: '<div class="content">0</div>' },
        { "sizeX": 3, "sizeY": 2, "row": 0, "col": 1, content: '<div class="content">1</div>' },
        { "sizeX": 1, "sizeY": 1, "row": 0, "col": 4, content: '<div class="content">2</div>' },
        { "sizeX": 1, "sizeY": 1, "row": 1, "col": 0, content: '<div class="content">3</div>' },
        { "sizeX": 1, "sizeY": 1, "row": 1, "col": 4, content: '<div class="content">4</div>' },
    ]
    loadData: any;
    allKey: any;
    allValue: any;
    ei: any;
    bi: any;
    bdar: any;
    dataInprogress: number[] = [];
    dataSuccess: number[] = [];
    dataFailed: number[] = [];
    dataPending: number[] = [];
    spinner: boolean;
    activeBtn1: boolean;
    activeBtn2: boolean;
    activeBtn3: boolean;
    activeBtn4: boolean;
    labsDetail: any;
    timeZone: any;
    constructor(private apiService: ApiService, private storageService: StorageService,private router: Router,) {
        setInterval(() => {
            this.dateNow = Date.now();
        }, 1000);
    }



    ngOnInit() {
        const lab_id = this.storageService.getCookie("lab_id");
        if (lab_id) {
            this.activeBtn1 = false;
            this.activeBtn2 = true;
            this.activeBtn3 = false;
            this.activeBtn4 = false;
            this.assignValueToChart("week");
            // this.onClickLoadData("week")
        } else {
            this.logout();
        }

    }

    logout(): void {
        localStorage.clear();
        this.storageService.clearCookie();
        // this.globals.cassetteConfig = null;
        this.router.navigate(['/login']);
        // location.reload();
    
      }

    onClickLoadData(ctxbutton) {
        this.dataInprogress = [];
        this.dataSuccess = [];
        this.dataFailed = [];
        this.dataPending = [];
        // console.log("period button",ctxbutton)

        this.spinner = true;

        if (ctxbutton == 'today') {
            this.activeBtn1 = true;
            this.activeBtn2 = false;
            this.activeBtn3 = false;
            this.activeBtn4 = false;
            this.assignValueToChart(ctxbutton);
            this.spinner = false;

        } else if (ctxbutton == 'week') {
            this.activeBtn1 = false;
            this.activeBtn2 = true;
            this.activeBtn3 = false;
            this.activeBtn4 = false;
            this.assignValueToChart(ctxbutton);
            this.spinner = false;
        } else if (ctxbutton == 'month') {

            this.activeBtn1 = false;
            this.activeBtn2 = false;
            this.activeBtn3 = true;
            this.activeBtn4 = false;
            this.assignValueToChart(ctxbutton);
            this.spinner = false;

        } else if (ctxbutton == 'year') {

            this.activeBtn1 = false;
            this.activeBtn2 = false;
            this.activeBtn3 = false;
            this.activeBtn4 = true;
            this.assignValueToChart(ctxbutton);
            this.spinner = false;

        }



    }


    getTimeZone() {
        const token = this.storageService.getCookie('token');
        const labId = this.storageService.getCookie("lab_id")
        this.apiService.getLabIdDetail(token, labId).subscribe(
            a => {
                //  // console.log("All Lab Data ==", a)
                Object.entries(a).find(([key, value]) => {
                    if (key == 'data') {
                        this.labsDetail = value;
                        //  // console.log('',this.labsDetail)
                        this.labsDetail.forEach(element => {
                            this.timeZone = element.default_time_zone;
                            console.log("timeZone=", this.timeZone)
                        });


                        // });
                    }
                    else {
                        // // console.log(key,'=',value);
                    }
                })
            },

            err => {

                this.errorMessage = err.error.message;
                // console.log('unauthorised', err.status, this.errorMessage)
                if (err.status == 401) {
                    // // console.log("gggg"),
                    alert('Token is invalid or has expired Please relogin')
                    // this.logout()
                }
                // this.isLoginFailed = true;

            }
        )
    }


    assignValueToChart(ctxbutton) {

        this.dataInprogress = [];
        this.dataSuccess = [];
        this.dataFailed = [];
        this.dataPending = [];

        //  console.log("timeZone=",this.timeZone)


        this.apiService.getLoadStatusOnDashboard(ctxbutton).subscribe(
            a => {
                // console.log("Total Load Data ==", a)
                this.loadData = {}
                Object.entries(a).find(([key, value]) => {
                    if (key == 'data') {
                        // console.log("Value =",value)
                        this.loadData = value;
                        // console.log("static",this.loadData)
                        Object.entries(this.loadData).forEach(([status, innerObject]) => {
                            // Iterate over inner object keys and values
                            Object.values(innerObject).forEach((value) => {
                                // Push values into the corresponding array based on the status
                                switch (status) {
                                    case "Inprogress":
                                        this.dataInprogress.push(value);
                                        break;
                                    case "Sucess":
                                        this.dataSuccess.push(value);
                                        break;
                                    case "Failed":
                                        this.dataFailed.push(value);
                                        break;
                                    case "pending":
                                        this.dataPending.push(value);
                                        break;
                                    default:
                                        break;
                                }
                            });
                        });

                        // console.log("dataInprogress:", this.dataInprogress);
                        // console.log("dataSuccess:", this.dataSuccess);
                        // console.log("dataFailed:", this.dataFailed);
                        // console.log("dataPending:", this.dataPending);
                        this.showbarChart(this.dataInprogress, this.dataSuccess, this.dataFailed, this.dataPending)




                    }
                    else {

                    }
                })
            },

            err => {

                this.errorMessage = err.error.message;
                // console.log('unauthorised', err.status, err.message)
                if (err.status == 401) {
                    alert('Token is invalid or has expired Please relogin')
                }

            }
        )

    }

    showbarChart(dataInprogress, dataSuccess, dataFailed, dataPending) {
        var myChart
        if (myChart) {
            myChart.destroy();
        }
        this.canvas = document.getElementById('myBarChart');
        this.ctx = this.canvas.getContext('2d');
        myChart = new Chart(this.ctx, {
            type: 'bar',
            data: {
                labels: ['BD/AR', 'BI', 'EI'],
                datasets: [{
                    label: 'Inprogress',
                    data: dataInprogress,
                    backgroundColor: ['#B0BEC5', '#B0BEC5', '#B0BEC5'],
                    borderWidth: 0.8
                },
                {
                    label: 'Success',
                    data: dataSuccess,
                    backgroundColor: ['#3F51B5', '#3F51B5', '#3F51B5'],
                    borderWidth: 0.8
                },

                {
                    label: 'Failed',
                    data: dataFailed,
                    backgroundColor: ['#E78A61', '#E78A61', '#E78A61'],
                    borderWidth: 0.8
                },
                {
                    label: 'Pending',
                    data: dataPending,
                    backgroundColor: ['#BBDEFB', '#BBDEFB', '#BBDEFB'],
                    borderWidth: 0.8
                }

                ],

            },
            options: {
                legend: {
                    display: true
                },
                responsive: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            stepSize: 1,
                        }
                    }]
                }
                // display: true
            }
        });
    }



    // createChart(ei,bdar,bi){

    //   this.chart = new Chart("MyChart", {
    //     type: 'doughnut', //this denotes tha type of chart

    //     data: {// values on X-Axis
    //       labels: ['EI','BD/AR','BI', ],
    //        datasets: [{
    //   label: 'My First Dataset',
    //   data: [ei,bdar , bi],
    //   backgroundColor: [
    //     'red',
    //     'orange',
    //     'blue',			
    //   ],
    //   // hoverOffset: 4
    // }],
    //     },
    //     options: {
    //       aspectRatio:5.5
    //     }

    //   });
    // }

    ngAfterViewInit(): void {

    }



}

