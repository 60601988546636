import { Injectable } from '@angular/core';
// import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Logs } from './logs';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../../common/service/authentication.service';
import { StorageService } from 'app/main/module/common/service/storage.service';
import { Globals } from '../../common/global';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../common/service/api.service';
import {Router, RouterStateSnapshot, ActivatedRouteSnapshot, Resolve} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
// export class LogsService {

//   constructor() { }
// }

export class LogsService  implements Resolve<Logs>{
  private itemsSubject: BehaviorSubject<Logs[]>;
  public itemsObservable: Observable<Logs[]>;
  public pageSubject: BehaviorSubject<number>;
  public pageObservable: Observable<number>;
  latestEntry: any;
  startingEntry: any;
  // onDataChanged: BehaviorSubject<any>;

  ROWS = 100; // per page data

  table = 'logs';
  currentPage = 0;
  private infoMsg: string;
  labName = '';
  data: any;
  allNames: Array<any> = [];
  // routeParams: any;

  labsObject = [];
  labsData = {
      labId: undefined,
      labName: undefined,
      role: 'NA',
      // hasLimit:false,
      // validTillDate: null

  };

  public notFoundSubject: BehaviorSubject<boolean>;
  public notFoundObservable: Observable<boolean>;

  constructor(
      public authenticationService: AuthenticationService,
      private userService: StorageService,
      public globals: Globals,
      public apiService: ApiService,
  ) {

      // this.onDataChanged = new BehaviorSubject({});
  }


  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        //  return  this.apiService.getRoleAndPermission(this.userService.getCookie('lab')).then(returnData =>{
        //     // console.log('returnData',returnData);
        //      return returnData;

        //  })
  }

  setTable(table, orderBy): void {
      this.table = table;
  }

//   getCollection(ref, queryFn?): Observable<any[]> {
//       return this.afs.collection(ref, queryFn).snapshotChanges().pipe(map(actions => {
//           return actions.map(a => {
//               const data = a.payload.doc.data();
//               const id = a.payload.doc.id;
//               const doc = a.payload.doc;
//               return { id, ...data as {}, doc };
//           });
//       }));
//   }

//   first(): void {
//       this.currentPage = 0;
//       this.itemsSubject = new BehaviorSubject([]);
//       this.itemsObservable = this.itemsSubject.asObservable();
//       this.pageSubject = new BehaviorSubject<number>(0);
//       this.pageObservable = this.pageSubject.asObservable();
//       this.notFoundSubject = new BehaviorSubject<boolean>(false);
//       this.notFoundObservable = this.notFoundSubject.asObservable();
//       this.currentPage = 0;
//       this.pageSubject.next(0);
//       const ref = this.getCollection(this.getCollectionPath(), ref => ref
//           .limit(this.ROWS))
//           .subscribe(data => {
//               this.pageSubject.next(0);
//               try {
//                   if (data && data.length) {
//                       this.latestEntry = data[data.length - 1].doc;
//                       this.startingEntry = data[0].doc;
//                   }
//               } catch (e) {
//               }
//               this.itemsSubject.next(data);
//               // this.pageSubject.next(0);
//               // if (data) {
//               // this.latestEntry = data[data.length - 1].doc;
//               // this.startingEntry = data[0].doc;
//               // this.itemsSubject.next(data);
//               // } else {
//               // }
//               // scoresRef.unsubscribe();
//           });
//   }

//   next(): void {
//       const ref = this.getCollection(this.getCollectionPath(), ref => ref
//           .startAfter(this.latestEntry)
//           .limit(this.ROWS))
//           .subscribe(data => {
//               try {
//                   if (data && data.length) {
//                       this.currentPage++;

//                       // And save it again for more queries
//                       this.latestEntry = data[data.length - 1].doc;
//                       this.startingEntry = data[0].doc;
//                       this.pageSubject.next(this.currentPage);
//                       this.itemsSubject.next(data);
//                   }
//               } catch (e) {
//               }
//               this.itemsSubject.next(data);


//               // if (data.length) {
//               //   this.currentPage++;
//               //   this.pageSubject.next(this.currentPage);
//               //   // And save it again for more queries
//               //   this.latestEntry = data[data.length - 1].doc;
//               //   this.startingEntry = data[0].doc;
//               //   this.itemsSubject.next(data);
//               // }
//               // scoresRef.unsubscribe();
//           });
//   }


//   prev(): void {
//       const ref = this.getCollection(this.getCollectionPath(), ref => ref
//           // Now you can use the latestEntry to query with startAfter
//           .startAfter(this.startingEntry)
//           .limit(this.ROWS))
//           .subscribe(data => {

//               try {
//                   if (data && data.length) {
//                       data.reverse();
//                       // And save it again for more queries
//                       this.latestEntry = data[data.length - 1].doc;
//                       this.startingEntry = data[0].doc;
//                       this.currentPage--;
//                       this.pageSubject.next(this.currentPage);
//                       this.itemsSubject.next(data);
//                   }
//               } catch (e) { // dp something
//               }
//               this.itemsSubject.next(data);

//               //
//               // data.reverse();
//               // // And save it again for more queries
//               // this.latestEntry = data[data.length - 1].doc;
//               // this.startingEntry = data[0].doc;
//               // this.currentPage--;
//               // this.pageSubject.next(this.currentPage);
//               // this.itemsSubject.next(data);

//               // scoresRef.unsubscribe();
//           });
//   }


//   getCollectionPath(): string {
//       // // console.log('logs');
//       return 'lab/' + this.userService.getCookie('lab') + '/eventLog/';
//   }

//   checkTeammember(teampath, email) {
//       return this.afs.collection(teampath).doc(email).get();
//   }

//   addLabsObject(teamData, expiryData) {
//       return new Promise((resolve, reject) => {
//           this.afs.collection('lab').doc(teamData.lab).get().subscribe((result: any) => {
//               const resultData = result.data();
//               this.labsData.labId = teamData.lab;
//               this.labsData.labName = resultData.name;
//               this.labsData.role = teamData.role;
//               // this.labsData.hasLimit = expiryData.hasLimit;
//               // this.labsData.validTillDate = expiryData.validTillDate;
//               this.labsObject.push(this.labsData);
//               resolve(this.labsObject);
//           });
//       });
//   }

//   // checking element in array of objects
//   checkLabsObjectExist(arr, labId) {
//       return arr.some((el) => {
//           return el.labId === labId;
//       });
//   }


//   addlogs(logData: any) {
//       // console.log('ADDD LOG CALING---',logData);
     
//       const logPath = 'lab/' + this.userService.getCookie('lab') +'/eventLog/';
                
//       this.afs.collection(logPath).add(logData).then((resultData) => {
//           // console.log('ADD LOG--',resultData);
//           // console.log('ADD LOG------',logData);
//         // window.alert(`Team Member ${teamData.name} Added Successfully`);
//         this.data = { msg: 'Added SuccessFully', status: 'success' };
//     }).catch((error) => {
//         // window.alert(`Server Error!, Member Not Added`);
//         this.data = { msg: 'Server Error!, Member Not Added ', status: 'fail' };
//         // console.log(error);
//     });
   
             
//           }
    

//   // backup old one
//   addTeamMember12(teamData: any) {
//       const email = teamData.email;
//       const teamPath = `lab/${teamData.lab}/team/`;
//       return new Promise((resolve, reject) => {
//           this.afs.collection('user').doc(email).get().subscribe(async (doc: any) => {
//               if (doc.data() !== undefined) {
//                   const userData = doc.data();

//                   window.alert(`${teamData.email} Already Exist`);

//                   resolve(userData);

//               } else {
//                   // this.afs.collection('user').doc(email).set(teamData).then( (resultData) => {
//                   //     const data = {msg: 'Added SuccessFully', status: 'success'};
//                   // });
//                   this.afs.collection(teamPath).add(teamData).then((resultData) => {
//                       // window.alert(`Team Member ${teamData.name} Added Successfully`);
//                       const data = { msg: 'Added SuccessFully', status: 'success' };
//                       resolve(data);

//                   }).catch((error) => {
//                       reject(error);
//                   });
//               }
//           });
//       });
//   }

//   getMemberById(docId) {
//       const memberCollectionPath = this.getCollectionPath();
//       return new Promise((resolve, reject) => {
//           this.afs.collection(memberCollectionPath).doc(docId).valueChanges().subscribe(data => {
//               resolve(data);
//           });
//       });
//   }

//   updateTeamMember(teamData) {

//       const teamPath = this.getCollectionPath();
//       return new Promise((resolve, reject) => {
//           this.afs.collection(teamPath).doc(teamData.email).update(teamData).then((resultData) => {
//               // window.alert(`Team Member ${teamData.name} Added Successfully`);
//               const data = { msg: 'Updated SuccessFully', status: 'success' };
//               resolve(data);

//           }).catch((error) => {
//               reject(error);
//           });
//       });
//   }

//   updateUserWhenTeamUpdated(teamData) {
//       return new Promise((resolve, reject) => {
//           delete teamData.sortBy;
//           this.afs.collection('user').doc(teamData.email).update(teamData).then((resultData) => {
//               const data = { msg: 'Added SuccessFully', status: 'success' };
//               resolve(data);

//           }).catch((error) => {
//               reject(error);
//           });
//       });

//   }


//   allTeamData() {
//       return new Promise<any>((resolve, reject) => {
//           this.afs.collection(this.getCollectionPath()).valueChanges().subscribe(data => {
//               if (data) {
//                   resolve(data.map((value: any) => value.name));
//               }
//           });
//       });

//   }

//   deleteDocumentById(docId) {
//       return new Promise<any>((resolve, reject) => {
//           this.afs.collection(this.getCollectionPath()).doc(docId).delete().then((result) => {
//               const msg = 'Team Member Successfully Deleted';
//               this.currentPage = 0;
//               resolve(msg);
//           }).catch((error) => {
//               reject('Error removing document');
//           });
//       });
//   }

//   getAllRole() {
//       const allData = [];
//       return new Promise<any>((resolve, reject) => {
//           const collectionName = 'common/config/role';
//           this.afs.collection(collectionName).snapshotChanges().subscribe(roleData => {
//               if (roleData) {
//                   roleData.map(mapD => {
//                       let data = {};
//                       data = mapD.payload.doc.data();
//                       allData.push(data);
//                   })

//                   resolve(allData);
//               } else {
//                   reject(' Error in fetching the role details');
//               }
//           })
//       });

//   }


// //   shareAssessmentLink(email: string): Observable<any> {
// //       const lab = this.userService.getCookie('lab');
// //       const data: any = {
// //           email: email,
// //           labId: lab,
// //       };

// //     //   return this.apiService.shareStaffAssessmentLink(data);

// //   }

//   getCovidConfig() {
//       const getCollectionPath = 'lab/' + this.userService.getCookie('lab') + '/covid';
//       return new Promise<any>((resolve, reject) => {
//           this.afs.collection(getCollectionPath).doc('config').valueChanges().subscribe(data => {
//               if (data) {
//                   resolve(data);
//               }
//           });
//       });

//   }

//     // being used in email list detail page
//     getEmailDetailsById(id) {
//         return new Promise((resolve, reject) => {
//             this.afs.collection('mail').doc(id).valueChanges().subscribe(data => {
//                 resolve(data);
//             });
//         });

//     }

}
