import {
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { ObjectUnsubscribedError, Observable } from "rxjs";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { fuseAnimations } from "@fuse/animations";
import { StorageService } from "../../../common/service/storage.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Globals } from "../../../common/global";
import { LabService } from "../../lab/lab.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { ApiService } from "app/main/module/common/service/api.service";
import { I } from "@angular/cdk/keycodes";
import { MatPaginator } from "@angular/material/paginator";
import { SelectionModel } from "@angular/cdk/collections";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatSnackBar } from "@angular/material/snack-bar";
// import { LoadverifiedService } from '../../loadverified/loadverified.service';
import { CassetteListService } from "../list/cassette-list.service";
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { OpendialougeComponent } from "../opendialouge/opendialouge.component";
import * as es6printJS from "print-js";
import * as printJS from "print-js";
import {
    NgxQrcodeElementTypes,
    NgxQrcodeErrorCorrectionLevels,
    NgxQRCodeModule,
} from "@techiediaries/ngx-qrcode";
import { MatRadioChange } from "@angular/material/radio";

const timeZone = require("moment-timezone");
export interface State {
    flag: string;
    name: string;
    population: string;
}

@Component({
    selector: "app-preparesets",
    templateUrl: "./preparesets.component.html",
    styleUrls: ["./preparesets.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class PreparesetsComponent {
    [x: string]: any;
    name = "Angular ";
    elementType = NgxQrcodeElementTypes.IMG;
    correctionLevel = NgxQrcodeErrorCorrectionLevels.LOW;
    value = "";

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @Input()
    public content: any;
    public rows = new MatTableDataSource<any>();
    public columns = [];
    public selection = new SelectionModel<any>(true, []);
    departmentList: Array<any> = [];
    categoryList: Array<any> = [];
    settingFormOne: FormGroup;
    settingFormTwo: FormGroup;
    settingFormThree: FormGroup;
    settingFormFour: FormGroup;
    currentDepartment: any;
    currentUser = this.userService.getCookie("email");
    currentUserName = this.userService.getCookie("name");
    currentSubCategory: any;
    subCategoryList: Array<any> = [];
    currentCategory: any;
    // role = this.userService.getCookie('role');
    currentRoleData: any;
    getStatus: any;
    getError: any;

    displayedColumns: string[] = [
        "quantity",
        "name",
        "code",
        "selectAll",
        "addMoreBtn",
    ];

    dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
    tableList: any = [];
    tableForm: FormGroup;
    setQuantity: Array<any> = [];
    setParticulars: Array<any> = [];
    setBulkReport: Array<any> = [];
    setCode: Array<any> = [];
    showTable: boolean = false;
    quantity: any;
    setsArray: any;
    finalBarcode: any;
    selectedLength: number;
    changesRowLength: any;
    lastId: any;
    getTemplateList: any;
    insertCheckedchecked: object;
    time: any;
    setsDisplayName: any;
    setsPrintTitle: any;
    midlevalue: any;
    finalBarcode2: any;
    instruments: Array<any> = [];
    selectionlength: number = 0;
    contentLength: any;
    setBulkReportForTrue: Array<any> = [];
    selectedDepartment: any;
    selectedDepartmentName: any;
    selectedCategoryName: any;
    selectedCategory: string;
    selectedSubCategoryName: string;
    selectedSubCategory: string;
    returnUpdatebarcode: any;
    templateLength: boolean;

    command_type: any;
    connect_type: any;
    barcode_type: any;
    barcodestringposition: any;
    print_copies: any;
    print_direction: any;
    print_rotation: any;

    viewPrinterCode: boolean = true;
    label_height: any;
    label_width: any;
    label_gap: any;
    printer_dpi: any;
    dpi: any;
    qrwidth: any;
    qrsize: any;
    qrheight: any;
    barcodewidth: any;
    barcodeheight: any;
    titleheight: any;
    // titlewidth: any;
    headingBarcode: any;
    headingTitle: any;
    rightContainer: any;
    titlemargintop: any;
    lablemargintop: any;
    labelmarginright: any;
    pagemargintop: any;
    pagemarginbottom: any;
    pagemarginleft: any;
    pagemarginright: any;
    titlefontsize: any;
    barcodefontsize: any;
    isInitComplete: boolean;
    currentType: any = "Internal";
    quantityext: number;
    instrumentext: string = "";
    addmoreButton: boolean = true;
    userbarcode: any;
    errorMessage: any;
    public isChecked: boolean = false;
    rowIndex: number;

    constructor(
        public labService: LabService,
        private http: HttpClient,
        public apiservice: ApiService,
        private formBuilder: FormBuilder,
        private userService: StorageService,
        private cassetteservice: CassetteListService,
        private router: Router,
        public globals: Globals,
        public snackBar: MatSnackBar,
        // private loadservice: LoadverifiedService,
        public dialog: MatDialog,
        private cdr: ChangeDetectorRef // public dialogRef: MatDialogRef<PreparesetsComponent>
    ) {
        this.elementType = NgxQrcodeElementTypes.IMG;
        this.correctionLevel = NgxQrcodeErrorCorrectionLevels.LOW;
        this.value = this.finalBarcode;
    }

    ngOnInit(): void {
        // this.currentType = 'Internal'
        const lab_id = this.userService.getCookie("lab_id");
        if (lab_id) {
            this.selectionlength = 0;
            this.departmentList = [];
            this.lastId = 0;
            this.getDocuments();
            this.selection.clear();

            this.settingFormOne = this.formBuilder.group({
                departmentListName: ["", Validators.required],
            });

            this.settingFormTwo = this.formBuilder.group({
                categoryListName: ["", Validators.required],
            });

            this.settingFormThree = this.formBuilder.group({
                subcatListName: ["", Validators.required],
            });

            this.tableForm = this.formBuilder.group({
                setTemplateList: this.formBuilder.array([]),
            });

            this.getprinterconfig();
        } else {
            this.logout();
        }
    }

    radioButtonChanged(event: MatRadioChange) {
        this.currentType = event.value;
        console.log("Selected Type:", this.currentType);
        if (this.currentType == "External") {
            this.addmoreButton = true;
        }
        if (this.currentType === "External") {
            // Clear settingFormOne
            this.settingFormOne.reset();

            // Clear settingFormTwo
            this.settingFormTwo.reset();

            // Clear settingFormThree
            this.settingFormThree.reset();
        }

        this.tableList.length = 0;
        this.showTable = false;

        this.tableList = [];
        const userCtrl = this.tableForm.get("setTemplateList") as FormArray;
        while (userCtrl.length) {
            userCtrl.removeAt(0);
        }
    }

    openDialogWithRefext(ref: TemplateRef<any>): void {
        this.addmoreButton = false;

        this.quantityext = null;
        this.instrumentext = "";

        this.dialog.open(ref, { width: "450px", disableClose: true });
    }

    handleDialogKeydown(
        event: KeyboardEvent,
        dialogRef: MatDialogRef<any>
    ): void {
        if (event.key === "Enter") {
            this.createExternalSets();
        }
    }

    onKeyDown(event: KeyboardEvent): void {
        if (event.key === "Enter") {
            // Trigger the click event of the Confirm button
            const confirmButton = document.getElementById("confirmButton");
            if (confirmButton) {
                confirmButton.click();
            }
        }
    }

    submitDialog(dialogRef: MatDialogRef<any>): void {
        console.log("dialogRef=", dialogRef);
        dialogRef.afterClosed().subscribe((result) => {
            console.log("Dialog closed", result);
        });
        dialogRef.close();
    }

    keyPressNumbers(event) {
        var charCode = event.which ? event.which : event.keyCode;
        // Only Numbers 0-9
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
            return false;
        } else {
            return true;
        }
    }

    updateQuantityInTableList(newQuantity: number, index: number) {
        // Update the quantity property in tableList array
        this.tableList[index].quantity = newQuantity;
        // Update the value of the quantity control in the form array for the current row
        const userCtrl = this.tableForm.get("setTemplateList") as FormArray;
        userCtrl.at(index).get("quantity").setValue(newQuantity);
        // Update the dataSource if needed
        this.dataSource = new MatTableDataSource<any>(this.tableList);
    }

    private setUsersFormExternal() {
        const userCtrl = this.tableForm.get("setTemplateList") as FormArray;
        // Clear existing form array
        // while (userCtrl.length) {
        //   userCtrl.removeAt(0);
        // }
        this.tableList.forEach((user) => {
            //   userCtrl.push(this.setUsersFormArray(user))
            const isDuplicate = userCtrl.controls.some(
                (control) =>
                    control.get("quantity").value === user.quantity &&
                    control.get("name").value === user.name &&
                    control.get("code").value === user.code.toString() // Assuming code is a string
            );
            if (!isDuplicate) {
                userCtrl.push(this.setUsersFormArray(user));
                console.log("userCtrl", userCtrl.length);
            }
        });
    }

    private setUsersForm() {
        const userCtrl = this.tableForm.get("setTemplateList") as FormArray;
        while (userCtrl.length) {
            userCtrl.removeAt(0);
        }
        console.log("tableList.length", this.tableList);

        this.tableList.forEach((user) => {
            userCtrl.push(this.setUsersFormArray(user));
            // console.log("userCtrl", userCtrl.length);
        });
    }

    private setUsersFormArray(user) {
        return this.formBuilder.group({
            quantity: [user.quantity],
            name: [user.name],
            code: [user.code].toString() || "",
            checkbox: [user.checkbox] || false,
        });
    }

    // public masterToggle() {
    //   // console.log(this.selection.selected)
    //   this.isAllSelected() ?
    //     this.selection.clear() :
    //     this.dataSource.data.forEach(row => {
    //       this.selection.select(row)
    //       //  console.log('row=',row,row.length)
    //     }
    //     );
    //     this.updateCheckboxValues();
    // }

    public isAllSelected() {
        const numSelected = this.selection.selected.length;
        this.selectionlength = numSelected;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    public masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
        } else {
            this.dataSource.data.forEach((row) => this.selection.select(row));
        }
        this.updateCheckboxValues();
    }

    private updateCheckboxValues() {
        const userCtrl = this.tableForm.get("setTemplateList") as FormArray;
        console.log("userCtrl", userCtrl.controls);

        // if (userCtrl.controls[0].value.checkbox === true) {
        //     const row = this.dataSource.data[0];
        //     const selected = this.selection.isSelected(row);
        //     userCtrl.controls[0].patchValue({ checkbox: selected });
        // }

        userCtrl.controls.forEach((control, index) => {
            const row = this.dataSource.data[index];
            const selected = this.selection.isSelected(row);
            control.patchValue({ checkbox: selected });
        });
    }

    public checkboxLabel(row?: any): string {
        return !row
            ? `${this.isAllSelected() ? "select" : "deselect"} all`
            : `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.position + 1
            }`;
    }

    private updateRows(): void {
        this.dataSource = new MatTableDataSource<any>(this.content);
        // console.log("datasource=", this.dataSource);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    private updateColumns(): void {
        this.columns = ["select"];
        for (const column of Object.keys(this.content[0])) {
            this.columns.push(column);
        }
    }

    private updateTable(): void {
        // console.log(this.content);
        if (this.content) {
            this.updateRows();
            this.updateColumns();
        }
    }

    printLabelConfirmation(
        ref: TemplateRef<any>,
        contentLength,
        selectionLength
    ) {
        console.log(
            "calling printLabelConfirmation >> " + contentLength,
            selectionLength
        );
        sessionStorage.setItem("contentLength", contentLength);
        sessionStorage.setItem("selectionLength", selectionLength);
        sessionStorage.setItem("showFinalPrint", "false");
        const token = this.userService.getCookie("token");
        const labId = this.userService.getCookie("lab_id");

        console.log("this.tableForm.value", this.tableForm.value);

        this.apiservice.getLabIdDetail(token, labId).subscribe(
            (a) => {
                a["data"].forEach((element) => {
                    this.getbarcode(element.statistics.last_cassette_barcode);
                });
            },
            (err) => {
                this.getStatus = err.error.message;
                this.errorMessage = err.error.message;
                console.log("unauthorised", err.status, this.errorMessage);
                if (err.status == 401) {
                    console.log("gggg"),
                        alert("Token is invalid or has expired Please relogin");
                    this.logout();
                }
            }
        );
    }

    logout(): void {
        localStorage.clear();
        this.userService.clearCookie();
        // this.globals.cassetteConfig = null;
        this.router.navigate(["/login"]);
        // location.reload();
    }

    getbarcode(userbarcode) {
        const contentLengthuser = sessionStorage.getItem("contentLength");
        const selectionLengthuser = sessionStorage.getItem("selectionLength");

        const dialogRef = this.dialog.open(OpendialougeComponent, {
            disableClose: true,
            data: {
                contentLength: contentLengthuser,
                selectionLength: selectionLengthuser,
                id: "test",
            },
            width: "450px",
        });
        dialogRef.afterClosed().subscribe((result) => {
            console.log("result >>" + result);

            const kk = document.getElementById("kir");
            console.log(kk);
            // this.dialogResult = result;
            if (result == true) {
                console.log(result.id);
                // console.log(id);
                this.viewPrinterCode = false;
                this.isInitComplete = true;

                this.tableForm.value;
                console.log("this.tableForm.value", this.tableForm.value);
                this.onSubmit(userbarcode);
            } else {
                console.log(result);
            }
        });
    }

    getDocuments() {
        this.userbarcode = "";
        this.apiservice.getDocumentsApi().subscribe(
            (data) => {
                console.log("deparments=", data);
                data["data"].forEach((element) => {
                    // console.log(element.display_name)
                    if (
                        element.display_name != undefined &&
                        element.display_name != null &&
                        element.display_name != ""
                    ) {
                        this.departmentList.push({
                            id: element.name,
                            name: element.display_name,
                        });
                        // console.log(this.departmentList);
                        this.departmentList.sort(this.sortByName);
                    }
                    // else{
                    //   this.departmentList.push(element.name)
                    // }
                });
            },

            (err) => {
                this.getStatus = err.error.message;
                this.errorMessage = err.error.message;
                console.log("unauthorised", err.status, this.errorMessage);
                if (err.status == 401) {
                    console.log("gggg"),
                        alert("Token is invalid or has expired Please relogin");
                    this.logout();
                }
            }
        );
    }

    departOnChangeVal(deviceValue) {
        this.insertCheckedchecked = [];
        this.selectedDepartmentName = "";
        this.selectedDepartment = "";
        this.showTable = false;
        for (let key in deviceValue) {
            if (key == "name") {
                this.selectedDepartmentName = deviceValue[key];
                console.log(
                    "selectedDepartmentName",
                    this.selectedDepartmentName
                );
            }
            if (key == "id") {
                this.selectedDepartment = deviceValue[key];
                console.log("selectedDepartment=", this.selectedDepartment);
            }
        }

        //  console.log('deviceValue=',deviceValue,this.currentDepartment);
        this.categoryList = [];
        this.subCategoryList = [];
        this.isInitComplete = true;
        this.apiservice.getCategoryApi().subscribe({
            next: (data) => {
                // console.log(data['data'])
                data["data"].forEach((element) => {
                    if (this.selectedDepartment == element.departments) {
                        if (
                            element.display_name != undefined &&
                            element.display_name != null &&
                            element.display_name != ""
                        ) {
                            this.categoryList.push({
                                id: element.name,
                                name: element.display_name,
                            });
                            this.isInitComplete = false;

                            this.categoryList.sort(this.sortByName);
                            //  console.log(this.categoryList);
                        }
                    }
                });
            },
            error: (HttpErrorResponse) => {
                this.getStatus = HttpErrorResponse.error.status;
                console.log("status=", this.getStatus);
                this.getError = HttpErrorResponse.error.error;
                console.log("error=", this.getError);
            },
        });
    }

    sortByName(a: any, b: any) {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        // names must be equal
        return 0;
    }

    categoryOnChangeVal(deviceValue) {
        this.insertCheckedchecked = [];
        this.showTable = false;
        this.subCategoryList = [];
        this.selectedCategoryName = "";
        this.selectedCategory = "";

        for (let key in deviceValue) {
            if (key == "name") {
                this.selectedCategoryName = deviceValue[key];
                //  console.log('selectedCategoryName',this.selectedCategoryName)
            }
            if (key == "id") {
                this.selectedCategory = deviceValue[key];
                // console.log('selectedCategory=',this.selectedCategory)
            }
        }
        this.isInitComplete = true;

        this.apiservice.getSubCategoryApi().subscribe({
            next: (data) => {
                //  console.log(data['data'])
                data["data"].forEach((element) => {
                    if (this.selectedCategory == element.categories) {
                        if (
                            element.display_name != undefined &&
                            element.display_name != null &&
                            element.display_name != ""
                        ) {
                            this.subCategoryList.push({
                                id: element.name,
                                name: element.display_name,
                            });
                            this.isInitComplete = false;

                            this.subCategoryList.sort(this.sortByName);
                            // console.log(this.subCategoryList);
                        }
                    }
                });
            },
            error: (HttpErrorResponse) => {
                this.getStatus = HttpErrorResponse.error.status;
                console.log("status=", this.getStatus);
                this.getError = HttpErrorResponse.error.error;
                console.log("error=", this.getError);
            },
        });
    }

    subCategoryOnChangeVal(deviceValue) {
        this.insertCheckedchecked = [];
        this.selection.clear();
        this.selectionlength = 0;
        this.showTable = true;
        this.setsDisplayName = "";
        this.setsPrintTitle = "";
        this.tableList = [];
        this.dataSource = new MatTableDataSource<any>();
        this.content = [];
        this.contentLength = 0;

        this.selectedSubCategoryName = "";
        this.selectedSubCategory = "";
        this.isInitComplete = true;

        for (let key in deviceValue) {
            if (key == "id") {
                this.selectedSubCategory = deviceValue[key];
                console.log("selectedSubCategory=", this.selectedSubCategory);
            }
            if (key == "name") {
                this.selectedSubCategoryName = deviceValue[key];
                console.log(
                    "selectedSubCategoryName",
                    this.selectedSubCategoryName
                );
            }
        }
        this.tableList = [];
        // console.log('caling subcategory deviceValue=',deviceValue,this.currentDepartment,this.currentCategory);
        this.apiservice
            .getSet_templatesApi(
                this.selectedDepartment,
                this.selectedCategory,
                this.selectedSubCategory
            )
            .subscribe({
                next: (data) => {
                    // this.tableList=data['data'];
                    console.log("set template data =", data["data"]);
                    if (data["data"].length == 0) {
                        //  this.templateLength = true;
                        //  this.openDialogWithRef()
                        window.alert(
                            "This template does not exist, please contact the administrator."
                        );
                    }
                    data["data"].forEach((element) => {
                        this.setsDisplayName = element.display_name;
                        this.setsPrintTitle = element.print_title;
                        console.log(this.setsDisplayName, this.setsPrintTitle);
                        element.instruments.forEach((element) => {
                            // this.tableList.push({ name: element.name, code: element.code, quantity: element.quantity, checkbox: element.checkbox });
                            this.tableList.push({
                                name: element.name,
                                code: element.code,
                                quantity: element.quantity,
                            });
                        });
                    });
                    this.dataSource = new MatTableDataSource<any>(
                        this.tableList
                    );
                    this.isInitComplete = false;

                    this.setUsersForm();
                    // this.content = this.tableList;
                    this.contentLength = this.tableList.length;
                    console.log(this.contentLength);
                },
                error: (HttpErrorResponse) => {
                    this.getStatus = HttpErrorResponse.error.status;
                    console.log("status=", this.getStatus);
                    this.getError = HttpErrorResponse.error.error;
                    console.log("error=", this.getError);
                },
            });
    }

    openDialogWithRef(ref: TemplateRef<any>): void {
        this.dialog.open(ref, { width: "400px" });
        // this.router.navigate(['/viewtool']);
    }

    // openDialogWithRefEdit(
    //     ref: TemplateRef<any>,
    //     data: any,
    //     rowIndex: any
    // ): void {
    //     this.rowIndex = rowIndex;
    //     const dialogRef = this.dialog.open(ref, {
    //         width: "400px",
    //         data: { data, rowIndex },
    //     });

    //     dialogRef.afterClosed().subscribe((result) => {
    //         if (result) {
    //             this.editSets(result.data, rowIndex);
    //         }
    //     });

    //     console.log(data);
    // }

    createExternalSets() {
        this.isInitComplete = true;
        // this.tableList = [];
        var quantityexts = "";
        var instrumentexts = "";
        this.addmoreButton = false;
        this.insertCheckedchecked = [];
        // this.selection.clear();
        this.selectionlength = 0;
        this.showTable = true;
        // this.setsDisplayName = '';
        // this.setsPrintTitle = '';
        // quantityexts = qwa;
        //  instrumentexts = inst;
        // this.tableList = [];
        // this.dataSource = new MatTableDataSource<any>();
        this.content = [];
        this.contentLength = 0;
        console.log("qty", this.quantityext, "intruments=", this.instrumentext);
        // this.tableList.push({ name: this.instrumentext, code: '', quantity: this.quantityext });
        // this.dataSource = new MatTableDataSource<any>(this.tableList)
        // console.log("updated tableList:", this.tableList);

        const userCtrl = this.tableForm.get("setTemplateList") as FormArray;

        console.log("tableForm: ", this.tableForm);
        console.log("Content: ", this.content);

        userCtrl.insert(
            0,
            this.setUsersFormArray({
                name: this.instrumentext,
                code: "",
                quantity: this.quantityext,
                checkbox: true,
            })
        ); // Initialize with default values

        this.tableList.splice(0, 0, {
            name: this.instrumentext,
            code: "",
            quantity: this.quantityext,
        });

        // Assuming you want to update the dataSource as well
        this.dataSource = new MatTableDataSource<any>([...this.tableList]);

        //marking checkbox when adding data
        const newDataRow = this.dataSource.data[0];
        if (newDataRow) {
            newDataRow.checkbox = true;
        }
        this.selection.select(this.dataSource.data[0]);

        this.updateCheckboxValues();

        //Printing data to test
        console.log("Data source before rendering:", this.dataSource);
        this.contentLength = this.tableList.length;
        console.log("tablelist", this.tableList);
        console.log("userCtrl: ", userCtrl);

        // this.setUsersForm();
        // this.content = this.tableList;

        // instrumentext = '';
        // quantityext = '';

        if (this.currentType == "External") {
            this.selectedDepartment = "external";
            this.selectedCategory = "external";
            this.selectedSubCategory = "external";
            this.selectedDepartmentName = "-";
            this.selectedCategoryName = "-";
            this.selectedSubCategoryName = "-";
            this.setUsersFormExternal();
            this.contentLength = this.tableList.length;
        }

        // need to call below api to get printTitle value
        this.apiservice
            .getSet_templatesApi(
                this.selectedDepartment,
                this.selectedCategory,
                this.selectedSubCategory
            )
            .subscribe({
                next: (data) => {
                    console.log("set template data =", data["data"]);
                    if (data["data"].length == 0) {
                        window.alert(
                            "This template does not exist, please contact the administrator."
                        );
                    }
                    data["data"].forEach((element) => {
                        this.setsDisplayName = element.display_name;
                        this.setsPrintTitle = element.print_title;
                        console.log(this.setsDisplayName, this.setsPrintTitle);
                    });
                    this.isInitComplete = false;

                    this.dialog.closeAll();
                },
                error: (HttpErrorResponse) => {
                    this.getStatus = HttpErrorResponse.error.status;
                    console.log("status=", this.getStatus);
                    this.getError = HttpErrorResponse.error.error;
                    console.log("error=", this.getError);
                },
            });
    }

    // editSets(
    //     data: {
    //         quantity: number;
    //         instrument: string;
    //     },
    //     rowIndex: number
    // ): void {
    //     const { quantity, instrument } = data;

    //     this.rowIndex = rowIndex;

    //     console.log(data, this.dataSource.filteredData[rowIndex]);

    //     // if (this.rowIndex === undefined || this.rowIndex < 0) {
    //     //     console.error("Invalid or undefined row index:", this.rowIndex);
    //     //     return;
    //     // }

    //     if (
    //         this.dataSource.filteredData &&
    //         this.dataSource.filteredData[this.rowIndex]
    //     ) {
    //         this.dataSource.filteredData[rowIndex].quantity = quantity;
    //         this.dataSource.filteredData[rowIndex].name = instrument;

    //         this.dataSource._updateChangeSubscription();

    //         this.cdr.detectChanges();

    //         console.log(
    //             "Updated data source:",
    //             this.dataSource.filteredData[rowIndex]
    //         );

    //         // Only necessary if you are using MatTableDataSource
    //     } else {
    //         console.error("Data source is undefined or invalid row index");
    //     }

    //     console.log(this.dataSource);
    // }

    onSubmit(userbarcode) {
        this.insertCheckedchecked = [];
        this.instruments = [];
        this.lastId = 0;
        this.setBulkReport = [];
        this.setBulkReportForTrue = [];
        this.selectedLength = this.selection.selected.length;
        this.getTemplateList = this.tableForm.value;
        console.log("getTemplateList", this.getTemplateList);
        this.changesRowLength = this.getTemplateList["setTemplateList"].length;

        console.log("dbbarcode", userbarcode);
        this.finalBarcode = "";
        var text = userbarcode;
        var getPart = text.replace(/[^\d.]/g, "");
        var num = parseInt(getPart);
        var newVal = (num + 1).toString().padStart(getPart.length, "0");
        this.finalBarcode = text.replace(getPart, newVal);
        console.log(this.finalBarcode);

        this.getTemplateList["setTemplateList"].forEach((element) => {
            console.log(element.checkbox);

            if (element.checkbox !== false && element.checkbox !== true) {
                this.setBulkReport.push(element);
            } else if (element.checkbox == true) {
                this.setBulkReportForTrue.push(element);
            }
        });

        this.cassetteservice
            .getSetsDetailByBarcode(this.finalBarcode)
            .subscribe((a: any) => {
                console.log(
                    " All Sets Detail by  barcode ==",
                    a,
                    this.finalBarcode
                );
                // Check if 'status' property is 'success'
                if (a.status === "success") {
                    this.isInitComplete = false;
                    const message =
                        "This Barcode (" +
                        this.finalBarcode +
                        ") is already exist. Please enter a new Barcode.";
                    this.openSnackBar(message, "OK");
                } else if (a.message === "No data Found") {
                    this.getlatestBarcode(this.finalBarcode);
                }
            });

        (err) => {
            this.errorMessage = err.error.message;
            this.errorMessage = err.error.message;
            console.log("unauthorised", err.status, this.errorMessage);
            if (err.status == 401) {
                console.log("gggg"),
                    alert("Token is invalid or has expired Please relogin");
                this.logout();
            }
        };
    }

    getlatestBarcode(userbarcode) {
        // console.log(this.setBulkReport.length,this.setBulkReportForTrue.length);
        if (this.setBulkReportForTrue.length !== 0) {
            this.setBulkReportForTrue.forEach((element) => {
                this.time = new Date(new Date()).getTime();
                this.instruments.push({
                    name: element.name,
                    quantity: element.quantity,
                    code: element.code,
                });
            });
        } else {
            this.setBulkReport.forEach((element) => {
                this.time = new Date(new Date()).getTime();
                // console.log(element.quantity,element.particulars,element.code);
                this.instruments.push({
                    name: element.name,
                    quantity: element.quantity,
                    code: element.code,
                });
            });
        }

        const created_by = {
            id: this.userService.getCookie("email"),
            name: this.currentUserName,
            timestamp: this.time.toString(),
        };
        this.insertCheckedchecked = {
            attached: false,
            barcode: userbarcode,
            categories: this.selectedCategoryName,
            created_by: created_by,
            departments: this.selectedDepartmentName,
            timestamp: this.time.toString(),
            display_name: this.setsDisplayName,
            print_title: this.setsPrintTitle,
            instruments: this.instruments,
            subcategories: this.selectedSubCategoryName,
            set_scope: this.currentType.toLowerCase(),
            verified: false,
            is_assigned_to_department: false,
            is_assigned_to_patient: false,
        };
        console.log(this.insertCheckedchecked, this.instruments.length);
        this.apiservice
            .insertCheckedTemplates(this.insertCheckedchecked)
            .subscribe({
                next: (data) => {
                    this.returnUpdatebarcode = "";
                    console.log("insertchecked api=", data);
                    if (data) {
                        sessionStorage.setItem(
                            "successMessage",
                            "Label Printed Successfully "
                        );
                        sessionStorage.setItem("lastUsedBarcode", userbarcode);
                        sessionStorage.setItem("showFinalPrint", "true");
                        console.log(this.setsPrintTitle);
                        sessionStorage.setItem(
                            "generatedPrintTitle",
                            this.setsPrintTitle
                        );
                        this.elementType = NgxQrcodeElementTypes.IMG;
                        this.correctionLevel =
                            NgxQrcodeErrorCorrectionLevels.LOW;
                        this.value = userbarcode;
                        setTimeout(() => {
                            const timeout = 5000;
                            this.printTest1();

                            const dialogRef = this.dialog.open(
                                OpendialougeComponent,
                                {
                                    disableClose: true,
                                    data: {
                                        showFinalPrint: "true",
                                        message: "Label Printed Successfully ",
                                        finalBarcode: userbarcode,
                                        generatedPrintTitle:
                                            this.setsPrintTitle,
                                    },
                                    width: "450px",
                                }
                            );
                            dialogRef.afterOpened().subscribe((gg) => {
                                setTimeout(() => {
                                    dialogRef.close();
                                    // this.isInitComplete = false;
                                }, timeout);
                            });

                            dialogRef.afterClosed().subscribe((result) => { });
                        }, 7000);
                    } else {
                        // this.openSnackBar(data.status, 'OK');
                        console.log("else calling");
                    }
                    this.subCategoryList = [];
                    this.showTable = false;
                    this.ngOnInit();
                },
            });
        this.apiservice.updateLastBarcodeUsed(userbarcode).subscribe((a) => {
            console.log("update data", a);
        });
    }

    getprinterconfig() {
        this.apiservice.getLabelprintersApi().subscribe({
            next: (data) => {
                this.lastId = 0;
                console.log(data["data"]);
                data["data"].forEach((element) => {
                    this.connect_type = element.connect_type;
                    this.barcode_type = element.barcode_type;
                    this.printer_dpi = element.printer_dpi;
                    this.dpi = element.dpi;
                    // let map=dpi.get(printer_dpi)
                    let jsonMap = new Map(Object.entries(this.dpi));
                    console.log(jsonMap);
                    for (var entry of jsonMap.entries()) {
                        let key = entry[0],
                            value = entry[1];
                        let innerJson = JSON.stringify(value);
                        let innerJsonObj = JSON.parse(innerJson);
                        // console.log(key)
                        console.log(innerJsonObj);
                        if (key == this.printer_dpi) {
                            this.qrwidth = innerJson["qrwidth"];
                            this.titlemargintop =
                                innerJsonObj["titlemargintop"]; // new field add as
                            this.lablemargintop =
                                innerJsonObj["lablemargintop"];
                            this.labelmarginright =
                                innerJsonObj["labelmarginright"];
                            this.titlefontsize = innerJsonObj["titlefontsize"];
                            this.barcodefontsize =
                                innerJsonObj["barcodefontsize"];
                            this.pagemargintop = innerJsonObj["pagemargintop"];
                            this.pagemarginbottom =
                                innerJsonObj["pagemarginbottom"];
                            this.pagemarginleft =
                                innerJsonObj["pagemarginleft"];
                            this.pagemarginright =
                                innerJsonObj["pagemarginright"];

                            console.log(
                                this.lablemargintop +
                                "_" +
                                this.labelmarginright +
                                "_" +
                                this.titlemargintop +
                                "_" +
                                this.barcodefontsize +
                                "_" +
                                this.titlefontsize +
                                "_" +
                                this.pagemargintop +
                                "_" +
                                this.pagemarginbottom +
                                "_" +
                                this.pagemarginleft +
                                "_" +
                                this.pagemarginright
                            );
                            //style="margin-top: 20px; float: right; margin-right: 50px;"
                            this.rightContainer = {
                                "font-family":
                                    "Verdana, Geneva, Tahoma, sans-serif",

                                "margin-right": this.labelmarginright + "px", //50

                                "margin-top": "20px", //20
                            };

                            this.headingBarcode = {
                                // 'font-size': this.barcodefontsize + 'px',
                                "margin-top": "30px", //20
                                "font-size": "45px",
                            };
                            //style="margin-top:50px; font-size: 16px;
                            this.headingTitle = {
                                // 'font-family': 'Verdana, Geneva, Tahoma, sans-serif',
                                "margin-top": this.titlemargintop + "px",
                                // 'font-size': this.titlefontsize + 'px',
                                "font-size": "45px",
                            };
                        }
                    }
                });
            },
        });
    }

    openSnackBar(message: string, action: string): void {
        const currentTime = new Date().getTime();
        // this.lastSnackTime = currentTime;
        this.snackBar.open(message, action, {
            duration: 6000,
        });
    }

    // printTest1() {
    //   this.pagemargintop = '0in';
    //   this.pagemarginbottom = '5in';
    //   this.pagemarginleft = '0.8in';
    //   this.pagemarginright = '0in';

    //   const barcodeContent = `<strong><b><span style="font-size: 35px;">${this.finalBarcode}</span></b></strong>`;
    //   const form1Content1 = `
    //   <div class="container">
    //     <div class="leftContainer" style="float: left;">
    //       <ngx-qrcode [id]="t1" [elementType]="elementType" [errorCorrectionLevel]="correctionLevel" [value]="value" [width]="qrwidth" [margin-top]="none"></ngx-qrcode>
    //     </div>
    //     <div class="rightContainer">
    //       <strong><b><span>${this.finalBarcode}jj</span></b></strong>
    //       <br>
    //       <strong><b><span>${this.setsPrintTitle}</span></b></strong>
    //     </div>
    //   </div>
    // `;
    //   es6printJS({
    //     printable: form1Content1,
    //     type: 'html',
    //     targetStyles: ['*'],
    //     style: `
    //       @page {
    //         margin-top: -0.6cm;
    //         margin-bottom: 8cm;
    //         margin-left: 1.9cm;
    //         margin-right: 0cm;
    //       }
    //       @media print {
    //         body * {
    //           margin: 15mm 15mm 15mm 15mm;
    //         }
    //         #section-to-print, #section-to-print * {
    //           visibility: visible;
    //         }
    //         #section-to-print {
    //           position: absolute;
    //           left: 0;
    //           top: 0;
    //         }
    //       }
    //     `,
    //   });

    //   this.viewPrinterCode = true;
    //   this.isInitComplete = false;
    //   if (this.currentType === 'External') {
    //     this.addmoreButton = true;
    //   }
    // }

    printTest1() {
        this.pagemargintop = "0in";
        this.pagemarginbottom = "5in";
        this.pagemarginleft = "0.8in";
        this.pagemarginright = "0in";
        es6printJS({
            printable: "form1",
            type: "html",
            targetStyles: ["*"],

            style: `@page { margin-top: -0.6cm;

        margin-bottom: 8cm;

        margin-left: 1.9cm;

        margin-right: 0cm;   } @media print {

          body * {

            margin: 15mm 15mm 15mm 15mm;

          }

          #section-to-print, #section-to-print * {

            visibility: visible;

          }

          #section-to-print {

            position: absolute;

            left: 0;

            top: 0;

          }

        }`,
        });

        this.viewPrinterCode = true;
        this.isInitComplete = false;
        if (this.currentType == "External") {
            this.addmoreButton = true;
        }

        this.tableList.length = 0;
        this.showTable = false;

        this.tableList = [];
        const userCtrl = this.tableForm.get("setTemplateList") as FormArray;
        while (userCtrl.length) {
            userCtrl.removeAt(0);
        }
    }
}
