import { Component, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import { Inject } from '@angular/core'; 
import { Globals } from '../../../../common/global';
import { Router } from '@angular/router';


@Component({
  selector: 'app-conformation-dialog',
  templateUrl: './conformation-dialog.component.html',
  styleUrls: ['./conformation-dialog.component.scss']
})
export class ConformationDialogComponent implements OnInit {

  firstName:any;
  lastName:any;
  email:any;
  patientId:any;
  dateTime: any;
  comboId: any;
  token:any;
  labId: any;
  mobileNo: any;


  constructor(  public dialogRef: MatDialogRef<ConformationDialogComponent>,
    
    @Inject(MAT_DIALOG_DATA) public data: any,
    private global: Globals,
    private router: Router,
    ) { }

  ngOnInit(): void {

    // // console.log(this.dialogRef.componentInstance.data.urlStr);
    this.email = this.dialogRef.componentInstance.data.email;
    this.patientId = this.dialogRef.componentInstance.data.patientId;
    this.lastName = this.dialogRef.componentInstance.data.lastName;
    this.firstName = this.dialogRef.componentInstance.data.firstName;
    this.dateTime = this.dialogRef.componentInstance.data.dateTime;
    this.comboId = this.dialogRef.componentInstance.data.comboId;
    this.labId = this.dialogRef.componentInstance.data.labId;
    this.mobileNo=this.dialogRef.componentInstance.data.phone;
  }

//   processedPreScreening() {
//     // console.log('DATETIME+',this.dateTime);

//     const getate = new Date(this.dateTime); // isoString to date time
//     // console.log('DATETIME+',getate);
//     if (this.dateTime && this.dateTime !== undefined) {
//     //     this.paginationService.getTokenInitValue().then(async token => {
//     //         this.token = await token.screeningId;
//     //         const covidData: any = {
//     //           mediumSelected:'OTHER',
//     //             firstName: this.firstName,
//     //             lastName: this.lastName,
//     //             email: this.email,
//     //             labName: this.labId,
//     //             phone: this.mobileNo,
//     //             patient: this.patientId,
//     //             patientId: this.patientId,
//     //             addedOn: (-1) * new Date().getTime(),
//     //             tokenId: this.token,
//     //             anyScreening: false,
//     //             doaTimestamp: (-1) * getate.getTime(),
//     //             doa: getate.getTime(),
//     //             appointTime: this.addZeroToHourAndMinutes(getate.getHours()) + ':' + this.addZeroToHourAndMinutes(getate.getMinutes()),
//     //         };

//     //         const splitMapFName = this.firstName.toUpperCase().split(' ');
//     //         let splitMapF = {}
//     //         for (let i of splitMapFName) {
//     //             splitMapF = Object.assign(splitMapF, this.getMapValue(i));
//     //         }
//     //         const splitMapLName = this.firstName.toUpperCase().split(' ');
//     //         let splitMapL = {}
//     //         for (let j of splitMapLName) {
//     //             splitMapL = Object.assign(splitMapL, this.getMapValue(j));
//     //         }


//     //         const firstNameMap = this.getMapValue(this.toUpperCaseString(this.firstName).replace(/\s/g, ''));
//     //         const lastNameMap = this.getMapValue(this.toUpperCaseString(this.lastName).replace(/\s/g, ''));
//     //         const fullNameMap = (this.firstName + this.lastName).replace(/\s/g, '').toUpperCase();
//     //         const idMap = this.getMapValue(this.toUpperCaseString(this.patientId));
//     //         const fullMapData = this.getMapValue(fullNameMap);
//     //         const anyMap = Object.assign({}, idMap, firstNameMap, lastNameMap, fullMapData, splitMapF, splitMapL);

//     //         // anyMap[fullNameMap] = true;
//     //         covidData.anyMap = anyMap;
//     //         // console.log('Covid DATA+',covidData)
//     //         const resultDataCovid = this.paginationService.addCovidResult(covidData);
//     //         resultDataCovid.then(async (returnData: any) => {
//     //             if (await returnData) {
//     //               // // console.log(returnData.id);
//     //               const urlStr =
//     //               "key#add##patient#" +
//     //               this.patientId +
//     //               "##labName#" +
//     //               this.labId +
//     //               "##email#" +
//     //               this.email +
//     //               "##firstName#" +
//     //               this.firstName +
//     //               "##lastName#" +
//     //               this.lastName +
//     //               "##docId#" +
//     //               returnData.id;
              
//     //               this.router.navigate(['/covid-assessment-start'], { queryParams: { token: this.global.encodeToa(urlStr) } });

//     //             }
//     //         })

//     //     })
//     // }
  
//   }


//   dataBaseEntry(){
  
//     const getate = new Date(this.dateTime); // isoString to date time
//     // console.log('Datetime other',getate);

//     if (this.dateTime && this.dateTime !== undefined) {
//         this.paginationService.getTokenInitValue().then(async token => {
//             this.token = await token.screeningId;
//             const covidData: any = {
//                 addedType: 'other',
//                 firstName: this.firstName,
//                 lastName: this.lastName,
//                 email: this.email,
//                 labName: this.labId,
//                 // pId: this.patientId,
//                 patient: this.patientId,
//                 patientId: this.patientId,
//                 addedOn: (-1) * new Date().getTime(),
//                 tokenId: this.token,
//                 anyScreening: false,
//                 doaTimestamp: (-1) * getate.getTime(),
//                 doa: getate.getTime(),
//                 appointTime: this.addZeroToHourAndMinutes(getate.getHours()) + ':' + this.addZeroToHourAndMinutes(getate.getMinutes()),
//             };

//             const splitMapFName = this.firstName.toUpperCase().split(' ');
//             let splitMapF = {}
//             for (let i of splitMapFName) {
//                 splitMapF = Object.assign(splitMapF, this.getMapValue(i));
//             }
//             const splitMapLName = this.firstName.toUpperCase().split(' ');
//             let splitMapL = {}
//             for (let j of splitMapLName) {
//                 splitMapL = Object.assign(splitMapL, this.getMapValue(j));
//             }


//             const firstNameMap = this.getMapValue(this.toUpperCaseString(this.firstName).replace(/\s/g, ''));
//             const lastNameMap = this.getMapValue(this.toUpperCaseString(this.lastName).replace(/\s/g, ''));
//             const fullNameMap = (this.firstName + this.lastName).replace(/\s/g, '').toUpperCase();
//             const idMap = this.getMapValue(this.toUpperCaseString(this.patientId));
//             const fullMapData = this.getMapValue(fullNameMap);
//             const anyMap = Object.assign({}, idMap, firstNameMap, lastNameMap, fullMapData, splitMapF, splitMapL);

//             // anyMap[fullNameMap] = true;
//             covidData.anyMap = anyMap;
//             const resultDataCovid = this.paginationService.addCovidResult(covidData);
//             resultDataCovid.then(async (returnData: any) => {
//                 if (await returnData) {
//                   // // console.log(returnData);
//                     // updating token config , using trigger inspite of below query
//                     // this.covidService.updateTokenConfig({tokenId:covidData.tokenId});
//                     // data['docId'] = returnData.id;
//                     // data['dateTime'] = getate.toDateString() + ' at ' + covidData['appointTime'];

//                     // this.paginationService.shareLinkUrl(data).subscribe(dataResult => {

//                     //     this.openSuccessfulDialog();
//                     // });
//                 }
//             })

//         })
//     }
//   }


//   getMapValue(stringData) {
//     const param = stringData.replace(/\./g, '');
//     const obj = {};
//     for (let j = 0; j <= param.length - 1; j++) {
//         let emptyString = '';
//         for (let k = 0; k <= j; k++) {
//             emptyString += param[k];
//         }

//         obj[emptyString] = true;

//     }
//     return obj;
// }

// addZeroToHourAndMinutes(value: any) {
//   if (value < 10) {
//       return '0' + value;
//   }
//   return value;
// }

// // to uppercase string

// toUpperCaseString(str) {
//     return (str.trim()).toUpperCase();
// }
}
